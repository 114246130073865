import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { RouterModule, Routes } from '@angular/router';
import { MyTaskComponent } from './my-task.component';
import { AuthGuard } from 'app/auth/helpers';
import { MyTaskSearchListComponent } from './my-task-search-list/my-task-search-list.component';
import { MyTaskDetailComponent } from './my-task-detail/my-task-detail.component';
import { SharedModule } from 'shared/shared.module';
import { CoreCommonModule } from '@core/common.module';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';
import { TransferWorkModalComponent } from './transfer-work-modal/transfer-work-modal.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { PopoverModule } from 'ngx-bootstrap/popover';

const routes: Routes = [
  {
    path: '',
    component: MyTaskComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: MyTaskSearchListComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'view/:id',
        component: MyTaskDetailComponent,
        canActivate: [AuthGuard],
        children: [
          { path: '', redirectTo: 'detail', pathMatch: 'full' },
          {
            path: 'detail',
            canActivate: [AuthGuard],
            component: MyTaskDetailComponent,
          },
          {
            path: 'detail/:month/:year',
            canActivate: [AuthGuard],
            component: MyTaskDetailComponent,
          },
        ],
      },
      {
        path: 'view/:id/:month/:year',
        component: MyTaskDetailComponent,
        canActivate: [AuthGuard],
      },
    ],
  },

];

@NgModule({
  declarations: [
    MyTaskComponent,
    MyTaskSearchListComponent,
    MyTaskDetailComponent,
    TransferWorkModalComponent,
  ],
  imports: [
    CommonModule,
    CoreCommonModule,
    ContentHeaderModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    BsDatepickerModule.forRoot(),
    [RouterModule.forChild(routes)],
    SharedModule,
    Ng2FlatpickrModule,
    TimepickerModule.forRoot(),
    PopoverModule.forRoot(),
    NgbModule
  ],
})
export class MyTaskModule {}
