import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss']
})
export class CustomerComponent implements OnInit {

  constructor() { }
  contentHeader: object;
  ngOnInit(): void {
    this.contentHeader = {
      headerTitle: 'หน้าหลัก',
      actionButton: false,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'ลูกค้า',
            isLink: true,
            link: '/atms/customer'
          },
        ]
      }
    }
  }

}
