<!-- Menu header -->
<div class="navbar-header">
  <ul class="nav navbar-nav flex-row">
    <!-- <li class="nav-item mr-auto"> -->
    <a class="nav-link modern-nav-toggle d-none d-xl-block pr-0"
      style="z-index: 9999; position: absolute; color: white; margin-inline:15px; padding: 10px 15px 10px 15px;"
      (click)="toggleSidebarCollapsible()">
      <i class="toggle-icon feather font-medium-4 collapse-toggle-icon text-white" data-feather="menu"></i>
    </a>
    <span class="brand-logo">
      <img src="assets/images/logo/ACCOUNTWORKS.svg" alt="logo" class="w-100" style="width: 300px !important" />
    </span>

    <!-- App Branding -->
    <!-- <a class="navbar-brand" [routerLink]="['/']">
        
        <h2 class="brand-text mb-0">{{ coreConfig.app.appName }}</h2>
      </a> -->
    <!-- </li> -->

    <!-- Menu Toggler -->
    <!-- <li class="nav-item nav-toggle">
      <a
        class="nav-link modern-nav-toggle d-none d-xl-block pr-0"
        style="z-index: 9999"
        (click)="toggleSidebarCollapsible()"
      >
        <i
          [ngClass]="isCollapsed === true ? 'icon-circle' : 'icon-disc'"
          class="toggle-icon feather font-medium-4 collapse-toggle-icon text-primary"
        ></i>
      </a>
      <a
        class="nav-link modern-nav-toggle d-block d-xl-none pr-0"
        (click)="toggleSidebar()"
      >
        <i
          data-feather="x"
          class="font-medium-4 text-primary toggle-icon icon-circle"
        ></i>
      </a>
    </li> -->
  </ul>
</div>
<!--/ Menu header -->

<!-- Navbar shadow -->
<div class="shadow-bottom" [ngClass]="{ 'd-block': isScrolled }"></div>

<!-- Main menu -->
<div class="main-menu-content" [perfectScrollbar] (scroll)="onSidebarScroll()">
  <ul class="navigation navigation-main" layout="vertical" core-menu></ul>
  <div class="user-guide-card" *ngIf="!isCollapsed">
    <label class="user-guide-label"><u>User Guide</u></label>
    <div class="row mt-1 mb-1">
      <div class="col-4 text-small">
        <span class="text-color-main">100.00</span>
        <hr />
        <span class="text-color-pink">50.00</span>
      </div>
      <div class="col-8 text-small">
        Accountwork ชำระภาษีให้
        <hr />
        Accountwork ชำระค่าปรับให้
      </div>
    </div>
    <div class="row mt-1 mb-1">
      <div class="col-4 text-small">
        <span class="text-color-green">100.00</span>
        <hr />
        <span class="text-color-red">50.00</span>
      </div>
      <div class="col-8 text-small">
        ลูกค้า ชำระภาษีเอง
        <hr />
        ลูกค้า ชำระค่าปรับเอง
      </div>
    </div>
    <div class=" mb-1 align-items-center">
      <div class="row  mb-1">
        <div class="col text-center">
          <i data-feather="check" class="text-success" [size]="18"></i>
          <div class="text-small">เอกสารตรวจแล้ว</div>
        </div>
        <div class="col text-center">
          <i data-feather="x" class="text-danger" [size]="18"></i>
          <div class="text-small">เอกสารถูกปฏิเสธ</div>
        </div>
      </div>
      <div class="row">
        <div class="col text-center">
          <i data-feather="star" class="text-warning" [size]="18"></i>
          <div class="text-small">เอกสารถูกยื่นภาษีแล้ว</div>
        </div>
        <div class="col text-center">
          <i data-feather="alert-circle" class="text-danger" [size]="18"></i>
          <div class="text-small">เอกสารเกินกำหนดชำระ</div>
        </div>
      </div>
    </div>

    <div class="row mb-1 align-items-center">
      <div class="col-4 icon">
        <img src="../../../../../assets/images/icons/button-icons/Edit.png" />
      </div>
      <div class="col-8">แก้ไข</div>
    </div>
    <div class="row mb-1 align-items-center">
      <div class="col-4 icon">
        <img src="../../../../../assets/images/icons/button-icons/See-Detail.png" />
      </div>
      <div class="col-8">ดูตัวอย่าง</div>
    </div>
    <div class="row mb-1 align-items-center">
      <div class="col-4 icon">
        <img src="../../../../../assets/images/icons/button-icons/more-option.png" />
      </div>
      <div class="col-8">ตัวเลือกเพิ่มเติม</div>
    </div>
    <div class="row mb-1 align-items-center">
      <div class="col-4 icon">
        <img src="../../../../../assets/images/icons/button-icons/businessman.png" />
      </div>
      <div class="col-8">โอนงานชั่วคราว</div>
    </div>
    <div class="row mb-1 align-items-center">
      <div class="col-4 icon">
        <img src="../../../../../assets/images/icons/button-icons/transfer.png" />
      </div>
      <div class="col-8">โอนงานถาวร</div>
    </div>
    <div class="row mb-1 align-items-center">
      <div class="col-4 book-icon">
        <a routerLink="atms/user-guide"><img src="../../../../../assets/images/icons/button-icons/bookIcon.png" /></a>
      </div>
      <div class="col-8">
        <a routerLink="atms/user-guide"><span class="text-color-green">คู่มือการใช้งาน</span></a>
      </div>
    </div>
  </div>
</div>
<!--/ Main menu -->