import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
} from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CoreConfigService } from '@core/services/config.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Role, User } from 'app/auth/models';
import { AuthenticationService } from 'app/auth/service';
import { ModalComponent } from 'app/main/module/task-document/modal/modal.component';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subject } from 'rxjs';
import { ApiService } from 'service/api.service';
import { FilesApiService } from 'service/files-api.service';
import { GlobalFuncService } from 'service/global-func.service';
import Swal from 'sweetalert2';

export enum CreditType {
  PP30 = 0,
  Customer = 1,
}

@Component({
  selector: 'app-adjust-credit-modal',
  templateUrl: './adjust-credit-modal.component.html',
  styleUrls: ['./adjust-credit-modal.component.scss'],
})
export class AdjustCreditModalComponent implements OnInit {
  adjustForm: FormGroup;
  @Input() customerId: string;

  creditType = CreditType;

  @BlockUI() blockUI: NgBlockUI;
  @Output() callBackFunc: EventEmitter<any> = new EventEmitter();

  userRole: string
  userRoleEnum = Role
  currentUser: User

  constructor(
    private router: Router,
    public activeModal: NgbActiveModal,
    private _apiService: ApiService,
    private _formBuilder: FormBuilder,
    private _modalService: NgbModal,
    private _http: HttpClient,
    private _globalFuncService: GlobalFuncService,
    private _authenticationService: AuthenticationService
  ) {
    this.adjustForm = this._formBuilder.group({
      adjustType: 1,
      creditType: this.creditType.PP30,
      quantity: ['0.00', [this.nonNegativeNumberValidator()]],
      credit: 0,
      creditPP30: 0,
      remark: '',
    });

    this.currentUser = this._authenticationService.currentUserValue
    this.userRole = this.currentUser.roleSetName
  }

  nonNegativeNumberValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = this.formatMoneyToNumber(control.value);
      if (value !== null && value !== undefined && isNaN(value)) {
        return { nonNegativeNumber: true };
      }

      return value < 0 ? { nonNegativeNumber: true } : null;
    };
  }

  closeModal(): void {
    this.activeModal.close();
  }

  formatMoneyToNumber(value: string) {
    return this._globalFuncService.ParenthesesMoneyFormatToNumber(value);
  }

  formatNumberToMoney(value: number) {
    return this._globalFuncService.FormatMinusOrNot(value);
  }

  formatNumber(id: string) {
    let quantityInput = document.getElementById(id) as HTMLFormElement;
    let tempValue = this.formatMoneyToNumber(quantityInput.value);
    quantityInput.value = this.formatNumberToMoney(tempValue);
    console.log(tempValue);
  }

  ngOnInit(): void {}

  openConfirmModal() {
    const modalRef = this._modalService.open(ModalComponent, {
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.title = 'ยืนยันความถูกต้อง';
    modalRef.componentInstance.detail = `คุณแน่ใจที่จะเพิ่มเครดิตเป็นจำนวน ${this.formatNumberToMoney(
      this.adjustForm.controls.quantity.value
    )} หรือไม่?`;
    modalRef.componentInstance.callBackFunc.subscribe((res) => {
      this.callBack();
    });
  }

  callBack(): void {
    this.blockUI.start();
    let url = '';

    if (this.adjustForm.controls.creditType.value == this.creditType.PP30) {
      url = `${this._apiService.API_URL}/creditpp30/Adjust/CreditPP30/Customer/${this.customerId}`;
      this.adjustForm.patchValue({
        creditPP30: this.formatMoneyToNumber(
          this.adjustForm.controls.quantity.value
        ),
      });
    } else {
      url = `${this._apiService.API_URL}/credit/Adjust/Credit/Customer/${this.customerId}`;
      this.adjustForm.patchValue({
        credit: this.formatMoneyToNumber(
          this.adjustForm.controls.quantity.value
        ),
      });
    }

    let formGroup = this.adjustForm.value;

    // this.adjustForm.patchValue({
    //   quantity: this.formatMoneyToNumber(
    //     this.adjustForm.controls.quantity.value
    //   ),
    // });

    this._http
      .post(url, formGroup, {
        headers: this._apiService.API_HEADERS,
      })
      .subscribe(
        (res: any) => {
          this.blockUI.stop()
          Swal.fire('สำเร็จ', '', 'success');
          this.callBackFunc.emit()
        },
        (err) => {
          this.blockUI.stop()
          Swal.fire('ผิดพลาด', '', 'error');
        }
      );
  }
}
