import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  FormGroup,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { CoreConfigService } from '@core/services/config.service';
import { AuthenticationService } from 'app/auth/service';
import { log } from 'console';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-auth-login-v2',
  templateUrl: './auth-login-v2.component.html',
  styleUrls: ['./auth-login-v2.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AuthLoginV2Component implements OnInit {
  //  Public
  public coreConfig: any;
  public loginForm: UntypedFormGroup;
  public loading = false;
  public submitted = false;
  public returnUrl: string;
  public error = '';
  public passwordTextType: boolean;
  isNotFoundUser: boolean;
  isNotMatchPassword: boolean;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {CoreConfigService} _coreConfigService
   */
  constructor(
    private _coreConfigService: CoreConfigService,
    private _formBuilder: UntypedFormBuilder,
    private _route: ActivatedRoute,
    private _router: Router,
    private _authService: AuthenticationService,
    private modalService: NgbModal
  ) {
    this._unsubscribeAll = new Subject();

    // Configure the layout
    this._coreConfigService.config = {
      layout: {
        navbar: {
          hidden: true,
        },
        menu: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        customizer: false,
        enableLocalStorage: false,
      },
    };
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  /**
   * Toggle password
   */
  togglePasswordTextType() {
    this.passwordTextType = !this.passwordTextType;
  }

  async onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.isNotFoundUser = false;
    this.isNotMatchPassword = false;
    this.loading = true;
    // Login
    this._authService
      .login(this.f?.email?.value, this.f?.password?.value)
      .subscribe(
        (res) => {
          var data = res.data;
          if (data.success) {
            this._authService.getUserDetails().subscribe(
              async (res) => {
                // await localStorage.setItem(
                //   'currentUser',
                //   JSON.stringify(res.data.resultData)
                // );

                // redirect to home page
                
                this.loading = false;
                this._router.navigate(['/atms/my-task']);
              },
              (err) => {
                this.loading = false;
              }
            );
          } else {
            console.log('Login fail : ', data.message);
            this.loading = false;
            this.error = data.message;
            this.isNotFoundUser = true;
            this.isNotMatchPassword = true;
          }
        },
        (err) => {
          this.loading = false;
          this.error = 'Invalid username or password';
          this.isNotFoundUser = true;
          this.isNotMatchPassword = true;
        }
      );
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.loginForm = this._formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
    });

    //Init forgot password form
    this.initForgotForm();

    // get return url from route parameters or default to '/'
    this.returnUrl =
      this._route.snapshot.queryParams['returnUrl'] || '/atms/my-task';

    // Subscribe to config changes
    this._coreConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.coreConfig = config;
      });
    var token = localStorage.getItem('token');
    if (token != null) {
      setTimeout(() => {
        this._router.navigate(['/atms/my-task']);
      }, 1000);
    } else {
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('email');
      localStorage.removeItem('currentUser');
      this._router.navigate(['']);
    }
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  onFormLoginChanged() {
    this.isNotFoundUser = false;
    this.isNotMatchPassword = false;
  }

  //Forgot password secton
  forgotForm: FormGroup;
  showForgotSection: boolean = false;
  fgsubmitted: boolean = false;
  initForgotForm() {
    this.forgotForm = this._formBuilder.group({
      email: ['', Validators.required],
    });
  }

  get fg() {
    return this.forgotForm.controls;
  }

  onForgotSubmit() {
    this.fgsubmitted = true;
  }

  onClickedForgot() {
    this.showForgotSection = true;
  }

  //Test Dialog
  testInput: string;
  openDialog(content: any) {
    this.modalService.open(content, { centered: true }).result.then(
      (result) => {
        // console.log('close with', result); //close
        // console.log('Input ', this.testInput)
        this.openDialog2(result);
      },
      (reason) => {
        console.log('dismiss with', reason); //dismiss
      }
    );
  }

  openDialog2(content: any) {
    this.modalService.open(content, { centered: true }).result.then(
      (result) => {
        console.log('close with', result); //close
        this.testInput = '';
      },
      (reason) => {
        console.log('dismiss with', reason); //dismiss
        this.testInput = '';
      }
    );
  }
}
