<div class="row">
    <div class="col-12">
        <div class="card p-2">
            <div class="card-body search-text">
                <div class="row justify-content-center">
                    <div class="col-4">
                        <label>ค้นหาชื่อลูกค้า</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <!-- <span class="input-group-text" id="basic-addon-search1"><i
                                        data-feather="search"></i></span> -->
                                <span class="input-group-text" id="basic-addon-search1">
                                    <i class="fa-solid fa-magnifying-glass"></i>
                                </span>
                            </div>
                            <input type="text" class="form-control" placeholder="Search..." aria-label="Search..."
                                aria-describedby="basic-addon-search1" [(ngModel)]="filterText" />
                        </div>
                    </div>
                    <div class="col-3">
                        <label>สถานะ</label>
                        <ng-select [(ngModel)]="filterStatus" [clearable]="false">
                            <ng-option *ngFor="let item of selectStatus" [value]="item.value">
                                {{ item.desc }}
                            </ng-option>
                        </ng-select>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center mt-3">
                <div class="col-lg-2 col-md-3 col-12"> <button class="btn btn-main w-100"
                        (click)="onSearch()">Search</button> </div>
                <div class="col-lg-2 col-md-3 col-12"> <button class="btn btn-cancel w-100"
                        (click)="onReset()">Reset</button> </div>
            </div>
        </div>
        <div class="row justify-content-between align-items-center mb-2">
            <div class="col">
                <ng-container *ngIf="
                      userRole !== userRoleEnum.Finance &&
                      userRole !== userRoleEnum.FinanceSupervisor &&
                      userRole !== userRoleEnum.FinanceManager
                ">
                    <button type="button" class="btn btn-success w-25" rippleEffect routerLink="create">
                        เพิ่ม
                    </button>
                </ng-container>
            </div>
            <div class="col-2 justify-content-end align-items-center">
                <div class="d-flex justify-content-end align-items-center mb-1">
                    <input type="checkbox" class="default-checkbox" [checked]="isWantFullReport"   [(ngModel)]="isWantFullReport"/>
                    <p class="mx-1 mb-0">ออกรายงานข้อมูลทั้งหมด</p>
                </div>
                <a (click)="onDownloadFile('', '')">
                    <img src="../../../../../assets/images/icons/button-icons/Download_Icon.svg" alt="Download"
                        class="w-100" />
                </a>
            </div>
        </div>
        <div class="card">
            <div class="card-body p-0">
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">
                                    <input type="checkbox" class="default-checkbox" (change)="selectAll($event.target.checked)"
                                    [checked]="isSelectAll" />
                                </th>
                                <th scope="col" class="text-center">NO.</th>
                                <th scope="col" class="text-center">ลูกค้า</th>
                                <th scope="col" class="text-center">สถานะ</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of tableListData; let i = index">
                                <td class="text-center" scope="col">
                                    <input type="checkbox" class="default-checkbox" [checked]="data.isChecked"
                                      (change)="selectItem($event.target.checked, data)" />
                                  </td>
                                <td class="text-center">{{ i+1 + (page*pageLength-pageLength) }}</td>
                                <td> {{ data.businessName }} </td>
                                <td class="text-center" *ngIf="data.isActive" style="color: rgb(0, 207, 0);">Active</td>
                                <td class="text-center" *ngIf="!data.isActive" style="color: rgb(231, 5, 5);">Inactive
                                </td>
                                <td>
                                    <div class="icon">
                                        <button class="table-action-btn" (click)="onCustomerClicked(data.id)">
                                            <img src="../../../../../assets/images/icons/button-icons/See-Detail.png"
                                                alt="Detail" />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="row my-2">
                    <div class="col-12">
                        <app-pagination [currentPage]="page" [totalPages]="totalPage"
                            (pageChange)="onPageChange($event)"
                            (sizePageChange)="onSizePageChange($event)"></app-pagination>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>