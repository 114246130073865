<div class="auth-wrapper auth-v2">
  <div class="auth-inner row m-0">
    <!-- Left Text-->
    <div class="d-none d-lg-flex col-lg-8 align-items-center p-5 bg-main">
      <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
        <img class="img-fluid" src="../../../../../assets/images/pages/login/accountworks-login-img.png" alt="Login V2"
          style="max-height: 500px;" />
      </div>
    </div>
    <!-- /Left Text-->
    <!-- Forgot password-->
    <div class="d-flex col-lg-4 align-items-center auth-bg px-2 p-lg-5">
      <div class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto">
        <h2 class="card-title font-weight-bold mb-1">Reset Password 🔒</h2>
        <p class="card-text mb-2">
          Enter a new password for <span>{{ email }}</span><br />
          Password must have 1 Uppercase <br />Password length must be of 8-20
          characters
        </p>
        <form class="auth-forgot-password-form mt-2" [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <label class="form-label" for="forgot-password-email">New Password</label>
            <div class="input-group input-group-merge form-password-toggle">
              <input class="form-control form-control-merge" [type]="passwordTextType ? 'text' : 'password'"
                formControlName="newPassword" placeholder="Enter Password" autofocus="" tabindex="1" [ngClass]="{
                  'is-invalid': f.newPassword.errors && f.newPassword.touched
                }" />
              <div class="input-group-append">
                <span class="input-group-text cursor-pointer" [style.borderColor]="
                    f.newPassword.errors && f.newPassword.touched
                      ? '#CD212A'
                      : '#d8d6de'
                  "><i class="feather font-small-4" [ngClass]="{
                      'icon-eye-off': passwordTextType,
                      'icon-eye': !passwordTextType,
                      'is-invalid':
                        f.newPassword.errors && f.newPassword.touched
                    }" (click)="togglePasswordTextType()"></i></span>
              </div>
            </div>

            <div class="invalid-feedback" *ngIf="f.newPassword.errors && f.newPassword.touched">
              <div *ngIf="f.newPassword.errors.required">
                Password is required.
              </div>
              <div *ngIf="f.newPassword.errors.minlength">
                Password should be at least 8 characters long.
              </div>
              <div *ngIf="f.newPassword.errors.pattern">
                Password should contain at least one uppercase letter.
              </div>
            </div>

            <label class="form-label" for="forgot-password-email">Confirm New Password</label>

            <div class="input-group input-group-merge form-password-toggle">
              <input class="form-control form-control-merge" [type]="passwordTextType ? 'text' : 'password'"
                formControlName="confPassword" placeholder="Enter Confirm Password" autofocus="" tabindex="2" [ngClass]="{
                  'is-invalid': f.confPassword.errors && f.confPassword.touched
                }" />
              <div class="input-group-append">
                <span class="input-group-text cursor-pointer" [style.borderColor]="
                    f.confPassword.errors && f.confPassword.touched
                      ? '#CD212A'
                      : '#d8d6de'
                  "><i class="feather font-small-4" [ngClass]="{
                      'icon-eye-off': passwordTextType,
                      'icon-eye': !passwordTextType,
                      'is-invalid':
                        f.confPassword.errors && f.confPassword.touched
                    }" (click)="togglePasswordTextType()"></i></span>
              </div>
            </div>

            <div class="invalid-feedback" *ngIf="f.confPassword.errors && f.confPassword.touched">
              <div *ngIf="f.confPassword.errors.required">
                Confirm Password is required.
              </div>
              <div *ngIf="f.confPassword.errors.mismatch">
                Passwords do not match.
              </div>
            </div>
          </div>
          <button [disabled]="isLoading" class="btn btn-primary btn-block" tabindex="4" rippleEffect>
            Confirm Reset Password
          </button>
        </form>
        <p class="text-center mt-2">
          <a routerLink="/login"><i data-feather="chevron-left" class="mb-25"></i>Back to login</a>
        </p>
      </div>
    </div>
    <!-- /Forgot password-->
  </div>
</div>