<!-- Modal -->
<ng-template #cancelModal let-modal>
    <div class="modal-header text-center bg-primary">
        <h3 class="modal-title w-100">Account Task Management System</h3>
        <button type="button" class="btn-close float-right" (click)="closeModal()">&times;</button>
    </div>
    <div class="modal-body text-center">
        <img src="assets/images/modal-assets/cancel.png" style="width: 250px; height: 250px;">
        <tr style="height: 25px;"></tr>
        <h3 class="text-danger">Cancel</h3>
    </div>
    <div class="modal-footer justify-content-evenly">
        
    </div>
</ng-template>