import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { ApiService } from './api.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

var FileSaver = require('file-saver');

@Injectable({
  providedIn: 'root',
})
export class GlobalFuncService {
  @BlockUI() blockUI: NgBlockUI

  constructor(private http: HttpClient, private apiService: ApiService) {}

  // MoneyFormatToNumber(value: string): number {
  //   let result = 0;
  //   result = parseFloat(value.toString().replace(/,/g, ''));

  //   if (isNaN(result)) {
  //     return 0;
  //   }

  //   return result;
  // }

  ParenthesesMoneyFormatToNumber(value: string): number {
    let result = 0;
    value = value.toString()

    // Remove commas and parentheses
    const cleanedValue = value.replace(/,|\(|\)/g, '');

    // Parse the cleaned value as a float
    result = parseFloat(cleanedValue);

    if (isNaN(result)) {
      return 0;
    }

    // If the original value had parentheses, make the result negative
    if (value.includes('(') && value.includes(')')) {
      result = -result;
    }

    return result;
  }

  FormatToMoney(
    value: number,
    showMaxFourDecimals: boolean = false,
    disableDecimals: boolean = false
  ): string {
    // Check if the value is a number
    if (isNaN(value)) {
      return '';
    }

    // Set the minimum and maximum decimal digits
    const minDecimalDigits = disableDecimals ? 0 : 2;
    const maxDecimalDigits = disableDecimals ? 0 : showMaxFourDecimals ? 4 : 2;

    // Format the number as currency with the desired decimal digits
    return value.toLocaleString(undefined, {
      minimumFractionDigits: minDecimalDigits,
      maximumFractionDigits: maxDecimalDigits,
    });
  }

  FormatMinusOrNot(value: number): string {
    if (isNaN(value)) {
      return '';
    }

    if (value < 0) {
      let tempVal = Math.abs(value);
      return `(${tempVal.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })})`;
    }

    return value.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  GetCurrentStep(value: any): number {
    if (!value || value.length === 0) {
      return 0;
    }

    const orderStatus = value[0];
    if (orderStatus.isCancel || orderStatus.isVoided) {
      return 5; // Cancel
    } else if (orderStatus.isCompleted || orderStatus.isAllRTCreated) {
      return 4; // Complete
    } else if (orderStatus.isAllIVPaid) {
      return 3; // Payment
    } else if (orderStatus.isAllIVCreated || orderStatus.isCreatedIV) {
      return 2; // Wait Payment
    } else if (
      (orderStatus.isCreatedQO && orderStatus.isCreatedSO) ||
      (orderStatus.isCreatedPR && orderStatus.isCreatedPO)
    ) {
      return 1; // Accept
    } else {
      return 0; // Draft
    }
  }

  GetAddressTemplate(customerAddressObj): string {
    let result = '';

    if (customerAddressObj) {
      var provinceId = parseInt(customerAddressObj.provinceId);

      let address = customerAddressObj.addressNumber
        ? customerAddressObj.addressNumber
        : '-';
      let subDistrict = customerAddressObj.subDistrictName
        ? (provinceId == 10 ? 'แขวง' : 'ตำบล') +
          customerAddressObj.subDistrictName
        : '-';
      let district = customerAddressObj.districtName
        ? (provinceId == 10 ? 'เขต' : 'อำเภอ') + customerAddressObj.districtName
        : '-';

      let provinceName = 'จังหวัด' + customerAddressObj.provinceName;

      let postCode = customerAddressObj.postCode;

      result = `${address} ${subDistrict} ${district} ${provinceName} ${postCode}`;
      return customerAddressObj ? result : '-';
    } else {
      return customerAddressObj ? result : '-';
    }
  }

  getPropertyByPath(obj: any, path: string): any {
    const properties = path.split('.');
    let value = obj;

    for (const prop of properties) {
      value = value[prop];
      if (value === undefined) {
        return undefined;
      }
    }

    return value;
  }

  bytesToSize(bytes: number): string {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return parseFloat((bytes / Math.pow(1024, i)).toFixed(2)) + ' ' + sizes[i];
  }

  // scrollToFirstInvalidControl(formID: string, htmlEL?: Element) {
  //   let form = document.getElementById(formID); // <-- your formID
  //   let firstInvalidControl: Element;

  //   if (!htmlEL) {
  //     firstInvalidControl = form.getElementsByClassName('ng-invalid')[0];
  //   } else {
  //     firstInvalidControl = htmlEL;
  //   }

  //   let elementRect = firstInvalidControl.getBoundingClientRect();
  //   let absoluteElementTop = elementRect.top + window.pageYOffset;
  //   let middleOfElement = absoluteElementTop - window.innerHeight / 2;
  //   window.scrollTo({ top: middleOfElement, behavior: 'smooth' });
  //   (firstInvalidControl as HTMLElement).focus();
  // }

  formatDate(isoDate: string, format: string = 'D MMMM YYYY'): string {
    return moment(isoDate).format(format); // Customize the format as needed
  }

  checkFirstLetterisInvalid(text: string): boolean {
    let firstLetter = Array.from(text)[0];
    const specialCharRegex =
      /^[-._!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\| ]+/;
    return specialCharRegex.test(firstLetter);
  }

  getPdf(param: string) {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };

    return this.http.get(param, httpOptions);
  }

  getExcelReport(path: string, filename: string, params?: any) {
    this.blockUI.start()
    this.apiService.GetBlob(path, params).subscribe((data) => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      FileSaver.saveAs(blob, `${filename}.xlsx`);
      this.blockUI.stop()
    });
  }

  getMonthName(monthNumber: number): string {
    let dateNow: Date = new Date();
    // const months = [
    //   'January',
    //   'February',
    //   'March',
    //   'April',
    //   'May',
    //   'June',
    //   'July',
    //   'August',
    //   'September',
    //   'October',
    //   'November',
    //   'December',
    // ];

    const months = [
      'มกราคม',
      'กุมภาพันธ์',
      'มีนาคม',
      'เมษายน',
      'พฤษภาคม',
      'มิถนายน',
      'กรกฏาคม',
      'สิงหาคม',
      'กันยายน',
      'ตุลาคม',
      'พฤศจิกายน',
      'ธันวาคม',
    ];

    // Adjust the monthNumber to be zero-based (January is 0, February is 1, etc.)
    const adjustedMonthNumber = monthNumber - 1;

    // Check if the provided monthNumber is valid
    if (adjustedMonthNumber >= 0 && adjustedMonthNumber < months.length) {
      return months[adjustedMonthNumber];
    } else {
      // return 'Invalid Month';
      return months[dateNow.getMonth()];
    }
  }

  calulatePeriod(date: Date): number {
    if (date.getDate() <= 7) {
      return 1;
    } else if (date.getDate() <= 14) {
      return 2;
    } else if (date.getDate() <= 15) {
      return 3;
    } else if (date.getDate() <= 23) {
      return 4;
    } else {
      return 1;
    }
  }

  documentStatus(docStatus: number): number {
    if (docStatus === 0) {
      return 0;
    } else if (docStatus === 1) {
      return 1;
    } else if (docStatus === 2) {
      return 2;
    } else if (docStatus === 6) {
      return 3;
    } else if (docStatus === 3 || docStatus === 4) {
      return 4;
    } else if (docStatus === 5) {
      return 5;
    }
  }

  getDocumentStatusTitle(status: number): string {
    switch (this.documentStatus(status)) {
      case 0:
        return 'รอการอนุมัติ';

      case 1:
        return 'รอการชำระเงิน';

      case 2:
        return 'ชำระเงินสำเร็จ';

      case 3:
        return 'เสร็จสิ้น';

      case 4:
        return 'ปฎิเสธการอนุมัติ';

      case 5:
        return 'ยกเลิก';

      default:
        return 'รอการอนุมัติ';
    }
  }

  formatBusinessName(customer): string {
    let formatBusinessName: string = '';
    if (customer.islegalEntity) {
      if (!customer.isHeadBranch) {
        formatBusinessName = `${
          customer.businessName
        } (สาขา ${customer.branchNumber.toString().padStart(5, '0')})`;
      } else {
        formatBusinessName = `${customer.businessName} (สำนักงานใหญ่)`;
      }
    } else {
      formatBusinessName = customer.businessName;
    }
    return formatBusinessName;
  }
}
