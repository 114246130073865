export class CustomerDetails {
  id: string;
  isActive: boolean = true;
  isDelete: boolean;
  createBy: string;
  createDate: string;
  updateBy: string;
  updateDate: string;
  deleteBy: string;
  deleteDate: string;
  customerCode: string;
  isThailand: boolean = true;
  taxId: string;
  isHeadBranch: boolean = true;
  islegalEntity: boolean;
  branchName: string;
  branchNumber: string;
  businessTypeId: number;
  businessTypeName: string;
  businessName: string;
  customerFirstName: string;
  customerLastName: string;
  employeeOwnedId: string;
  employeeFirstNameTh: string;
  personalFirstName: string;
  personalLastName: string;
  personalNickName: string;
  personalPosition: string;
  personalPhone: string;
  personalEmail: string;
  contactPhone1: string;
  contactPhone2: string;
  contactPhone3: string;
  contactEmail: string;
  contactEmail2?: string;
  contactEmail3?: string;
  contactOwnerEmail?: string;
  contactOwnerEmail2?: string;
  contactOwnerEmail3?: string;
  contactFax: string;
  contactLine: string;
  contactWebsite: string;
  firstName: string;
  lastName: string;
  genderId: number = 0;
  isChecked?: boolean;
}

export class CustomerAddress {
  isActive: true;
  isDelete: true;
  createBy: string;
  createDate: string;
  updateBy: string;
  updateDate: string;
  deleteBy: string;
  deleteDate: string;
  id: string;
  customerId: string;
  detail: string;
  addressNumber: string;
  village: string;
  alley: string;
  road: string;
  country: 0;
  provinceId: string;
  provinceName: string;
  districtId: string;
  districtName: string;
  subDistrictId: string;
  subDistrictName: string;
  postCode: string;
  useForShipping: true;
  useForBilling: true;
  isLegalAddr: true;
  addrLatitude: 0;
  addrLongitude: 0;
  phone: string;
  fax: string;
  taxpayerNumber: string;
}
export class CustomerAddressModel {
  customerId: string;
  detail: string;
  addressNumber: string;
  village: string;
  alley: string;
  road: string;
  country: string;
  provinceId: string;
  districtId: string;
  subDistrictId: string;
  useForShipping: boolean = true;
  useForBilling: boolean = true;
}

export class CustomerPackage {
  id: string;
  itemCode: string;
  name: string;
  handleName_uq: string;
  createDate: string;
  checked: boolean = false;
}
