import { Component, OnInit, ViewChild } from '@angular/core';
import { ContentHeader } from 'app/layout/components/content-header/content-header.component';
import { PdfViewerComponent, PdfViewerModule, } from 'ng2-pdf-viewer';  // Updated import

@Component({
  selector: 'app-user-guide',
  templateUrl:'./user-guide.component.html',
//   template: `<pdf-viewer [src]="pdfSrc"
//   [render-text]="true"
//   [original-size]="false"
//   style="width: 400px; height: 500px"
// ></pdf-viewer>`,
  styleUrls: ['./user-guide.component.scss']
})
export class UserGuideComponent implements OnInit{
  title: string = 'ng2-pdf-viewer';
  src: string = '/assets/UserManual.pdf';

  
  contentHeader: ContentHeader;
  ngOnInit(): void {
    this.contentHeader = {
      headerTitle: 'หน้าหลัก',
      actionButton: false,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'คู่มือการใช้งาน',
            isLink: true,
            link: '/atms/user-guide',
          },
        ],
      },
    };
    
  }

  downloadPDF(){
    let link = document.createElement('a')
    link.setAttribute('type', 'hidden');
    link.href = '/assets'
    link.download = 'UserManual.pdf'
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
  
}
