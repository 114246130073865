import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Role } from 'app/auth/models/role';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ApiService } from 'service/api.service';
import { CustomerService } from 'service/customer.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-transfer-work-modal',
  templateUrl: './transfer-work-modal.component.html',
  styleUrls: ['./transfer-work-modal.component.scss'],
})
export class TransferWorkModalComponent implements OnInit {
  @Input() data: any;
  @Input() month: any;
  @Input() year: any;
  @Input() currentUser: any;
  @Input() isPermanentMode: boolean = false;
  @Input() viewByInvoice: boolean = false;
  @Input() customerId: string = '';
  @Output() refreshFunc: EventEmitter<any> = new EventEmitter();

  @BlockUI() blockUI: NgBlockUI;

  userRoleEnum = Role;
  selectedEmployee: any;
  remark: any;
  employeeList: any[] = [];
  selectedPeriod = [];
  selectedPeriodNumber: number;
  teamId: string;
  departmentId: string;
  isLoading: boolean = false;
  myTeamList: any[] = [];

  form: FormGroup;

  periodList = [
    // {
    //   id: '1',
    //   monthNameTh: 'พฤษจิกายน',
    //   period: 1,
    // },
    // {
    //   id: '2',
    //   monthNameTh: 'พฤษจิกายน',
    //   period: 2,
    // },
    // {
    //   id: '3',
    //   monthNameTh: 'พฤษจิกายน',
    //   period: 3,
    // },
    // {
    //   id: '4',
    //   monthNameTh: 'พฤษจิกายน',
    //   period: 4,
    // },
  ];

  showCheckDialog: boolean = false;
  checkTransferDialog: string;

  constructor(
    private apiService: ApiService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private activeModal: NgbActiveModal,
    private customerService: CustomerService
  ) {
    const token = JSON.parse(localStorage.getItem('token'));
    this.customerService.SetHttpHeaders(token);

    this.form = fb.group({
      selectedDoc: [''],
    });
  }

  onChangeSelectedPeriod(item: any) {
    // Use appropriate model type instead of any

    // if (isChecked) {
    //   this.selectedPeriod.push(item.period);
    // } else {
    //   let index = this.selectedPeriod.indexOf(item.period);
    //   this.selectedPeriod.splice(index, 1);
    // }
    // console.log(this.selectedPeriod)
    this.selectedPeriodNumber = item.period;
    console.log(this.form.controls.selectedDoc.value);
  }

  ngOnInit(): void {
    console.log(this.data);
    console.log(this.currentUser);
    console.log(this.isPermanentMode);
    console.log(this.viewByInvoice);

    if (!this.data.isFullPeriodModel && !this.isPermanentMode) {
      console.log('not full period');
    }

    if (
      this.currentUser.positionId == 92 ||
      this.currentUser.positionId == 101
    ) {
      this.GetMyTeamEmployeeList(this.currentUser.teamId);
    } else {
      this.GetEmployeeList();
    }

    if (this.isPermanentMode) {
      // this.selectedEmployee.id
      this.customerService
        .chectPermanentJobTransfer(this.data.customerId)
        .subscribe((res) => {
          this.showCheckDialog = true;
          if (res.data.resultData[0].permanentJobTransferCheckEnum == 0) {
            this.checkTransferDialog = 'การเปลี่ยนผู้ดูแลจะมีผลในทันที';
          } else if (
            res.data.resultData[0].permanentJobTransferCheckEnum == 1
          ) {
            this.checkTransferDialog =
              'การเปลี่ยนผู้ดูแลจะมีผลในรอบงานเดือนถัดไป';
          }
        });
    }
    
    this.getCustomerDocumentByPeriod();
  }

  GetMyTeamEmployeeList(team: number) {
    this.apiService
      .GetDataById('team/GetEmployeeByTeam', team.toString())
      .subscribe((res) => {
        this.employeeList = res.data.resultData;
        if (
          this.currentUser.positionId == 92 ||
          this.currentUser.positionId == 101
        ) {
          this.employeeList = this.employeeList.map((element) => ({
            ...element,
            teamTh: this.currentUser.teamName_TH,
          }));
        }
        this.removeCurrentUser();
        console.log(this.employeeList);
      });
  }

  GetEmployeeList() {
    console.log('get my team');
    this.apiService.GetAllData('employee/GetMyTeam').subscribe((res) => {
      res.data.resultData.forEach((element) => {
        if (element['departmentEn'] == 'Account') {
          this.employeeList.push(element);
        }
      });
      // this.employeeList = res.data.resultData;
      this.removeCurrentUser();
      console.log(this.employeeList);
    });
  }

  GetSuperAdminEmployeeList() {
    this.apiService.GetAllData('employee/GetMyTeam').subscribe((res) => {
      res.data.resultData.forEach((element) => {
        if (element['departmentEn'] == 'Account') {
          this.employeeList.push(element);
        }
      });
    });
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  onSelectChanged() {
    console.log(this.selectedEmployee);
  }

  openModal(modal) {
    this.modalService.open(modal, { centered: true });
  }

  submit() {
    if (this.isPermanentMode) {
      this.permanentJobTransfer();
    } else if (!this.isPermanentMode && !this.data.isFullPeriodModel) {
      this.tempJobTransfer(this.data.documentTimingId);
    } else {
      this.tempJobTransfer(this.form.controls.selectedDoc.value);
    }
  }

  permanentJobTransfer() {
    this.blockUI.start();
    this.apiService
      .Put('workTransfer/PermanentJobTransfer', {
        recipientEmployeeId: this.selectedEmployee.id,
        customerId: this.data.customerId,
      })
      .subscribe(
        (res) => {
          this.refreshFunc.emit();
          this.activeModal.close();
          this.blockUI.stop();
          Swal.fire('สำเร็จ', 'โอนงานสำเร็จ', 'success');
        },
        (err) => {
          this.blockUI.stop();
          Swal.fire('ผิดพลาด', 'โอนงานไม่สำเร็จ', 'error');
        }
      );
  }

  tempJobTransfer(documentTimingId: string) {
    this.blockUI.start();
    this.apiService
      .Put('workTransfer/Temporary/Transfer', {
        documentTimingId: documentTimingId,
        employeeId: this.selectedEmployee.id,
        remark: this.remark,
      })
      .subscribe(
        (res) => {
          this.refreshFunc.emit();
          this.activeModal.close();
          this.blockUI.stop();
          Swal.fire('สำเร็จ', 'โอนงานสำเร็จ', 'success');
        },
        (err) => {
          this.blockUI.stop();
          Swal.fire('ผิดพลาด', 'โอนงานไม่สำเร็จ', 'error');
        }
      );
  }

  getCustomerDocumentByPeriod() {
    this.isLoading = true;
    this.apiService
      .GetAllData(
        `documenttiming/${this.customerId}/GetDropdownDocumentTimingPeriod?Year=${this.year}&Month=${this.month}`
      )
      .subscribe((res) => {
        this.isLoading = false;
        this.periodList = res.data.resultData;
        console.log(this.periodList);
      });
  }

  removeCurrentUser() {
    this.employeeList.forEach((element, index) => {
      if (this.employeeList[index]['id'] == this.currentUser['id']) {
        this.employeeList.splice(index, 1);
      }
    });
  }
}
