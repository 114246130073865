import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { CoreCommonModule } from '@core/common.module';

import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';

import { SampleComponent } from './sample.component';
// import { HomeComponent } from './home.component';
import { HomeComponent } from '../module/home/home.component';

const routes = [
 
];

@NgModule({
  // declarations: [HomeComponent],
  // imports: [RouterModule.forChild(routes)],
  // exports: [HomeComponent]
})
export class SampleModule {}
