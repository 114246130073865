import { ManageUsersModule } from './main/module/manage-users/manage-users.module';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentHeaderModule } from './layout/components/content-header/content-header.module';
import { TranslateModule } from '@ngx-translate/core';
import { CoreCommonModule } from '@core/common.module';
import { AuthGuard } from './auth/helpers';
import { UserGuideComponent } from './main/module/manage-users/user-guide/user-guide.component';

const routes: Routes = [
  {
    path: '',
    children: [
      { path: '', redirectTo: 'login', pathMatch: 'full', },
      { path: 'login', loadChildren: () => import('./main/pages/authentication/authentication.module').then(m => m.AuthenticationModule)},
      { path: 'atms/my-task', loadChildren: () => import('./main/module/my-task/my-task.module').then(m => m.MyTaskModule) },
      { path: 'atms/tax-report', loadChildren: () => import('./main/module/my-task-kv/my-task-kv.module').then(m => m.MyTaskKvModule) },
      { path: 'atms/customer', loadChildren: () => import('./main/module/customer/customer.module').then(m => m.CustomerModule) },
      { path: 'atms/user', loadChildren: () => import('./main/module/user/user.module').then(m => m.UserModule) },
      { path: 'atms/task-document', loadChildren: () => import('./main/module/task-document/task-document.module').then(m => m.TaskDocumentModule) },
      { path: 'atms/task-overdue', loadChildren: () => import('./main/module/task-overdue/task-overdue.module').then(m => m.TaskOverdueModule) },
      { path: 'atms/employee', loadChildren: () => import('./main/module/employee/employee.module').then(m => m.EmployeeModule) },
      { path: 'atms/document-history', loadChildren: () => import('./main/module/document-history/document-history.module').then(m => m.DocumentHistoryModule) },
      { path: 'atms/trash-can', loadChildren: () => import('./main/module/trashcan/trashcan.module').then(m => m.TrashcanModule) },
      { path: 'atms/manage-users', loadChildren: () => import('./main/module/manage-users/manage-users.module').then(m => m.ManageUsersModule) },
      { path: 'atms/manage-package', loadChildren: () => import('./main/module/manage-package/manage-package.module').then(m => m.ManagePackageModule) },
      { path: 'atms/report', loadChildren: () => import('./main/module/report/report.module').then(m => m.ReportModule) },
      { path: 'atms/modal', loadChildren: () => import('./main/module/modal-test/modal-test.module').then(m => m.InvoiceTestModule) },
      { path: 'atms/modal', loadChildren: () => import('./main/module/modal-test/modal-test.module').then(m => m.InvoiceTestModule) },
      {
        path: "atms/user-guide",
        component: UserGuideComponent,
        canActivate: [AuthGuard],

      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes), ContentHeaderModule, TranslateModule, CoreCommonModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
