import { Component, Input, OnInit } from '@angular/core';

import { CoreMenuItem } from '@core/types';
import { Role, User } from 'app/auth/models';
import { AuthenticationService } from 'app/auth/service';

@Component({
  selector: '[core-menu-vertical-section]',
  templateUrl: './section.component.html',
})
export class CoreMenuVerticalSectionComponent implements OnInit {
  @Input()
  item: CoreMenuItem;
  forceHidden: boolean = false;
  currentUser: User;
  userRoleEnum = Role;
  constructor(private authService: AuthenticationService) {}

  ngOnInit(): void {
    this.currentUser = this.authService.currentUserValue;

    let validUserRole =
      this.currentUser.isSuperUserW ||
      this.currentUser.roleSetName == this.userRoleEnum.AccountantManager ||
      this.currentUser.roleSetName == this.userRoleEnum.AccountantSupervisor ||
      this.currentUser.roleSetName == this.userRoleEnum.SuperAdmin ||
      this.currentUser.roleSetName == this.userRoleEnum.FinanceManager ||
      this.currentUser.roleSetName == this.userRoleEnum.Accountant;

    if (!validUserRole && this.item.id == 'filling-tax') {
      this.forceHidden = true;
    }
  }
}
