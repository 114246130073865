import { CustomerService } from 'service/customer.service';
import { Component, OnInit } from '@angular/core';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsLocaleService, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { thBeLocale } from 'ngx-bootstrap/locale';
import { ActivatedRoute } from '@angular/router';
import { CustomerCreateComponent } from '../customer-create.component';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ApiService } from 'service/api.service';
import { UploadFileModalComponent } from 'app/main/module/task-document/upload-file-modal/upload-file-modal.component';
import { AdjustCreditModalComponent } from './adjust-credit-modal/adjust-credit-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'app/auth/service';
import { Role, User } from 'app/auth/models';
defineLocale('th-be', thBeLocale);
@Component({
  selector: 'app-customer-create-credit',
  templateUrl: './customer-create-credit.component.html',
  styleUrls: ['./customer-create-credit.component.scss'],
})
export class CustomerCreateCreditComponent implements OnInit {
  selectedDateFrom: Date;
  selectedDateTo: Date;

  currentUser: User
  userRole: string
  userRoleEnum = Role

  constructor(
    private localeService: BsLocaleService,
    private customerService: CustomerService,
    private customerCreateComponent: CustomerCreateComponent,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private modalService: NgbModal,
    private authenticationService: AuthenticationService
  ) {
    const token = JSON.parse(localStorage.getItem('token'));
    this.customerService.SetHttpHeaders(token);
    this.localeService.use('th-be');
    this.apiService.SetHttpHeaders(token);

    this.currentUser = this.authenticationService.currentUserValue;
    this.userRole = this.currentUser.roleSetName;
  }

  public dateOptions: Partial<BsDatepickerConfig> = {
    adaptivePosition: true,
    isAnimated: true,
    containerClass: 'theme-dark-blue',
    dateInputFormat: 'D MMM YYYY',
    showWeekNumbers: false,
  };
  selectDocType: number = 2;
  docTypeList = [
    { id: 1, name: 'Credit ลูกค้า' },
    { id: 2, name: 'Credit ภ.พ.30' },
  ];

  currentPage = 1;
  totalPages = 10;
  page: number = 1;
  pageLength: number = 10;
  totalItem: number = 0;
  pageSize: number = 10;
  loading: boolean = false;

  dataCreditTeble: any[] = [];
  customerId: string;
  @BlockUI() blockUI: NgBlockUI;

  ngOnInit(): void {
    this.checkRouteParam();
    this.getCreditList();
  }

  checkRouteParam() {
    this.customerId = this.customerCreateComponent.getCustomerId();
  }

  getCreditList() {
    let params = this.prepraeDateCriteria();

    if (this.selectDocType == 1) {
      this.blockUI.start();
      this.apiService
        .GetAllData(`credit/Credit/Customer/${this.customerId}`, params)
        .subscribe((res) => {
          this.dataCreditTeble = res.data.resultData;
          this.totalPages = Math.ceil(res.data.totalItem / this.pageSize);
          console.log('dataCreditTeble', this.dataCreditTeble);
          this.blockUI.stop();
        });
    } else if (this.selectDocType == 2) {
      this.blockUI.start();
      this.apiService
        .GetAllData(`creditpp30/CreditPP30/Customer/${this.customerId}`, params)
        .subscribe((res) => {
          this.dataCreditTeble = res.data.resultData;
          this.totalPages = Math.ceil(res.data.totalItem / this.pageSize);
          console.log('dataCreditTeble', this.dataCreditTeble);
          this.blockUI.stop();
        });
    }
  }

  // Pagination
  // ใช้การ Query ข้อมูลทั้งหมดมาแสดง แล้วแบ่งหน้า
  onPageChange(page: number) {
    this.currentPage = page;
    let params = this.prepraeDateCriteria();
    if (this.selectDocType == 1) {
      this.apiService
        .GetAllData(`credit/Credit/Customer/${this.customerId}`, params)
        .subscribe((res) => {
          this.dataCreditTeble = res.data.resultData;
          this.totalPages = Math.ceil(res.data.totalItem / this.pageSize);
        });
    } else if (this.selectDocType == 2) {
      this.apiService
        .GetAllData(`creditpp30/CreditPP30/Customer/${this.customerId}`, params)
        .subscribe((res) => {
          this.dataCreditTeble = res.data.resultData;
          this.totalPages = Math.ceil(res.data.totalItem / this.pageSize);
        });
    }
  }

  prepraeDateCriteria(): any {

    //ยังใช้ได้อยู่ ไม่ต้องแปลงเวลา ถ้าพังค่อยเปลี่ยนครับ 
    let p: any = {
      page: this.page,
      pageLength: this.pageSize,
    };

    if (this.selectedDateFrom) {
      this.selectedDateFrom.setHours(0);
      this.selectedDateFrom.setMinutes(0);
      this.selectedDateFrom.setSeconds(0);

      p.CreateDateFrom = this.selectedDateFrom.toISOString();
    }

    if (this.selectedDateTo) {
      this.selectedDateTo.setHours(23);
      this.selectedDateTo.setMinutes(59);
      this.selectedDateTo.setSeconds(59);

      p.CreateDateTo = this.selectedDateTo.toISOString();
    }

    return p;
  }

  async onSizePageChange(event: any) {
    this.currentPage = 1;
    this.pageSize = event;

    let params = this.prepraeDateCriteria();

    if (this.selectDocType == 1) {
      this.apiService
        .GetAllData(`credit/Credit/Customer/${this.customerId}`, params)
        .subscribe((res) => {
          this.dataCreditTeble = res.data.resultData;
          this.totalPages = Math.ceil(res.data.totalItem / event);
        });
    } else if (this.selectDocType == 2) {
      this.apiService
        .GetAllData(`creditpp30/CreditPP30/Customer/${this.customerId}`, params)
        .subscribe((res) => {
          this.dataCreditTeble = res.data.resultData;
          this.totalPages = Math.ceil(res.data.totalItem / event);
        });
    }
  }
  openAdjustCreditModal() {
    const modalRef = this.modalService.open(AdjustCreditModalComponent, {
      centered: true,
      size: 'lg',
    });
    modalRef.componentInstance.customerId = this.customerId;
    modalRef.componentInstance.callBackFunc.subscribe((res) => {
      this.getCreditList();
      this.modalService.dismissAll();
    });
  }

  onReset() {
    this.selectDocType = 1;
    this.selectedDateFrom = null
    this.selectedDateTo = null
    this.getCreditList();
  }
}
