import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import 'hammerjs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr'; // For auth after login toast

import { CoreModule } from '@core/core.module';
import { CoreCommonModule } from '@core/common.module';
import { CoreSidebarModule, CoreThemeCustomizerModule } from '@core/components';

import { coreConfig } from 'app/app-config';
// import { PdfViewerModule } from 'ng2-pdf-viewer';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { SampleModule } from 'app/main/sample/sample.module';
import { HomeComponent } from './main/module/home/home.component';
import { AppRoutingModule } from './app-routing.module';
import { CustomerComponent } from './main/module/customer/customer.component';
import { CustomerRoutingModule } from './main/module/customer/customer-routing.module';
import { UserRoutingModule } from './main/module/user/user-routing.module';
import { AuthenticationModule } from './main/pages/authentication/authentication.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';
import { JwtModule } from '@auth0/angular-jwt';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { SharedModule } from 'shared/shared.module';
import { MyTaskModule } from './main/module/my-task/my-task.module';
import { InvoiceTestComponent } from './main/module/modal-test/modal-test.component';
import { CorePipesModule } from '@core/pipes/pipes.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { BlockUIModule } from 'ng-block-ui';
import { UserGuideModule } from './main/module/manage-users/user-guide/user-guide.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ReportComponent } from './main/module/report/report.component';
import { ErrorInterceptor } from './auth/helpers';

const appRoutes: Routes = [
  // {
  //   path: 'pages',
  //   loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule)
  // },
  // {
  //   path: '',
  //   children: [
  //     { path: '/customer', loadChildren: () => import('./main/module/customer/customer.module').then(m => m.CustomerModule) },
  //   ]
  // }
  // },
  // {
  //   path: '**',
  //   redirectTo: '/pages/miscellaneous/error' //Error 404 - Page not found
  // }
];

@NgModule({
  declarations: [AppComponent, InvoiceTestComponent,],
  imports: [
    TooltipModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled', // Add options right here
      relativeLinkResolution: 'legacy'
    }),
    TranslateModule.forRoot(),

    //NgBootstrap
    NgbModule,
    ToastrModule.forRoot(),

    //pdfviewer
    PdfViewerModule,

    // Core modules
    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    CoreSidebarModule,
    CoreThemeCustomizerModule,

    // Jwt
    JwtModule.forRoot({
      config: {
        tokenGetter: () => {
          return localStorage.getItem('token');
        },
        allowedDomains: ['localhost:4200'],
        disallowedRoutes: ['http://localhost:4200/authentication/login-v2']
      }
    }),

    // App modules
    LayoutModule,
    SampleModule,
    AppRoutingModule,
    CustomerRoutingModule,
    MyTaskModule,
    UserRoutingModule,
    AuthenticationModule,
    NgSelectModule,
    Ng2FlatpickrModule,
    FormsModule, 
    ReactiveFormsModule,
    SharedModule,
    DragDropModule,
    CorePipesModule,
    BlockUIModule.forRoot(),
    // UserGuideModule
    
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
