import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { CoreCommonModule } from '@core/common.module';

import { AuthLoginV2Component } from 'app/main/pages/authentication/auth-login-v2/auth-login-v2.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { VerifyComponent } from './verify/verify.component';

// routing
const routes: Routes = [
  {
    path: '',
    component: AuthLoginV2Component,
    data: { animation: 'auth' },
  },
  {
    path: 'forget-password',
    component: ForgetPasswordComponent,
    data: { animation: 'forget-password' },
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    data: { animation: 'reset-password' },
  },
  {
    path: 'verify',
    component: VerifyComponent,
    data: { animation: 'verify' },
  },
];

@NgModule({
  declarations: [AuthLoginV2Component,ForgetPasswordComponent,ResetPasswordComponent,VerifyComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    CoreCommonModule,
  ],
})
export class AuthenticationModule {}
