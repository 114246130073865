export enum Role {
  SuperAdmin = 'Super Admin',
  Admin = 'Admin',
  Accountant = 'Accountant',
  AccountantManager = 'Accountant Manager',
  AccountantSupervisor = 'Accountant Supervisor',
  Finance = 'Finance',
  FinanceSupervisor = 'Finance Supervisor',
  FinanceManager = 'Finance Manager',
}
