import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-cancel-modal',
  templateUrl: './cancel-modal.component.html',
  styleUrls: ['./cancel-modal.component.scss']
})
export class CancelModalComponent implements OnInit {
 
  @ViewChild('cancelModal') cancelModal: any;

  constructor(private ngbModal : NgbModal) { }

  ngOnInit(): void {
  }

  openModal() {
    this.ngbModal.open(this.cancelModal, {       
      animation: false,
      size: 'lg',
      centered: true,
      backdrop: 'static',
      keyboard: false,
    });
  }

  closeModal() {
    this.ngbModal.dismissAll();
  }
}
