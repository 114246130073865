import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-transfer-task',
  templateUrl: './transfer-task.component.html',
  styleUrls: ['./transfer-task.component.scss']
})
export class TransferTaskComponent implements OnInit {

  @ViewChild('transferTaskModal') transferTask: any;

  constructor(private ngbModal: NgbModal,) { }

  ngOnInit(): void {
  }

  itemList = [{id:1,name:'Package A'},{id:2,name:'Package B'},{id:3,name:'Package C'}];

  openModal() {
    this.ngbModal.open(this.transferTask, {       
      animation: false,
      size: 'lg',
      centered: true,
      backdrop: 'static',
      keyboard: false,
    });
  }

  closeModal() {
    this.ngbModal.dismissAll();
  }
}
