import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FilesApiService {
  private apiBaseUrl: string;
  private apiPath: string;
  private httpHeaders: HttpHeaders;

  constructor(private http: HttpClient, private _apiService: ApiService) {
    this.apiBaseUrl = this._apiService.API_URL;
    this.httpHeaders = this._apiService.API_HEADERS;
  }

  GetFile(guid: string): Observable<any> {
    let API_URL = `${this.apiBaseUrl}/file/${guid}`;
    return this.http
      .get(API_URL, { headers: this.httpHeaders, responseType: 'blob' })
      .pipe(catchError(this._apiService.handleError));
  }

  GetFileDetail(guid: string): Observable<any> {
    let API_URL = `${this.apiBaseUrl}/file/detail/${guid}`;
    return this.http
      .get(API_URL, { headers: this.httpHeaders })
      .pipe(catchError(this._apiService.handleError));
  }

  AddFile(
    tablename: string,
    contentID: string,
    collectionName: string,
    ordinal: number,
    body: any
  ): Observable<any> {
    let API_URL = `${this.apiBaseUrl}/file/${tablename}/${contentID}/${collectionName}/${ordinal}`;
    return this.http
      .post(API_URL, body, {
        headers: new HttpHeaders().delete('Content-Type'),
      })
      .pipe(catchError(this._apiService.handleError));
  }

  UpdateFile(
    tablename: string,
    contentID: string,
    collectionName: string,
    ordinal: number,
    body: any
  ): Observable<any> {
    let API_URL = `${this.apiBaseUrl}/file/${tablename}/${contentID}/${collectionName}/${ordinal}`;
    return this.http
      .put(API_URL, body, { headers: new HttpHeaders().delete('Content-Type') })
      .pipe(catchError(this._apiService.handleError));
  }

  AddCover(
    tablename: string,
    contentID: string,
    collectionName: string,
    ordinal: number,
    body: any
  ): Observable<any> {
    let API_URL = `${this.apiBaseUrl}/file/c/${tablename}/${contentID}/${collectionName}/${ordinal}`;
    console.log(API_URL);

    return this.http
      .post(API_URL, body, {
        headers: new HttpHeaders().delete('Content-Type'),
      })
      .pipe(catchError(this._apiService.handleError));
  }

  UpdateCover(
    tablename: string,
    contentID: string,
    collectionName: string,
    ordinal: number,
    body: any
  ): Observable<any> {
    let API_URL = `${this.apiBaseUrl}/file/c/${tablename}/${contentID}/${collectionName}/${ordinal}`;
    return this.http
      .put(API_URL, body, {
        headers: new HttpHeaders().delete('Content-Type'),
      })
      .pipe(catchError(this._apiService.handleError));
  }

  DeleteFile(tablename: string, contentID: string): Observable<any> {
    let API_URL = `${this.apiBaseUrl}/file/${tablename}/${contentID}`;
    return this.http
      .delete(API_URL, {
        headers: new HttpHeaders().delete('Content-Type'),
      })
      .pipe(catchError(this._apiService.handleError));
  }
  DeleteFileStore(contentID: string): Observable<any> {
    let API_URL = `${this.apiBaseUrl}/file/deletestore/${contentID}`;
    return this.http
      .delete(API_URL, {
        headers: new HttpHeaders().delete('Content-Type'),
      })
      .pipe(catchError(this._apiService.handleError));
  }

  DeleteCover(tablename: string, contentID: string): Observable<any> {
    let API_URL = `${this.apiBaseUrl}/file/c/${tablename}/${contentID}`;
    return this.http
      .delete(API_URL, {
        headers: new HttpHeaders().delete('Content-Type'),
      })
      .pipe(catchError(this._apiService.handleError));
  }

  SetActiveFile(
    tablename: string,
    contentID: string,
    body: any,
    isDelete: boolean
  ): Observable<any> {
    let API_URL = `${this.apiBaseUrl}/file/${tablename}/${contentID}/delete/${isDelete}`;
    return this.http
      .patch(API_URL, body, {
        headers: new HttpHeaders().delete('Content-Type'),
      })
      .pipe(catchError(this._apiService.handleError));
  }

  SetActiveCover(
    tablename: string,
    contentID: string,
    body: any,
    isDelete: boolean
  ): Observable<any> {
    let API_URL = `${this.apiBaseUrl}/file/c/${tablename}/${contentID}/delete/${isDelete}`;
    return this.http
      .patch(API_URL, body, {
        headers: new HttpHeaders().delete('Content-Type'),
      })
      .pipe(catchError(this._apiService.handleError));
  }

  getImage(filename, params?: { [key: string]: any }): string {
    let apiPath = `${this.apiBaseUrl}/file/image/${filename}`;

    if (!params) {
      params = {};
    }

    params.KeepRatio = true;
    const httpParams = new HttpParams({ fromObject: params });
    apiPath += `?${httpParams.toString()}`;

    return apiPath;
  }
  
}
