<!-- <div class="pagination">
    <button (click)="previousPage()" [disabled]="currentPage === 1">Previous</button>
    <button (click)="nextPage()" [disabled]="currentPage === totalPages">Next</button>
</div>

<div class="data">
    
</div> -->
<div class="d-flex justify-content-between px-2" style="align-items: center;">
    <div class="items-per-page d-flex float-left">
        <label for="itemsPerPage" class="mr-1">page </label>
        <select id="itemsPerPage" [(ngModel)]="itemsPerPage" (change)="changeItemsPerPage()" class="form-control">
            <option value="10">10</option>
            <option value="50">50</option>
            <option value="100">100</option>
        </select>
    </div>
    <nav class="d-flex">
        <ul class="pagination m-0">
            <li class="page-item" [class.disabled]="currentPage === 1">
                <a class="page-link" (click)="firstPage()"> << </a>
            </li>
            <li class="page-item" [class.disabled]="currentPage === 1">
                <a class="page-link" (click)="previousPage()">Previous</a>
            </li>
            <li class="page-item" *ngFor="let page of pages" [class.active]="page === currentPage">
                <a class="page-link" (click)="goToPage(page)">{{ page }}</a>
            </li>
            <li class="page-item" [class.disabled]="currentPage === totalPages">
                <a class="page-link" (click)="nextPage()">Next</a>
            </li>
            <li class="page-item" [class.disabled]="currentPage === totalPages">
                <a class="page-link" (click)="lastPage()"> >> </a>
            </li>
        </ul>
    </nav>
    <!-- <div class="d-flex"></div> -->
</div>