import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { throws } from 'assert';
import { CoreMenuVerticalItemComponent } from '@core/components/core-menu/vertical/item/item.component';

@Injectable({
  providedIn: 'root',
})
export class OverdueService {
  public overdueCount: number = 0;

  itemMenu: CoreMenuVerticalItemComponent

  constructor(private apiService: ApiService) {
    const token = JSON.parse(localStorage.getItem('token'))
    this.apiService.SetHttpHeaders(token);
    
  }

  get overdueValue(): string {
    if (this.overdueCount <= 0) {
      return '0';
    } else if (this.overdueCount > 99) {
      return '99+';
    } else {
      return this.overdueCount.toString();
    }
  }

  setOverdueFromApi(): Promise<any> {
    return new Promise((resolve, rejects) => {
      this.apiService.GetAllData('myoverdue/getoverduecount').subscribe(res => {
        this.overdueCount = res.data.resultData[0]
        resolve(this.overdueCount)
        console.log(this.overdueCount)
      })
    })
  }

  setNumOnMenu() {
    this.setOverdueFromApi().then((res) => {
      let overTaskNum = document.getElementById(
        'overTask-badge'
      ) as HTMLElement;
      overTaskNum.innerText = this.overdueValue;
    });
  }
}
