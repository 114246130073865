<div class="row px-3">
  <div class="col-12">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body px-3 py-2">
            <div class="row justify-content-between">
              <div class="col-lg-4 col-md-5 col-12">
                <label class="text-sub-document">ประเภทเครดิด</label>
                <ng-select [(ngModel)]="selectDocType" [clearable]="false">
                  <ng-option
                    *ngFor="let data of docTypeList"
                    [value]="data.id"
                    >{{ data.name }}</ng-option
                  >
                </ng-select>
              </div>
              <div class="col-lg-5 col-md-7 col-12">
                <div class="row">
                  <div class="col-md-6 col-12">
                    <label class="text-sub-document">ค้นหาจากวันที่</label>
                    <input
                      type="text"
                      [bsConfig]="dateOptions"
                      placeholder="DD/MM/YYYY"
                      class="form-control"
                      [(ngModel)]="selectedDateFrom"
                      bsDatepicker
                    />
                  </div>
                  <div class="col-md-6 col-12">
                    <label class="text-sub-document">ค้นหาจนถึงวันที่</label>
                    <input
                      type="text"
                      [bsConfig]="dateOptions"
                      placeholder="DD/MM/YYYY"
                      class="form-control"
                      [(ngModel)]="selectedDateTo"
                      bsDatepicker
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row justify-content-center mt-3">
              <div class="col-lg-2 col-md-3 col-12">
                <button class="btn btn-main w-100" (click)="getCreditList()">
                  ค้นหา
                </button>
              </div>
              <div class="col-lg-2 col-md-3 col-12">
                <button class="btn btn-cancel w-100" (click)="onReset()">
                  ล้างข้อมูล
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="p-0 mb-2">
          <button
            (click)="openAdjustCreditModal()"
            [disabled]="
              userRole == userRoleEnum.Finance ||
              userRole == userRoleEnum.FinanceSupervisor ||
              userRole == userRoleEnum.FinanceManager
            "
            type="button"
            class="text-sub-document btn btn-primary"
          >
            จัดการเครดิต
          </button>
        </div>
        <div class="card">
          <div class="card-body p-0">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">NO.</th>
                    <th scope="col">วันที่</th>
                    <th scope="col">จำนวน Credit</th>
                    <th scope="col">ยอดคงเหลือ</th>
                    <th scope="col">หมายเหตุ</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngIf="dataCreditTeble.length <= 0">
                    <td colspan="100%" class="text-center">
                      ไม่มีข้อมูลเครดิต
                    </td>
                  </tr>
                  <tr *ngFor="let data of dataCreditTeble; let i = index">
                    <th scope="row">
                      {{ i + 1 + (currentPage * pageSize - pageSize) }}
                    </th>
                    <td>{{ data.createDate | thaidate : "D/M/yyyy" }}</td>
                    <td>{{ data.amount | number : "1.2-2" }}</td>
                    <td>{{ data.balance | number : "1.2-2" }}</td>
                    <td>{{ data.remark }}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="row my-2">
              <div class="col-12">
                <app-pagination
                  [currentPage]="currentPage"
                  [totalPages]="totalPages"
                  (pageChange)="onPageChange($event)"
                  (sizePageChange)="onSizePageChange($event)"
                ></app-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
