<!-- Modal -->
<ng-template #textAreaFormModal let-modal>
    <div class="modal-header text-center bg-primary">
        <h3 class="modal-title w-100">Account Task Management System</h3>
        <button type="button" class="btn-close float-right" (click)="closeModal()">&times;</button>
    </div>
    <div class="modal-body">
        <div class="my-4">
            <h4 class="big-text blue-text text-center">
                กรุณาระบุเหตุผลการคืนเงิน
            </h4>
            <br />
            <div class="row justify-content-center">
                <div class="form-group">
                    <textarea class="form-control" id="textArea" rows="7" style="resize: none; width: 300px;"></textarea>
                  </div>
            </div>
        </div>
    </div>
    <div class="modal-footer justify-content-between">
        <button type="button" class="btn btn-danger" (click)="closeModal()">ยกเลิก</button>
        <button type="button" class="btn btn-primary">ยืนยัน</button>
    </div>
</ng-template>