
<div class="content-wrapper container-xxl p-0">
  <app-content-header [contentHeader]="contentHeader"></app-content-header>
  <a target="_blank" [href]="src" class="btn btn-primary">Download</a>
  <div class=" d-flex bg-light justify-content-center align-items-center">
    <div class="pdf-container">
      <pdf-viewer
        [src]="src"
        [rotation]="0"
        [original-size]="true"
        [show-all]="true"
        [fit-to-page]="true"
        [zoom]="1"
        [zoom-scale]="'page-width'"
        [stick-to-page]="false"
        [render-text]="false"
        [external-link-target]="'blank'"
        [autoresize]="true"
        [show-borders]="false"
        style="width: 800px; height: 790px"
      ></pdf-viewer>
    </div>
  </div>
</div>
