<div class="content-wrapper container-xxl p-0" >
    <div class="content-body">
        <app-content-header [contentHeader]="contentHeader"></app-content-header>
        <!-- Basic Alerts start -->
        <section>
            <router-outlet></router-outlet>
        </section>
        <!-- Basic Alerts end -->
    </div>
</div>
