import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {
  // data: any[]; // Your complete dataset
  // paginatedData: any[]; // Data for the current page
  itemsPerPage: number = 10; // Number of items per page
  // currentPage: number = 1; // Current page number
  // totalPages: number; // Total number of pages
  // totalItemsToDisplay: number = 20;
  

  @Input() currentPage: number;
  @Input() totalPages: number;
  @Output() pageChange = new EventEmitter<number>();
  @Output() sizePageChange = new EventEmitter<number>();
  
  pageSize = 5; // Number of pages to display
  pages: number[] = [];

  ngOnChanges() {
    this.generatePageNumbers();
  }

  changeItemsPerPage(): void {
    this.currentPage = 1; // Reset to the first page
    // this.totalPages = Math.ceil(this.totalItemsToDisplay / this.itemsPerPage);
    // this.paginatedData = this.getPaginatedData();
    this.sizePageChange.emit(this.itemsPerPage);
  }
  generatePageNumbers() {
      this.pages = [];
      const startPage = Math.max(1, this.currentPage - Math.floor(this.pageSize / 2));
      const endPage = Math.min(this.totalPages, startPage + this.pageSize - 1);
      for (let i = startPage; i <= endPage; i++) {
        this.pages.push(i);
      }
  }
  previousPage() {
    if (this.currentPage > 1) {
      this.pageChange.emit(this.currentPage - 1);
    }
  }

  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.pageChange.emit(this.currentPage + 1);
    }
  }

  goToPage(page: number) {
    if (this.currentPage !== page) {
      this.pageChange.emit(page);
    }
  }
  lastPage() {
    if (this.currentPage < this.totalPages) {
      this.pageChange.emit(this.totalPages);
    }
  }

  firstPage() {
      this.pageChange.emit(1);
  }
}
