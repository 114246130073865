import { Component, Input, OnInit } from '@angular/core';

import { CoreMenuItem } from '@core/types';
import { Role, User } from 'app/auth/models';
import { AuthenticationService } from 'app/auth/service';
import { OverdueService } from 'service/overdue.service';

@Component({
  selector: '[core-menu-vertical-item]',
  templateUrl: './item.component.html',
})
export class CoreMenuVerticalItemComponent implements OnInit {
  @Input()
  item: CoreMenuItem;

  overdueCount: string = '0';
  forceHidden: boolean = false;
  currentUser: User;
  userRoleEnum = Role;

  constructor(
    private overdueService: OverdueService,
    private authService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.currentUser = this.authService.currentUserValue;

    if (this.item.id == 'overTask') {
      this.fetchOverdueNumber();
    }

    let validUserRole =
      this.currentUser.isSuperUserW ||
      this.currentUser.roleSetName == this.userRoleEnum.AccountantManager ||
      this.currentUser.roleSetName == this.userRoleEnum.AccountantSupervisor ||
      this.currentUser.roleSetName == this.userRoleEnum.SuperAdmin ||
      this.currentUser.roleSetName == this.userRoleEnum.FinanceManager ||
      this.currentUser.roleSetName == this.userRoleEnum.Accountant;

    if (!validUserRole && this.item.id == 'filling-tax-report') {
      this.forceHidden = true;
    }
  }

  fetchOverdueNumber() {
    if (this.item.id == 'overTask') {
      this.overdueService.setOverdueFromApi().then((res) => {
        this.overdueCount = this.overdueService.overdueValue;

        let overTaskNum = document.getElementById(
          'overTask-badge'
        ) as HTMLElement;
        overTaskNum.innerText = this.overdueCount;
      });
    }
  }
}
