import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { CustomerCreateComponent } from './customer-create/customer-create.component';
import { CustomerComponent } from './customer.component';
import { CustomerIndexComponent } from './customer-index/customer-index.component';
import { CustomerCreateBusinessComponent } from './customer-create/customer-create-business/customer-create-business.component';
import { CustomerCreateCreditComponent } from './customer-create/customer-create-credit/customer-create-credit.component';
import { CustomerCreatePackageComponent } from './customer-create/customer-create-package/customer-create-package.component';
import { AuthGuard } from 'app/auth/helpers/auth.guards';

const routes: Routes = [
  {
    path: "",
    component: CustomerComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        component: CustomerIndexComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "create",
        component: CustomerCreateComponent,
        canActivate: [AuthGuard],
        children: [
          {path: "", redirectTo: "business", pathMatch: "full" },
          {path: "business", canActivate: [AuthGuard], component: CustomerCreateBusinessComponent},
          {path: "package", canActivate: [AuthGuard], component: CustomerCreatePackageComponent},
          {path: "credit", canActivate: [AuthGuard], component: CustomerCreateCreditComponent},
        ]
      },
      {
        path: "view/:id",
        component: CustomerCreateComponent,
        canActivate: [AuthGuard],
        children: [
          {path: "", redirectTo: "business", pathMatch: "full" },
          {path: "business", canActivate: [AuthGuard], component: CustomerCreateBusinessComponent},
          {path: "package", canActivate: [AuthGuard], component: CustomerCreatePackageComponent},
          {path: "credit", canActivate: [AuthGuard], component: CustomerCreateCreditComponent},
        ]
      },
      {
        path: "edit/:id",
        component: CustomerCreateComponent,
        canActivate: [AuthGuard],
        children: [
          {path: "", redirectTo: "business", pathMatch: "full" },
          {path: "business", canActivate: [AuthGuard], component: CustomerCreateBusinessComponent},
          {path: "package", canActivate: [AuthGuard], component: CustomerCreatePackageComponent},
          {path: "credit", canActivate: [AuthGuard], component: CustomerCreateCreditComponent},
        ]
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CustomerRoutingModule { }
