import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-my-task',
  templateUrl: './my-task.component.html',
  styleUrls: ['./my-task.component.scss']
})
export class MyTaskComponent implements OnInit {
  contentHeader: object;

  constructor() { }

  ngOnInit(): void {
    this.contentHeader = {
      headerTitle: 'หน้าหลัก',
      actionButton: false,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'งานของฉัน',
            isLink: true,
            link: '/atms/my-task'
          },
        ]
      }
    }
  }

}
