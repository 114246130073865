import { Component, OnInit } from '@angular/core';
import { CustomerCreateBusinessComponent } from './customer-create-business/customer-create-business.component';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CustomerService } from 'service/customer.service';

@Component({
  selector: 'app-customer-create',
  templateUrl: './customer-create.component.html',
  styleUrls: ['./customer-create.component.scss'],
})
export class CustomerCreateComponent implements OnInit {
  constructor(private route: ActivatedRoute) {}
  public activeParentRoute: string;
  viewCheck: boolean = true;
  editCheck: boolean = true;
  createCheck: boolean = true;
  customerId: string = '';
  ngOnInit(): void {
    this.route.url.subscribe((params) => {
      this.activeParentRoute = params[0].path;

      if (this.activeParentRoute == 'view') {
        this.viewCheck = false;
      } else if (this.activeParentRoute == 'edit') {
        this.editCheck = false;
      } else {
        this.createCheck = false;
      }
    });

    this.route.paramMap.subscribe((params) => {
      this.customerId = params.get('id');
    });
  }

  public getActiveParentRoute() {
    return this.activeParentRoute;
  }

  public getCustomerId() {
    return this.customerId;
  }
}
