import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { User, Role } from 'app/auth/models';
import { ToastrService } from 'ngx-toastr';
import { Token } from '../models/token';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  //public
  public token: Observable<string>;
  public refToken: Observable<string>;
  public currentUser: Observable<User>;

  //private
  private tokenSubject: BehaviorSubject<string>;
  private refreshTokenSubject: BehaviorSubject<string>;
  private currentUserSubject: BehaviorSubject<User>;
  private userRoleEnum = Role;

  @BlockUI() blockUI: NgBlockUI;

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(
    private _http: HttpClient,
    private _toastrService: ToastrService,
    private _router: Router
  ) {
    this.tokenSubject = new BehaviorSubject<string>(
      localStorage.getItem('token')
        ? JSON.parse(localStorage.getItem('token') as string)
        : null
    );
    this.token = this.tokenSubject.asObservable();

    this.refreshTokenSubject = new BehaviorSubject<string>(
      localStorage.getItem('refreshToken')
        ? JSON.parse(localStorage.getItem('refreshToken') as string)
        : null
    );
    this.refToken = this.refreshTokenSubject.asObservable();

    this.currentUserSubject = new BehaviorSubject<User>(
      localStorage.getItem('currentUser')
        ? JSON.parse(
            decodeURIComponent(
              window.atob(localStorage.getItem('currentUser') as string)
            )
          )
        : null
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get tokenValue(): string {
    return this.tokenSubject.value;
  }

  public get refreshTokenValue(): string {
    return this.refreshTokenSubject.value;
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
   *  Confirms if user is admin
   */
  get isAdmin() {
    return (
      this.currentUser && this.currentUserSubject.value.role === Role.Admin
    );
  }

  isLoggedIn(): boolean {
    return this.tokenValue != null;
  }

  /**
   *  Confirms if user is client
   */

  login(userName: string, password: string): Observable<any> {
    const url = `${environment.apiUrl}/api/auth/cms/login`;

    const requestBody = { userName: userName, password: password };

    // Define HTTP headers (optional)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      // Add any other headers as needed
    });

    // Make the HTTP POST request
    return this._http.post(url, requestBody, { headers }).pipe(
      map((response: any) => {
        if (response.data.token) {
          localStorage.setItem('token', JSON.stringify(response.data.token));
          localStorage.setItem(
            'refreshToken',
            JSON.stringify(response.data.refreshToken)
          );
          localStorage.setItem('email', JSON.stringify(response.data.email));

          this.tokenSubject.next(response.data.token);
          this.refreshTokenSubject.next(response.data.refreshToken);
          this.currentUserSubject.next(response.data);
        }
        return response;
      })
    );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('email');

    this.tokenSubject.next(null);
    this.currentUserSubject.next(null);
    this.blockUI.stop();
    this._router.navigate(['/login']);
  }

  getUserDetails(): Observable<any> {
    const token = JSON.parse(localStorage.getItem('token'));
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`, // Use 'Bearer' if it's a JWT token
      }),
    };

    return this._http
      .get(`${environment.apiUrl}/api/account/info`, httpOptions)
      .pipe(
        map((res: any) => {
          if (res && res.data) {
            localStorage.setItem(
              'currentUser',
              window.btoa(
                encodeURIComponent(JSON.stringify(res.data.resultData[0]))
              )
            );
            this.currentUserSubject.next(res.data.resultData[0]);
          }
          return res.data.resultData[0];
        })
      );
  }

  refreshToken(): Observable<any> {
    return this._http
      .post<any>(`${environment.apiUrl}/api/auth/cms/refresh-token`, {
        accessToken: this.tokenValue,
        refreshToken: this.refreshTokenValue,
      })
      .pipe(
        map((res) => {
          if (
            !res.success ||
            res.data.resultData[0].message == 'Token has expired'
          ) {
            Swal.fire('Session Expired', 'Please login again', 'error');
            this.logout();
          } else {
            if (
              res.data.resultData[0].success &&
              res.data.resultData[0].token &&
              res.data.resultData[0].refreshToken
            ) {
              localStorage.setItem(
                'token',
                JSON.stringify(res.data.resultData[0].token)
              );
              localStorage.setItem(
                'refreshToken',
                JSON.stringify(res.data.resultData[0].refreshToken)
              );
              this.refreshTokenSubject.next(
                res.data.resultData[0].refreshToken
              );
              this.tokenSubject.next(res.data.resultData[0].token);
            }
          }
          return res.data.resultData[0];
        }),
        catchError(() => EMPTY)
      );
  }

  revokeToken() {
    let httpHeaders;
    httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${this.tokenValue}`);
    return this._http
      .post<any>(
        `${environment.apiUrl}/api/auth/cms/revoke-token`,
        {
          refreshToken: this.refreshTokenSubject,
        },
        { headers: httpHeaders }
      )
      .pipe(
        map((res) => {
          if (!res.success) {
            this.logout();
            window.location.href = '/login';
          } else {
          }
          return res;
        }),
        catchError(() => EMPTY)
      );
  }

  sendEmailforgetPassword(email: string): Observable<any> {
    return this._http.post<any>(
      `${environment.apiUrl}/api/account/forgotPassword`,
      { email: email }
    );
  }

  IsAccountDepartment(): boolean {
    return (
      this.currentUserValue.roleSetName == this.userRoleEnum.Accountant ||
      this.currentUserValue.roleSetName ==
        this.userRoleEnum.AccountantSupervisor ||
      this.currentUserValue.roleSetName == this.userRoleEnum.AccountantManager
    );
  }

  IsFinanceDepartment(): boolean {
    return (
      this.currentUserValue.roleSetName == this.userRoleEnum.Finance ||
      this.currentUserValue.roleSetName ==
        this.userRoleEnum.FinanceSupervisor ||
      this.currentUserValue.roleSetName == this.userRoleEnum.FinanceManager
    );
  }

  IsSuperAdmin(): boolean {
    return this.currentUserValue.roleSetName == this.userRoleEnum.SuperAdmin;
  }

  isSuperUserW(): boolean {
    return this.currentUserValue.isSuperUserW;
  }
  isManager(): boolean {
    return (
      this.currentUserValue.roleSetName == this.userRoleEnum.FinanceManager ||
      this.currentUserValue.roleSetName == this.userRoleEnum.AccountantManager
    );
  }
}
