class Invoice {
    id: string;
    customerId: string;
    customerName: string;
    customerTaxId: string;
    transactionStatusId: number;
    issuerId: string;
    issuerName: string;
    issureAddress: string;
    issurePhone: string;
    issureFax: string;
    issureTaxId: string;
    invoiceCode: string;
    monthId: number;
    monthName: string;
    jobId: string;
    documentCreateDate: string;
    documentExpireDate: string;
    references: string;
    remarks: string;
    invoiceId?: string;
    taxInvoiceAddressId: string;
    taxInvoiceAddressDetail: string;
    isCreateInvoice: boolean;
    createByName: string;
    createDate: string;
    approveByName: string;
    period: number;
    isVoid: boolean;
    isActive: boolean;
    isDelete: boolean;
    updateBy: string;
    updateDate: string;
    deleteBy: string;
    deleteDate: string;
    item: InvoiceItem[];
}

export interface InvoiceItem {
    id?: string;
    ordinal: number;
    invoiceId?: string;
    itemId: string;
    itemName?: string;
    description?: string;
    otherPaidWay?: string;
    serviceAmount: number;
    taxAmount: number;
    isTaxAmount: boolean;
    fineAmount?: number;
    submitType?: string;
    vatAmount?: number;
    unitPrice?: number;
    preTaxAmount?: number;
    credit?: number;
    grandTotal?: number;
    isActive?: boolean;
    isDelete?: boolean;
    remarks?: String;
    uniqueId: string;
    createBy?: string;
    createDate?: string;
    updateBy?: string;
    updateDate?: string;
    deleteBy?: string;
    deleteDate?: string;
    documentTypeEnum?: number;
}

export class TempRowInvoice {
    rowType:
        | 'item'
        | 'description'
        | 'accepted-item'
        | 'accepted-desc'
        | 'credit-item'
        | 'credit-desc'
        | 'debit-item'
        | 'debit-desc';
    id?: string;
    ordinal: number;
    invoiceId?: string;
    itemId: string;
    itemName?: string;
    description?: string;
    otherPaidWay?: string;
    serviceAmount: any;
    taxAmount: any;
    isTaxAmount: boolean;
    fineAmount?: any;
    submitType?: string;
    vatAmount?: number;
    preTaxAmount?: number;
    credit?: number;
    grandTotal?: number;
    isActive?: boolean;
    isDelete?: boolean;
    remarks?: String;
    uniqueId: string;
    createBy?: string;
    createDate?: string;
    updateBy?: string;
    updateDate?: string;
    deleteBy?: string;
    deleteDate?: string;
    documentTypeEnum?: number;
    unitPrice?: number;
    isNewRow?: boolean = false;
}

export default Invoice;

// const InvoiceItemList: InvoiceItem[] = [
//     {
//         id: '1',
//         ordinal: 1,
//         itemId: '1',
//         itemName: 'ภพ.30',
//         description: 'เพิ่มเติม เดือนมกราคม 2566',
//         otherPaidWay: 'ยื่นผ่านเน็ต',
//         serviceAmount: null,
//         taxAmount: 2080,
//         isTaxAmount: true,
//         paidWay: 'บริษัทจ่าย',
//         grandTotal: 2080,
//         uniqueId: '1'
//     },
//     {
//         id: '2',
//         ordinal: 2,
//         itemId: '2',
//         itemName: 'ค่าบริการ Version 2',
//         description: '10-20 Transection',
//         serviceAmount: 3500,
//         taxAmount: null,
//         isTaxAmount: false,
//         grandTotal: 3500,
//         uniqueId: '2'
//     }
// ]

// export const InvoiceList: Invoice[] = [
//     {
//         id: '1',
//         customerId: '1',
//         customerName: 'บริษัท หจก. จี.ดี.เค. เซอร์วิส',
//         customerTaxId: '0105023246789',
//         transactionStatusId: 1,
//         issuerId: '1',
//         issuerName: 'บริษัท แอคเคาท์เวิร์ค จำกัด',
//         issureAddress: '1/1-2 ซอยสุภาพงษ์ 3 แยก 8 แขวงหนองบอน เขตประเวศ กรุงเทพมหานคร 10250',
//         issurePhone: '02-1234567',
//         issureFax: '02-1234567',
//         issureTaxId: '0105558183381',
//         invoiceCode: '1',
//         monthId: 5,
//         monthName: 'พฤษภาคม',
//         jobId: 'JOB-IV2306011',
//         documentCreateDate: '1 มิถุนายน 2565',
//         documentExpireDate: '3 มิถุนายน 2565',
//         references: 'references',
//         remarks: 'remarks',
//         taxInvoiceAddressId: '1',
//         taxInvoiceAddressDetail: '1/1 ซอย14 ถนนสุขุมวิท แขวงอุดมสุข กรุงเทพมหานคร 10110',

//         isCreateInvoice: false,
//         createByName: 'Somchai',
//         createDate: '',
//         approveByName: 'Somying',
//         period: 1,
//         isVoid: false,
//         isActive: true,
//         isDelete: false,
//         updateBy: '',
//         updateDate: '',
//         deleteBy: '',
//         deleteDate: '',
//         item: InvoiceItemList,
//     }
// ]