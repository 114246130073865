<div class="auth-wrapper auth-v2">
  <div class="auth-inner row m-0">
    <!-- Brand logo-->
    <!-- <a class="brand-logo" href="javascript:void(0);">
      <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" height="28" />
      <h2 class="brand-text text-primary ml-1">Vuexy</h2></a
    > -->
    <!-- /Brand logo-->
    <!-- Left Text-->
    <div class="d-none d-lg-flex col-lg-8 align-items-center p-5 bg-main">
      <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
        <!-- <img
          class="img-fluid"
          [src]="
            coreConfig.layout.skin === 'dark'
              ? 'assets/images/pages/login-v2-dark.svg'
              : 'assets/images/pages/login-v2.svg'
          "
          alt="Login V2"
        /> -->
        <img class="img-fluid" src="../../../../../assets/images/pages/login/accountworks-login-img.png" alt="Login V2"
          style="max-height: 500px;" />
      </div>
    </div>
    <!-- /Left Text-->
    <!-- Login-->
    <div class="d-flex col-lg-4 align-items-center auth-bg px-2 p-lg-5">
      <div class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto">
        <!-- <h2 class="card-title font-weight-bold mb-1">Welcome to Vuexy! 👋</h2> -->
        <h2 class="card-title font-weight-bold mb-3 text-center">Account Task Management System</h2>
        <!-- <p class="card-text mb-2">Please sign-in to your account and start the adventure</p> -->

        <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="error">
          <div class="alert-body">
            <p>{{ error }}</p>
          </div>
        </ngb-alert>

        <form class="auth-login-form mt-2" [formGroup]="loginForm" (ngSubmit)="onSubmit()" *ngIf="!showForgotSection">
          <div class="form-group">
            <label class="form-label" for="login-email">Username</label>
            <input type="text" formControlName="email" class="form-control"
              [ngClass]="{ 'is-invalid': (submitted && f.email.errors) || isNotFoundUser}" placeholder="Enter Username"
              aria-describedby="login-email" autofocus="" tabindex="1" (ngModelChange)="onFormLoginChanged()" />
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.required">User is required</div>
              <!-- <div *ngIf="f.email.errors.email">Email must be a valid email address</div> -->
            </div>
            <div *ngIf="isNotFoundUser" class="invalid-feedback">
              <div>User fail</div>
            </div>
          </div>
          <div class="form-group">
            <div class="d-flex justify-content-between">
              <label for="login-password" class="form-label">Password</label>
              <!-- <a routerLink="/pages/authentication/forgot-password-v2"><small>Forgot Password?</small></a> -->
            </div>
            <div class="input-group input-group-merge form-password-toggle">
              <input [type]="passwordTextType ? 'text' : 'password'" formControlName="password"
                class="form-control form-control-merge"
                [ngClass]="{ 'is-invalid error': (submitted && f.password.errors) || isNotMatchPassword }"
                placeholder="············" aria-describedby="login-password" tabindex="2"
                (ngModelChange)="onFormLoginChanged()" />
              <div class="input-group-append">
                <span class="input-group-text cursor-pointer"><i class="feather font-small-4" [ngClass]="{
                      'icon-eye-off': passwordTextType,
                      'icon-eye': !passwordTextType
                    }" (click)="togglePasswordTextType()"></i></span>
              </div>
            </div>
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback"
              [ngClass]="{ 'd-block': submitted && f.password.errors }">
              <div *ngIf="f.password.errors.required">Password is required</div>
            </div>
            <div *ngIf="isNotMatchPassword" class="invalid-feedback" [ngClass]="{ 'd-block': isNotMatchPassword }">
              <div>Password fail</div>
            </div>
          </div>
          <!-- <div class="form-group">
            <div class="custom-control custom-checkbox">
              <input class="custom-control-input" id="remember-me" type="checkbox" tabindex="3" />
              <label class="custom-control-label" for="remember-me"> Remember Me</label>
            </div>
          </div> -->
          <button [disabled]="loading" class="btn btn-primary btn-block mb-1">
            <span *ngIf="loading" class=""></span>Sign in
          </button>
          <!-- <a routerLink="/pages/authentication/forgot-password-v2"><small>Forgot Password?</small></a> -->
          <a routerLink="/forget-password"><small>Forgot Password?</small></a>
          <!-- <a (click)="onClickedForgot()"><small>Forgot Password?</small></a> -->
        </form>

        <ng-container *ngIf="showForgotSection">
          <h2 class="card-title font-weight-bold mb-3 red-text text-center">Forgot Password</h2>
          <form class="auth-login-form mt-2" [formGroup]="forgotForm" (ngSubmit)="onForgotSubmit()">
            <div class="form-group">
              <label class="form-label" for="login-email">E-mail</label>
              <input type="text" formControlName="email" class="form-control"
                [ngClass]="{ 'is-invalid': fgsubmitted && fg.email.errors}" placeholder="admin@demo.com"
                aria-describedby="login-email" autofocus="" tabindex="1" (ngModelChange)="onFormLoginChanged()" />
              <div *ngIf="fgsubmitted && fg.email.errors" class="invalid-feedback">
                <div *ngIf="fg.email.errors.required">Email is required</div>
              </div>
            </div>
            <button [disabled]="loading" class="btn btn-primary btn-block mt-3">
              <span *ngIf="loading" class=""></span>Sign in
            </button>
          </form>
        </ng-container>

        <!-- <p class="text-center mt-2">
          <span>New on our platform?</span
          ><a routerLink="/pages/authentication/register-v2"><span>&nbsp;Create an account</span></a>
        </p>
        <div class="divider my-2">
          <div class="divider-text">or</div>
        </div>
        <div class="auth-footer-btn d-flex justify-content-center">
          <a class="btn btn-facebook" href="javascript:void(0)"><i data-feather="facebook"></i></a
          ><a class="btn btn-twitter white" href="javascript:void(0)"><i data-feather="twitter"></i></a
          ><a class="btn btn-google" href="javascript:void(0)"><i data-feather="mail"></i></a
          ><a class="btn btn-github" href="javascript:void(0)"><i data-feather="github"></i></a>
        </div> -->
      </div>
    </div>
    <!-- /Login-->
  </div>
</div>

<!-- Test Dialog -->
<!-- <div class="mt-2">
  Test Dialog
  <button class="btn btn-outline-primary mb-2 me-2" (click)="openDialog(content1)">
    Dialog
  </button>
</div>

<ng-template #content1 let-modal>
	<div class="modal-header">
		<h4 class="modal-title">Account Task Management System</h4>
	</div>
	<div class="modal-body d-flex justify-content-center">
    <div class="row">
      <div class="col-12"><label>กรุณาระบุเหตผลการคืนงาน</label></div>
      <div class="col"><textarea type="text" class="form-control" [(ngModel)]="testInput"></textarea></div>
    </div>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-danger" (click)="modal.dismiss('Cancel')">ยกเลิก</button>
		<button type="button" class="btn btn-primary" (click)="modal.close(content2)">ยืนยัน</button>
	</div>
</ng-template>

<ng-template #content2 let-modal>
	<div class="modal-header">
		<h4 class="modal-title">Account Task Management System</h4>
	</div>
	<div class="modal-body d-flex justify-content-center">
    <div class="row">
      <div class="col-3">Job No.</div>
      <div class="col-9">: XXXXXX</div>
      <div class="col-3">สาเหตุ</div>
      <div class="col-9">: {{testInput}}</div>
    </div>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-danger" (click)="modal.dismiss('Cancel')">ยกเลิก</button>
		<button type="button" class="btn btn-primary" (click)="modal.close('Confirm')">ยืนยัน</button>
	</div>
</ng-template> -->