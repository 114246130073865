import { Role } from './role';

export class User {
  id: string;
  firstName?: string;
  lastName?: string;
  firstNameTh?: string;
  lastNameTh?: string;
  position?: string;

  departmentId?: number;
  departmentName_EN: string;
  departmentName_TH: string;

  teamId?: number;
  teamName_EN?: string;
  teamName_TH?: string;
  positionId?: number;
  positionName_EN?: string;
  positionName_TH?: string;
  managerId?: number;
  manager?: string;
  managerEn?: string;
  superVisorId?: number;
  managerReferralCode?: number;
  referralCode?: number;
  superVisor?: string;
  superVisorEn?: string;
  username?: string;
  email: string;
  password: string;
  fullName?: string;
  avatar?: string;
  role?: Role;
  token?: string;
  roleSetId?: string;
  roleSetName?: string;
  phone?: string;
  address?: string;
  userCode?: string;
  isActive?: boolean = true;
  mediaFiles?: any
  isSuperUserW?: boolean = false
}
