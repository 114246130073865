import { CustomerService } from 'service/customer.service';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  QueryList,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  BsDatepickerConfig,
  BsDatepickerDirective,
  BsLocaleService,
} from 'ngx-bootstrap/datepicker';
import { DocumentService } from 'service/document.service';
import { GlobalFuncService } from 'service/global-func.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ApiService } from 'service/api.service';
import {
  customerPaymentTypeList,
  paymentTypeList,
} from 'app/main/enum/paymentType';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AuthenticationService } from 'app/auth/service';
import { Role } from 'app/auth/models';
import { OverdueService } from 'service/overdue.service';
import Swal from 'sweetalert2';
import { JobStatus } from 'app/auth/models/jobStatus';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { bankIdList } from 'app/main/enum/bankId';
import { TransferWorkModalComponent } from '../transfer-work-modal/transfer-work-modal.component';
import { PeriodSelectModalComponent } from '../../task-document/period-select-modal/period-select-modal.component';
import { ModalComponent } from '../../task-document/modal/modal.component';
import { isNullOrUndefined } from '@swimlane/ngx-datatable';
import { FilesApiService } from 'service/files-api.service';
import { TimepickerState } from 'ngx-bootstrap/timepicker/reducer/timepicker.reducer';
import { take } from 'rxjs/operators';

var FileSaver = require('file-saver');

@Component({
  selector: 'app-my-task-search-list',
  templateUrl: './my-task-search-list.component.html',
  styleUrls: ['./my-task-search-list.component.scss'],
})
export class MyTaskSearchListComponent implements OnInit, AfterViewInit {
  @ViewChild('stickyNonAccountantHeader') stickyNonAccountantHeader: ElementRef;
  @ViewChild('NotStickyNonAccountantHeader')
  NotStickyNonAccountantHeader: ElementRef;
  @ViewChild('NonAccountantDataTable') NonAccountantDataTable: ElementRef;
  @ViewChild('NonAccountantTableWrapper') NonAccountantTableWrapper: ElementRef;

  @ViewChild('stickyAccountantHeader') stickyAccountantHeader: ElementRef;
  @ViewChild('NotStickyAccountantHeader') NotStickyAccountantHeader: ElementRef;
  @ViewChild('AccountantDataTable') AccountantDataTable: ElementRef;
  @ViewChild('AccountantTableWrapper') AccountantTableWrapper: ElementRef;

  selectedValue: any;
  selectedStatus: any = 1;
  selectPeriod: any;
  documentPeriod: any[] = [];
  paymentType: any[] = [
    { id: 1, name: 'ชำระด้วยเงินสด' },
    { id: 2, name: 'โอนผ่านธนาคาร' },
  ];
  bankIdList: any[] = bankIdList;

  tableListData: any[] = [];

  isSelectAll: boolean = false;
  rowData: any;
  rowData2: any;

  userRole: string;
  loading: boolean = false;
  myTeamList = [];
  jobStatusEnum = JobStatus;

  statusList = [];

  page: number = 1;
  pageLength: number = 10;
  totalPages: number = 10;

  userRoleEnum = Role;
  viewByInvoice: boolean = false;
  rejectJobReason: string;
  customerId: string;
  month: number = 0;
  year: string;
  documentTimingId: string;

  @BlockUI() blockUI: NgBlockUI;

  dateNow: Date = new Date();
  timeNow: Date = new Date();

  selectedEmployee: any;
  employeeList: any[];
  teamList: any[];

  selectItemToReportList: string[] = [];
  isWantFullReport: boolean = false;

  public dateOptions: Partial<BsDatepickerConfig> = {
    adaptivePosition: true,
    isAnimated: true,
    containerClass: 'theme-dark-blue',
    dateInputFormat: 'D MMM YYYY',
    showWeekNumbers: false,
    displayMonths: 1,
    // withTimepicker: true,
  };
  // timeOptions: Partial<BsDatepickerConfig> = {
  //   withTimepicker: true,
  //   rangeInputFormat: 'h:mm:ss',
  //   dateInputFormat: 'h:mm:ss',
  //   datesEnabled: true
  // };

  timeOptions: FlatpickrOptions = {
    enableTime: true,
    time_24hr: true,
    timeFormat: 'H:i',
    noCalendar: true,
    altInputClass: 'form-control bg-white flat-picker flatpickr-input',
    altInput: true,
    altFormat: 'H:i',
    defaultDate: this.dateNow,
  };

  searchByMode: number = 0;
  searchByPaymentType: number = null;
  paymentTypeList: any[] = paymentTypeList;

  isShowStickyHeader: boolean = false;
  isSetWidthToStickyHeader: boolean = false;

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private customerService: CustomerService,
    private documentService: DocumentService,
    private globalFunction: GlobalFuncService,
    private localeService: BsLocaleService,
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private overdueService: OverdueService,
    private fileService: FilesApiService,
    private cdr: ChangeDetectorRef
  ) {
    const token = JSON.parse(localStorage.getItem('token'));
    this.customerService.SetHttpHeaders(token);
    this.documentService.SetHttpHeaders(token);
    this.apiService.SetHttpHeaders(token);
    this.localeService.use('th-be');
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    if (this.tableListData.length >= 1) {
      this.checkElementPosition();
    }
  }

  checkElementPosition() {
    let targetElementTop: any;
    if (this.userRole == this.userRoleEnum.Accountant) {
      targetElementTop =
        this.NotStickyAccountantHeader.nativeElement.getBoundingClientRect()
          .top;
    } else {
      targetElementTop =
        this.NotStickyNonAccountantHeader.nativeElement.getBoundingClientRect()
          .top;
    }

    const windowHeight = window.innerHeight;

    // Calculate if the target element is about to leave the viewable window area
    if (targetElementTop < 100 || targetElementTop > windowHeight) {
      this.isShowStickyHeader = true;

      if (
        this.isShowStickyHeader &&
        targetElementTop &&
        !this.isSetWidthToStickyHeader
      ) {
        this.syncColumnWidthToStickyHeader();
      }
    } else {
      this.isShowStickyHeader = false;
      this.isSetWidthToStickyHeader = false;
    }
  }

  syncScroll(event: any) {
    const scrollContainer = event.target;
    const scrollLeft = scrollContainer.scrollLeft;

    // Set the scroll position of the secondary table to match the main table
    if (this.userRole == this.userRoleEnum.Accountant) {
      this.stickyAccountantHeader.nativeElement.scrollLeft = scrollLeft;
    } else {
      this.stickyNonAccountantHeader.nativeElement.scrollLeft = scrollLeft;
    }
  }

  syncColumnWidthToStickyHeader() {
    if (!this.isSetWidthToStickyHeader) {
      let theadColumns;
      let firstRowTbody;
      if (this.userRole == this.userRoleEnum.Accountant) {
        theadColumns =
          this.stickyAccountantHeader.nativeElement.querySelectorAll(
            'th'
          ) as NodeListOf<HTMLTableCellElement>;
        firstRowTbody =
          this.AccountantDataTable.nativeElement.querySelector('tr');
      } else {
        theadColumns =
          this.stickyNonAccountantHeader.nativeElement.querySelectorAll(
            'th'
          ) as NodeListOf<HTMLTableCellElement>;

        firstRowTbody =
          this.NonAccountantDataTable.nativeElement.querySelector('tr');
      }

      const tbodyColumns = firstRowTbody.querySelectorAll(
        'td'
      ) as NodeListOf<HTMLTableCellElement>;

      for (let i = 0; i < theadColumns.length; i++) {
        const tbodyColumnWidth = tbodyColumns[i].offsetWidth;

        theadColumns[i].style.width = `${tbodyColumnWidth}px`;
        theadColumns[i].style.minWidth = `${tbodyColumnWidth}px`;
      }

      if (this.userRole == this.userRoleEnum.Accountant) {
        this.stickyAccountantHeader.nativeElement.scrollLeft =
          this.AccountantTableWrapper.nativeElement.scrollLeft;
      } else {
        this.stickyNonAccountantHeader.nativeElement.scrollLeft =
          this.NonAccountantTableWrapper.nativeElement.scrollLeft;
      }

      this.isSetWidthToStickyHeader = true;
      this.cdr.detectChanges();
    }
  }

  currentUser: any;
  tableHeader = [];
  ngOnInit(): void {
    // this.loading = true;
    this.currentUser = this.authenticationService.currentUserValue;
    this.userRole = this.currentUser.roleSetName;
    if (
      this.userRole == this.userRoleEnum.Finance ||
      this.userRole == this.userRoleEnum.FinanceSupervisor ||
      this.userRole == this.userRoleEnum.FinanceManager
    ) {
      this.viewByInvoice = true;
    }

    if (
      this.currentUser.positionId == 92 ||
      this.currentUser.positionId == 101
    ) {
      this.GetMyTeamEmployeeList(this.currentUser.teamId);
    } else {
      this.GetEmployeeList();
    }

    this.getMyworkList(this.page, this.pageLength);
    this.getDropdownForSearch();
    this.overdueService.setNumOnMenu();
  }

  ngAfterViewInit(): void {
    // this.syncColumnWidthToStickyHeader()
  }

  GetMyTeamEmployeeList(team: number) {
    this.apiService
      .GetDataById('team/GetEmployeeByTeam', team.toString())
      .subscribe((res) => {
        this.employeeList = res.data.resultData;
        if (
          this.currentUser.positionId == 92 ||
          this.currentUser.positionId == 101
        ) {
          this.employeeList = this.employeeList.map((element) => ({
            ...element,
            teamTh: this.currentUser.teamName_TH,
          }));
        }
        this.removeCurrentUser();
        console.log(this.employeeList);
      });
  }

  GetEmployeeList() {
    console.log('get my team');
    this.apiService.GetAllData('employee/GetMyTeam').subscribe((res) => {
      this.employeeList = res.data.resultData;
      // this.removeCurrentUser();
      console.log(this.employeeList);
    });
  }

  removeCurrentUser() {
    this.employeeList.forEach((element, index) => {
      if (this.employeeList[index]['id'] == this.currentUser['id']) {
        this.employeeList.splice(index, 1);
      }
    });
  }

  getMyworkList(page: number, pageLength: number) {
    this.blockUI.start();
    this.tableListData = [];
    this.tableHeader = [];
    this.isSelectAll = false;
    let paymentId: string = '';

    console.log(this.searchByPaymentType);

    if (typeof this.searchByPaymentType !== 'number') {
      paymentId = '';
    } else {
      paymentId = this.searchByPaymentType.toString();
    }

    // PaymentType: paymentId,

    this.apiService
      .GetAllData('myworks', {
        page: page,
        pageLength: pageLength,
        CustomerName: this.searchByMode == 0 ? this.customerNameSearch : '',
        TaxId: this.searchByMode == 1 ? this.customerNameSearch : '',
        PaymentType: paymentId,
        DocumentIdList: this.listDocumentItemSearch,
        AtPeriod: this.listPeriodSearch,
        MonthNumberFrom: this.startMonth,
        MonthNumberTo: this.endMonth,
        YearNumberFrom: this.startYear,
        YearNumberTo: this.endYear,
        MyWorksStatus: this.selectedStatus,
        MyWorksGenerateByInvoice: this.viewByInvoice,
        CurrentOwnedById: this.selectedEmployee ? this.selectedEmployee.id : '',
      })
      .subscribe(
        (res) => {
          if (!res.data.resultData) {
            this.blockUI.stop();
          }
          // this.tableListData = [];
          // this.tableHeader = [];
          if (res.data.resultData.length >= 1) {
            this.totalPages = Math.ceil(res.data.totalItem / pageLength);

            res.data.resultData.forEach((element, index) => {
              if (element.paymentEvidenceList.length >= 1) {
                element.evidenceIdList = [];
                element.paymentAmount = [];
                element.diffValueList = [];
                element.paymentTypeList = [];
                element.bankList = [];
                element.payDate = [];
                element.payTime = [];
                element.financeRemark = [];
                element.evidenceStatusList = [];

                element.paymentEvidenceList.sort(
                  (a, b) => a.ordinal - b.ordinal
                );

                element.paymentEvidenceList.forEach((el, index) => {
                  element.evidenceIdList.push(el.id);
                  element.paymentAmount.push(el.customerAmountPayment);

                  if (index == 0) {
                    element.diffValueList.push(
                      this.formatNumberToMoney(el.customerAmountDifference)
                    );
                  } else {
                    element.diffValueList.push(
                      this.formatNumberToMoney(
                        this.ParenthesesMoneyFormatToNumber(
                          element.diffValueList[index - 1]
                        ) + el.customerAmountPayment
                      )
                    );
                  }
                  element.paymentTypeList.push(
                    el.customerPaymentTypeName == 'Transfer' ? 2 : 1
                  );
                  element.bankList.push(
                    el.customerPaymentTypeName == 'Transfer'
                      ? 'ธนาคารกสิกรไทย'
                      : '-- ไม่ระบุ --'
                  );
                  let paymentDate = new Date(el.paymentDate);
                  console.log(paymentDate);
                  console.log(paymentDate.getTime());

                  element.payDate.push(paymentDate);
                  element.payTime.push(paymentDate);
                  element.financeRemark.push(el.remark);
                  element.evidenceStatusList.push(el.financeIsSummited);
                });

                this.tableListData.push(element);
              } else {
                element.evidenceIdList = [
                  '00000000-0000-0000-0000-000000000000',
                ];
                element.paymentAmount = [0];
                element.diffValueList = [
                  this.formatNumberToMoney(
                    0 - this.ParenthesesMoneyFormatToNumber(element.totalPrice)
                  ),
                ];
                element.paymentTypeList = [2];
                element.bankList = ['ธนาคารกสิกรไทย'];
                element.payDate = [this.dateNow];
                element.payTime = [this.dateNow];
                element.financeRemark = [''];
                element.evidenceStatusList = [false];
                element.ordinal = 0;
                this.tableListData.push(element);
              }

              this.tableHeader = element.documentValueList;
            });
          }
          this.blockUI.stop();
          console.log(this.tableListData);
        },
        (err) => {
          this.blockUI.stop();
        }
      );
  }

  checkValue(item) {
    console.log(item);
  }

  trackByFn(index, item) {
    return index;
  }

  addFinanceRow(index: number) {
    let arr = this.tableListData[index];
    if (arr.paymentAmount.length < 15) {
      arr.evidenceIdList.push('00000000-0000-0000-0000-000000000000');
      arr.paymentAmount.push(0);
      arr.diffValueList.push(
        this.formatNumberToMoney(
          this.ParenthesesMoneyFormatToNumber(
            arr['diffValueList'][arr.diffValueList.length - 1]
          ) + 0
        )
      );
      arr.paymentTypeList.push(2);
      arr.bankList.push('ธนาคารกสิกรไทย');
      arr.payDate.push(this.dateNow);
      arr.payTime.push(this.dateNow);
      arr.financeRemark.push('');
      arr.evidenceStatusList.push(false);
    }
    console.log(this.tableListData);
  }

  removePaymentRow(i: number, ind: number) {
    let arr = this.tableListData[i];

    arr.evidenceIdList.splice(ind, 1);
    arr.paymentAmount.splice(ind, 1);
    arr.diffValueList.splice(ind, 1);
    arr.paymentTypeList.splice(ind, 1);
    arr.bankList.splice(ind, 1);
    arr.payDate.splice(ind, 1);
    arr.payTime.splice(ind, 1);
    arr.financeRemark.splice(ind, 1);
    arr.evidenceStatusList.splice(ind, 1);

    this.calculateDiffValue(i);
    console.log(this.tableListData);
  }

  dropdownCustomerHaveItemRespones: any[] = [];
  dropdownYears: any[] = [];
  dropdownEndYears: any[] = [];
  dropdownStartMonth: any[] = [];
  dropdownEndMonth: any[] = [];
  dropdownEndMonthCP: any[] = [];
  dropdownPeriods: any[] = [];
  listDocumentItemSearch: any[] = [];
  selectedDocumentItemSearch: any[] = [];
  selectedPeriodSearch: any[] = [];
  listPeriodSearch: string = '';
  customerNameSearch: string = '';
  startYear: string = '';
  endYear: string = '';
  startMonth: string = '';
  endMonth: string = '';
  getDropdownForSearch() {
    this.apiService
      .GetAllData('myworks/getdropdownforsearch')
      .subscribe((res) => {
        console.log(res.data.resultData[0]);
        this.dropdownCustomerHaveItemRespones =
          res.data.resultData[0].dropdownCustomerHaveItemRespones;
        this.dropdownCustomerHaveItemRespones.map((item) => {
          item.desc = item.itemName;
          item.value = item.itemId;
        });
        this.dropdownYears = res.data.resultData[0].dropdownYears;
        var setYear = [];
        setYear = this.dropdownYears.map((item) => {
          item.desc = this.toNumber(item.yearNumber) + 543;
          item.value = item.yearNumber;
          return item;
        });
        this.dropdownEndYears = setYear;
        this.dropdownYears = setYear;

        this.dropdownPeriods = res.data.resultData[0].dropdownPeriods;
        this.dropdownPeriods.map((item) => {
          item.desc = item.periodNumber;
          item.value = item.periodNumber;
        });
      });

    this.apiService
      .GetAllData('myworks/getdropdownstatusenum')
      .subscribe((res) => {
        this.statusList = [];
        res.data.resultData[0].forEach((element) => {
          this.statusList.push(element);
        });
      });
  }

  getDropdownForSearchStartMonth() {
    this.apiService
      .GetAllData('myworks/getdropdownforsearchmonth', {
        YearSelected: this.startYear,
      })
      .subscribe((res) => {
        console.log(res.data.resultData[0]);
        this.dropdownStartMonth = res.data.resultData[0].dropdownMonths;
        this.dropdownStartMonth.map((item) => {
          item.desc = this.globalFunction.getMonthName(
            this.toNumber(item.monthNumber)
          );
          item.value = item.monthNumber;
        });

        // Automatically select the first item in dropdownStartMonth
        if (this.dropdownStartMonth.length > 0) {
          this.startMonth = this.dropdownStartMonth[0].value;
          // Optionally, you can call onSelectMonthChanged here if needed
          this.onSelectMonthChanged();
        }
        // if (this.dropdownEndMonth.length > 0) {
        //   this.endMonth = this.dropdownEndMonth[0].value;
        //   // Optionally, you can call onSelectMonthChanged here if needed
        //   this.onSelectMonthChanged();
        // }
        this.getDropdownForSearchEndMonth();
      });
    this.endMonth = '';

    this.endYear = this.startYear;
    this.dropdownEndYears = this.dropdownYears.filter((e) => {
      return this.toNumber(e.value) >= this.toNumber(this.startYear);
    });
  }
  getDropdownForSearchEndMonth() {
    if (this.startYear > this.endYear) {
      this.endYear = '';
      return;
    }

    this.apiService
      .GetAllData('myworks/getdropdownforsearchmonth', {
        YearSelected: this.endYear,
      })
      .subscribe((res) => {
        console.log(res.data.resultData[0]);
        this.dropdownEndMonth = res.data.resultData[0].dropdownMonths;
        this.dropdownEndMonthCP = res.data.resultData[0].dropdownMonths;
        if (this.endYear == this.startYear) {
          this.dropdownEndMonth = this.dropdownEndMonthCP
            .filter(
              (e) =>
                this.toNumber(e.monthNumber) >= this.toNumber(this.startMonth)
            )
            .map((item) => {
              item.desc = this.globalFunction.getMonthName(
                this.toNumber(item.monthNumber)
              );
              item.value = item.monthNumber;
              return item;
            });
          this.endMonth = this.dropdownEndMonth[0].value;
          console.log(this.dropdownEndMonth);

          // Automatically select the first item in dropdownEndMonth
        } else {
          this.dropdownEndMonth.map((item) => {
            item.desc = this.globalFunction.getMonthName(
              this.toNumber(item.monthNumber)
            );
            item.value = item.monthNumber;
          });
        }
        if (this.startYear != this.endYear) {
          this.endMonth = this.dropdownEndMonth[0].value;
        }
      });
    this.dropdownEndYears = this.dropdownYears.filter((e) => {
      return this.toNumber(e.value) >= this.toNumber(this.startYear);
    });
  }
  onSelecStartMonth() {
    this.endMonth = '';
    if (this.endYear == this.startYear) {
      this.dropdownEndMonth = this.dropdownEndMonthCP
        .filter(
          (e) => this.toNumber(e.monthNumber) >= this.toNumber(this.startMonth)
        )
        .map((item) => {
          item.desc = this.globalFunction.getMonthName(
            this.toNumber(item.monthNumber)
          );

          item.value = item.monthNumber;
          return item; // Return the modified item
        });
      console.log(this.dropdownEndMonth);
    }
    // this.getdropdownforsearchmonth(null, 'end')
  }
  onSelectMonthChanged() {
    if (this.endYear == this.startYear) {
      this.dropdownEndMonth = this.dropdownEndMonthCP
        .filter(
          (e) => this.toNumber(e.monthNumber) >= this.toNumber(this.startMonth)
        )
        .map((item) => {
          item.desc = this.globalFunction.getMonthName(
            this.toNumber(item.monthNumber)
          );

          item.value = item.monthNumber;
          return item; // Return the modified item
        });
      console.log(this.dropdownEndMonth);
    }
    // this.getdropdownforsearchmonth(null, 'end')
  }

  onSelectPeriodChanged(event: any) {
    this.listPeriodSearch = '';
    event.forEach((e, i) => {
      if (event.length > 1) this.listPeriodSearch += (i > 0 ? '+' : '') + e;
      else this.listPeriodSearch = e;
    });
    this.listPeriodSearch = this.listPeriodSearch.toString();
  }

  onSelectDocItemChanged(event: any) {
    this.listDocumentItemSearch = event;
  }

  onSearchClicked() {
    this.page = 1;
    this.pageLength = 10;
    this.getMyworkList(this.page, this.pageLength);
  }

  onReset() {
    this.page = 1;
    this.pageLength = 10;
    this.customerNameSearch = '';
    this.searchByMode = 0;
    this.searchByPaymentType = null;
    this.listDocumentItemSearch = [];
    this.selectedDocumentItemSearch = [];
    this.selectedPeriodSearch = [];
    this.listPeriodSearch = '';
    this.startYear = '';
    this.endYear = '';
    this.startMonth = '';
    this.endMonth = '';
    this.selectedStatus = 1;
    this.selectedEmployee = null;
    this.selectedPeriodSearch = [];
    this.selectItemToReportList = [];
    this.isSelectAll = false;
    this.isSelectAll = false;
    this.getMyworkList(this.page, this.pageLength);
  }

  onSelectChanged() {}

  onDownloadFile(param: string, fileName: string) {
    let modeName = this.viewByInvoice ? 'Invoice' : 'Period';
    let paymentId: string = '';
    if (typeof this.searchByPaymentType !== 'number') {
      paymentId = '';
    } else {
      paymentId = this.searchByPaymentType.toString();
    }

    // PaymentType: paymentId,

    if (
      this.userRole == this.userRoleEnum.Accountant ||
      this.userRole == this.userRoleEnum.AccountantManager ||
      this.userRole == this.userRoleEnum.AccountantSupervisor ||
      this.userRole == this.userRoleEnum.SuperAdmin
    ) {
      this.globalFunction.getExcelReport(
        'excel/ExportMyworksAccount',
        `MyworksAccount - ${modeName}`,
        {
          CustomerName: this.searchByMode == 0 ? this.customerNameSearch : '',
          TaxId: this.searchByMode == 1 ? this.customerNameSearch : '',
          PaymentType: paymentId,
          DocumentIdList: this.listDocumentItemSearch,
          AtPeriod: this.listPeriodSearch,
          MonthNumberFrom: this.startMonth,
          MonthNumberTo: this.endMonth,
          YearNumberFrom: this.startYear,
          YearNumberTo: this.endYear,
          MyWorksStatus: this.selectedStatus,
          MyWorksGenerateByInvoice: this.viewByInvoice,
          Page: this.isWantFullReport ? '' : this.page,
          PageLength: this.isWantFullReport ? '' : this.pageLength,
          SelectList: this.isWantFullReport ? [] : this.selectItemToReportList,
          CurrentOwnedById: this.selectedEmployee
            ? this.selectedEmployee.id
            : '',
        }
      );
    } else if (
      this.userRole == this.userRoleEnum.Finance ||
      this.userRole == this.userRoleEnum.FinanceManager ||
      this.userRole == this.userRoleEnum.FinanceSupervisor
    ) {
      this.globalFunction.getExcelReport(
        'excel/ExportMyworksFinance',
        `MyworksFinance - ${modeName}`,
        {
          CustomerName: this.searchByMode == 0 ? this.customerNameSearch : '',
          TaxId: this.searchByMode == 1 ? this.customerNameSearch : '',
          PaymentType: paymentId,
          DocumentIdList: this.listDocumentItemSearch,
          AtPeriod: this.listPeriodSearch,
          MonthNumberFrom: this.startMonth,
          MonthNumberTo: this.endMonth,
          YearNumberFrom: this.startYear,
          YearNumberTo: this.endYear,
          MyWorksStatus: this.selectedStatus,
          MyWorksGenerateByInvoice: this.viewByInvoice,
          Page: this.isWantFullReport ? '' : this.page,
          PageLength: this.isWantFullReport ? '' : this.pageLength,
          SelectList: this.isWantFullReport ? [] : this.selectItemToReportList,
          CurrentOwnedById: this.selectedEmployee
            ? this.selectedEmployee.id
            : '',
        }
      );
    }
  }

  onPageChange(event: any) {
    this.page = event;
    this.getMyworkList(this.page, this.pageLength);
  }

  onSizePageChange(event: any) {
    this.pageLength = event;
    this.page = 1;
    this.getMyworkList(this.page, this.pageLength);
  }

  onViewModeChange() {
    // this.page = 1;
    // this.selectedPeriodSearch = [];
    // this.selectItemToReportList = [];
    // this.isSelectAll = false;
    // this.allSelected = false;
    // this.listPeriodSearch = '';
    // this.getMyworkList(this.page, this.pageLength);

    this.onReset();
  }

  iconClicked() {}

  onMyTaskClicked(id: number, month: number, year: string) {
    let yearInt = parseInt(year);

    this.router.navigate(['/atms/my-task/view', id, month, yearInt]);
  }

  onMyTaskOnePeriodClicked(documentTimingId: string) {
    this.router.navigate([
      '/atms/task-document/view-document',
      documentTimingId,
    ]);
  }

  onInvoiceClicked(id: string) {
    this.router.navigate(['/atms/task-document/view-invoice', id]);
  }

  onFinanceCreateSlipClick(id: string) {
    const url = ['/atms/task-document/create-slip', id];
    window.open(this.router.createUrlTree(url).toString(), '_blank');
  }

  onViewByPeriodInvoiceClicked(id: string) {
    this.router.navigate(['/atms/task-document/list-invoice', id]);
  }

  showModalData(name: string, period: string, month: string) {}

  transferIconClicked(item: any, index: number) {
    let modalRef = this.modalService.open(TransferWorkModalComponent, {
      centered: true,
    });
    modalRef.componentInstance.data = item;
    modalRef.componentInstance.month = this.tableListData[index]['monthNumber'];
    modalRef.componentInstance.year = this.tableListData[index]['yearNumber'];
    modalRef.componentInstance.currentUser = this.currentUser;
    modalRef.componentInstance.isPermanentMode = false;
    modalRef.componentInstance.viewByInvoice = this.viewByInvoice;
    modalRef.componentInstance.customerId =
      this.tableListData[index]['customerId'];
    modalRef.componentInstance.refreshFunc.subscribe((res) => {
      this.getMyworkList(this.page, this.pageLength);
    });
  }

  tempTransferIconClicked(item: any) {
    let modalRef = this.modalService.open(TransferWorkModalComponent, {
      centered: true,
    });
    modalRef.componentInstance.data = item;
    modalRef.componentInstance.currentUser = this.currentUser;
    modalRef.componentInstance.isPermanentMode = true;
    modalRef.componentInstance.viewByInvoice = this.viewByInvoice;
    modalRef.componentInstance.refreshFunc.subscribe((res) => {
      this.getMyworkList(this.page, this.pageLength);
    });
  }

  transferNextStep(dialog: any) {
    this.modalService.open(dialog, { centered: true }).result.then(
      (result) => {},
      (reason) => {}
    );
  }

  confirmJob(dialog: any, rowData: any) {
    this.rowData = rowData;
    console.log('data', rowData);
    this.modalService.open(dialog, { centered: true }).result.then(
      (result) => {},
      (reason) => {}
    );
  }

  rejectJob(dialog: any, rowData: any) {
    this.rowData = rowData;
    console.log('data', rowData);
    this.modalService.open(dialog, { centered: true }).result.then(
      (result) => {
        console.log('close with', result); //close
        this.rejectJob02(result);
      },
      (reason) => {
        console.log('dismiss with', reason); //dismiss
      }
    );
  }

  rejectJob02(dialog: any) {
    this.modalService.open(dialog, { centered: true }).result.then(
      (result) => {
        console.log('close with', result); //close
      },
      (reason) => {
        console.log('dismiss with', reason); //dismiss
      }
    );
  }

  ConfirmDocument(rowdata) {
    let departmentPath = '';
    if (
      this.userRole == this.userRoleEnum.Accountant ||
      this.userRole == this.userRoleEnum.AccountantManager ||
      this.userRole == this.userRoleEnum.AccountantSupervisor
    ) {
      departmentPath = 'Accountant';
    } else if (
      this.userRole == this.userRoleEnum.Finance ||
      this.userRole == this.userRoleEnum.FinanceManager ||
      this.userRole == this.userRoleEnum.FinanceSupervisor
    ) {
      departmentPath = 'Finance';
    }

    let path = this.viewByInvoice
      ? `${departmentPath}/Approve/Invoice/${rowdata.invoiceId}`
      : `${departmentPath}/Approve/YearMonth/`;

    let formData: any = {};

    if (!this.viewByInvoice) {
      formData.year = parseInt(rowdata.yearNumber);
      formData.month = rowdata.monthNumber;
    }

    this.blockUI.start();
    this.apiService.Post(path, formData).subscribe(
      (res) => {
        this.getMyworkList(this.page, this.pageLength);
        this.blockUI.stop();
        Swal.fire('Complete', '', 'success');
      },
      (err) => {
        this.blockUI.stop();
        Swal.fire('Error', '', 'error');
      }
    );
  }

  RejectDocument(rowdata) {
    let departmentPath = '';
    if (
      this.userRole == this.userRoleEnum.Accountant ||
      this.userRole == this.userRoleEnum.AccountantManager ||
      this.userRole == this.userRoleEnum.AccountantSupervisor
    ) {
      departmentPath = 'Accountant';
    } else if (
      this.userRole == this.userRoleEnum.Finance ||
      this.userRole == this.userRoleEnum.FinanceManager ||
      this.userRole == this.userRoleEnum.FinanceSupervisor
    ) {
      departmentPath = 'Finance';
    }

    let path = this.viewByInvoice
      ? `${departmentPath}/Reject/Invoice/${rowdata.invoiceId}`
      : `${departmentPath}/Reject/YearMonth/`;

    let formData: any = {};
    formData.atmsRejectRemarks = this.rejectJobReason;

    if (!this.viewByInvoice) {
      formData.year = parseInt(rowdata.yearNumber);
      formData.month = rowdata.monthNumber;
    }

    this.blockUI.start();
    this.apiService.Post(path, formData).subscribe(
      (res) => {
        this.getMyworkList(this.page, this.pageLength);
        this.blockUI.stop();
        Swal.fire('Complete', '', 'success');
      },
      (err) => {
        this.blockUI.stop();
        Swal.fire('Error', '', 'error');
      }
    );
  }

  openPeriodModal() {
    const modalRef = this.modalService.open(PeriodSelectModalComponent, {
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.confirmUrl = '';
    modalRef.componentInstance.customerId = this.customerId;
    modalRef.componentInstance.month = this.month;
    modalRef.componentInstance.year = this.year;
    modalRef.componentInstance.submitFunc.subscribe((res) => {
      this.router.navigate([
        `atms/task-document/list-invoice/${this.documentTimingId}`,
      ]);
    });
  }

  getMonthName(month) {
    return this.globalFunction.getMonthName(month);
  }

  toNumber(string) {
    return Number(string);
  }

  formatNumberToMoney(value: any): string {
    let strValue: number = parseFloat(value);
    return this.globalFunction.FormatMinusOrNot(strValue)
      ? this.globalFunction.FormatMinusOrNot(strValue)
      : '0.00';
  }

  ParenthesesMoneyFormatToNumber(value: string): number {
    return this.globalFunction.ParenthesesMoneyFormatToNumber(value)
      ? this.globalFunction.ParenthesesMoneyFormatToNumber(value)
      : 0;
  }

  calculateDiffValue(index: number) {
    let arr = this.tableListData[index];
    let price = this.ParenthesesMoneyFormatToNumber(arr['totalPrice']);

    arr['diffValueList'][0] = this.globalFunction.FormatMinusOrNot(
      this.ParenthesesMoneyFormatToNumber(arr['paymentAmount'][0]) - price
    );

    for (let i = 1; i < arr['paymentAmount'].length; i++) {
      arr['diffValueList'][i] = this.formatNumberToMoney(
        this.globalFunction.ParenthesesMoneyFormatToNumber(
          arr['diffValueList'][i - 1]
        ) +
          this.globalFunction.ParenthesesMoneyFormatToNumber(
            arr['paymentAmount'][i]
          )
      );
    }
  }

  submitFinance(index: number) {
    if (!this.checkFinanceValidate(index)) {
      Swal.fire('Error', 'กรุณากรอกข้อมูลให้ถูกต้อง', 'error');
      return;
    }

    // console.log(this.prepareFinanceData(index))
    // return

    const modalRef = this.modalService.open(ModalComponent, { centered: true });
    modalRef.componentInstance.title = 'ยืนยันการบันทึกข้อมูล';
    modalRef.componentInstance.detail =
      'คุณแน่ใจที่จะบันทึกข้อมูลยอดชำระนี้หรือไม่';
    modalRef.componentInstance.callBackFunc.subscribe((res) => {
      this.blockUI.start();
      console.log(this.tableListData[index]);
      let data = this.tableListData[index];
      let id = this.tableListData[index]['invoiceId'];
      let fdata = this.prepareFinanceData(index);

      this.apiService
        .UpdateDataById('Finance/Invoice', id, {
          paymentEvidenceList: fdata,
        })
        .subscribe(
          (res) => {
            if (
              fdata[fdata.length - 1].diffVal == 0 &&
              data.myWorksStatusId != 4096
            ) {
              this.apiService
                .Post(
                  `Finance/Approve/Invoice/${res.data.resultData[0].id}/FromBody`,
                  {
                    remarks: '',
                  }
                )
                .subscribe(
                  (r) => {
                    this.getMyworkList(this.page, this.pageLength);
                    this.getDropdownForSearch();
                    this.overdueService.setNumOnMenu();
                    this.blockUI.stop();
                    Swal.fire('สำเร็จ', 'บันทึกข้อมูลสำเร็จ', 'success');
                  },
                  (e) => {
                    this.blockUI.stop();
                    Swal.fire('ผิดพลาด', 'ไม่สามารถอนุมัติเอกสารได้', 'error');
                  }
                );
            } else {
              this.getMyworkList(this.page, this.pageLength);
              this.getDropdownForSearch();
              this.overdueService.setNumOnMenu();
              this.blockUI.stop();
              Swal.fire('สำเร็จ', 'บันทึกข้อมูลสำเร็จ', 'success');
            }
          },
          (err) => {
            this.blockUI.stop();
            Swal.fire('ผิดพลาด', 'กรุณาตรวจสอบข้อมูล', 'error');
          }
        );
    });
  }

  confirmFinance(index: number, data: any) {
    let invoiceId: string = data.invoiceId;
    const modalRef = this.modalService.open(ModalComponent, { centered: true });
    modalRef.componentInstance.title = 'ยืนยันการบันทึกข้อมูล';
    modalRef.componentInstance.detail =
      'คุณแน่ใจที่จะบันทึกข้อมูลยอดชำระนี้โดยที่ยอดชำระไม่ตรงหรือไม่';
    modalRef.componentInstance.callBackFunc.subscribe((res) => {
      this.blockUI.start();
      this.apiService
        .Post(`Finance/Approve/Invoice/${invoiceId}/FromBody`, {
          remarks: '',
        })
        .subscribe(
          (r) => {
            this.getMyworkList(this.page, this.pageLength);
            this.getDropdownForSearch();
            this.overdueService.setNumOnMenu();
            this.blockUI.stop();
            Swal.fire('สำเร็จ', 'บันทึกข้อมูลสำเร็จ', 'success');
          },
          (e) => {
            this.blockUI.stop();
            Swal.fire('ผิดพลาด', 'ไม่สามารถอนุมัติเอกสารได้', 'error');
          }
        );
    });
  }

  onSelectPaymentTypeChanged(i, ind) {
    let data = this.tableListData[i];

    if (data.paymentTypeList[ind] == 1) {
      data.bankList[ind] = '-- ไม่ระบุ --';
    } else {
      data.bankList[ind] = 'ธนาคารกสิกรไทย';
    }
  }

  checkFinanceValidate(index: number): boolean {
    const data = this.tableListData[index];

    if (this.ParenthesesMoneyFormatToNumber(data.totalPrice) != 0) {
      for (let i = 0; i < data['paymentAmount'].length; i++) {
        const price = data['paymentAmount'][i];

        if (price == 0) {
          let itemSelectInput = document.getElementById(
            'payment-amount-input-' + index + '-' + i
          ) as HTMLElement;
          let itemSelectText = document.getElementById(
            'payment-amount-text-' + index + '-' + i
          ) as HTMLElement;

          itemSelectInput.classList.add('is-invalid');
          itemSelectText.classList.add('is-invalid');
          return false;
        }
      }
    }

    return true;
  }

  addRequiredErrorAlert(tableDataIndex: number) {
    const item = this.tableListData[tableDataIndex];
    if (
      item['paymentAmount'][0].toString().length < 2 &&
      item['paymentAmount'].length == 1
    ) {
      let itemSelectInput = document.getElementById(
        'payment-amount-' + tableDataIndex + '-0'
      ) as HTMLElement;
      itemSelectInput.classList.add('is-invalid');
    } else if (item['paymentAmount'].length == 2) {
      item['paymentAmount'].forEach((element, index) => {
        if (element.toString().length < 2) {
          let itemSelectInput = document.getElementById(
            'payment-amount-' + tableDataIndex + '-' + index
          ) as HTMLElement;
          itemSelectInput.classList.add('is-invalid');
        }
      });
    }
  }

  prepareFinanceData(index: number) {
    let finalDocumentData = [];
    let amountList = [];
    this.tableListData[index]['paymentAmount'].forEach((element) => {
      amountList.push(this.formatNumberToMoney(element));
    });

    let dateAndTime = [];
    this.tableListData[index]['payDate'].forEach((element, ind) => {
      const date = element;
      const time = this.tableListData[index]['payTime'][ind];

      const dd = new Date(date).getDate();
      const mm = new Date(date).getMonth() + 1;
      const yy = new Date(date).getFullYear();

      const hh = new Date(time).getHours();
      const min = new Date(time).getMinutes();

      const fullDate = new Date(yy, mm - 1, dd, hh, min);
      dateAndTime.push(fullDate);
    });

    for (
      let i = 0;
      i < this.tableListData[index]['paymentTypeList'].length;
      i++
    ) {
      let data = {
        id: this.tableListData[index]['evidenceIdList'][i],
        diffVal: this.ParenthesesMoneyFormatToNumber(
          this.tableListData[index]['diffValueList'][i]
        ),
        atmsCustomerAmountPayment: this.ParenthesesMoneyFormatToNumber(
          amountList[i]
        ),
        atmsCustomerPaymentType:
          this.tableListData[index]['paymentTypeList'][i],
        atmsBankAccountName: this.tableListData[index]['bankList'][i],
        atmsPaymentDate: dateAndTime[i],
        atmsRemarks: this.tableListData[index]['financeRemark'][i],
        ordinal: i,
      };

      finalDocumentData.push(data);
    }

    console.log(finalDocumentData);
    return finalDocumentData;
  }

  removeValidateClass(tableDataIndex: number, financeIndex: number) {
    const item = this.tableListData[tableDataIndex];

    let itemSelectInput = document.getElementById(
      'payment-amount-' + tableDataIndex + '-' + financeIndex
    ) as HTMLElement;
    itemSelectInput.classList.remove('is-invalid');
  }

  showNumberInput(id: string, mainIndex: number, index: number) {
    let textElementId: string = `${id}-text-${mainIndex.toString()}-${index.toString()}`;
    let inputElementId: string = `${id}-input-${mainIndex.toString()}-${index.toString()}`;
    let textElement = document.getElementById(textElementId) as HTMLElement;
    let inputElement = document.getElementById(
      inputElementId
    ) as HTMLInputElement;

    textElement.hidden = true;
    inputElement.hidden = false;
    inputElement.focus();
    inputElement.value = inputElement.value.replace(/^0+/, '');
  }

  hideNumberInput(id: string, mainIndex: number, index: number) {
    let textElementId: string = `${id}-text-${mainIndex.toString()}-${index.toString()}`;
    let subTextElementId: string = `${id}-subtext-${mainIndex.toString()}-${index.toString()}`;
    let inputElementId: string = `${id}-input-${mainIndex.toString()}-${index.toString()}`;
    let textElement = document.getElementById(textElementId) as HTMLElement;
    let subTextElement = document.getElementById(
      subTextElementId
    ) as HTMLElement;
    let inputElement = document.getElementById(
      inputElementId
    ) as HTMLInputElement;

    if (
      !inputElement.value ||
      isNullOrUndefined(inputElement.value) ||
      inputElement.value == '0.00'
    ) {
      console.log('invalid input');
      this.tableListData[mainIndex]['paymentAmount'][index] = 0;
      inputElement.value = '0';
    } else {
      console.log('correct input');
      let value = parseFloat(inputElement.value);

      this.tableListData[mainIndex]['paymentAmount'][index] = value;
      inputElement.value = this.ParenthesesMoneyFormatToNumber(
        inputElement.value
      ).toString();
    }

    textElement.hidden = false;
    inputElement.hidden = true;
  }

  checkDiffIsZero(index: number): boolean {
    let diffValueArr = this.tableListData[index]['diffValueList'];
    return (
      this.ParenthesesMoneyFormatToNumber(
        diffValueArr[diffValueArr.length - 1]
      ) == 0
    );
  }

  financeRejectedCondition(status: number): boolean {
    return (
      status == 131072 ||
      status == 32768 ||
      status == 16384 ||
      status == 8192 ||
      status == 512
    );
  }

  completeJob(invoiceId: string) {
    const modalRef = this.modalService.open(ModalComponent, { centered: true });
    modalRef.componentInstance.title = 'ยืนยันการปิดงาน';
    modalRef.componentInstance.detail = 'คุณแน่ใจที่จะปิดงานนี้หรือไม่';
    modalRef.componentInstance.callBackFunc.subscribe((res) => {
      this.blockUI.start();

      this.apiService
        .Post(`Finance/Complete/Invoice/${invoiceId}`, null)
        .subscribe(
          (res) => {
            this.getMyworkList(this.page, this.pageLength);
            this.blockUI.stop();
            Swal.fire('สำเร็จ', 'บันทึกข้อมูลสำเร็จ', 'success');
          },
          (err) => {
            this.blockUI.stop();
            Swal.fire('ผิดพลาด', 'กรุณาตรวจสอบข้อมูล', 'error');
          }
        );
    });
  }

  completeSelectedJob(invoiceIdList: string[]) {
    const modalRef = this.modalService.open(ModalComponent, { centered: true });
    modalRef.componentInstance.title = 'ยืนยันการปิดงาน';
    modalRef.componentInstance.detail = 'คุณแน่ใจที่จะปิดงานนี้หรือไม่';
    modalRef.componentInstance.callBackFunc.subscribe((res) => {
      this.blockUI.start();

      this.apiService
        .Post(`Finance/Complete/Select/Invoice`, {
          InvoiceIdList: invoiceIdList,
        })
        .subscribe(
          (res) => {
            this.selectItemToReportList = [];
            this.getMyworkList(this.page, this.pageLength);
            this.blockUI.stop();
            Swal.fire('สำเร็จ', 'บันทึกข้อมูลสำเร็จ', 'success');
          },
          (err) => {
            this.blockUI.stop();
            Swal.fire('ผิดพลาด', 'กรุณาตรวจสอบข้อมูล', 'error');
          }
        );
    });
  }

  isNotFinance(): boolean {
    return (
      this.userRole != this.userRoleEnum.Finance &&
      this.userRole != this.userRoleEnum.FinanceSupervisor &&
      this.userRole != this.userRoleEnum.FinanceManager
    );
  }

  selectAll(checked: boolean) {
    // this.selectItemToReportList = checked
    //   ? this.getSelectedIds(this.tableListData)
    //   : [];
    // = !;

    console.log(checked);

    if (checked) {
      this.isSelectAll = true;
      this.tableListData.map((d) => {
        d.isChecked = true;
      });
      this.selectItemToReportList = this.getSelectedIds(this.tableListData);
    } else {
      this.isSelectAll = false;
      this.tableListData.map((d) => {
        d.isChecked = false;
      });
      this.selectItemToReportList = [];
    }
  }

  checkselected() {
    console.log('this.selectItemToReportList', this.selectItemToReportList);
    console.log('this.isSelectAll', this.isSelectAll);
    console.log(
      'this.selectItemToReportList length',
      this.selectItemToReportList.length
    );
  }

  selectItem(checked: boolean, item: any) {
    let value: string = this.viewByInvoice
      ? item.invoiceId
      : item.documentTimingId;

    if (checked) {
      this.selectItemToReportList.push(value);
      if (this.selectItemToReportList.length === this.tableListData.length) {
        this.isSelectAll = true;
      }
    } else {
      this.isSelectAll = false;
      item.isChecked = false;
      this.selectItemToReportList = this.selectItemToReportList.filter(
        (id) => id !== value
      );
    }
  }

  private getSelectedIds(data: any[]): string[] {
    return data.map((item) => {
      if (item.isChecked) {
        return this.viewByInvoice ? item.invoiceId : item.documentTimingId;
      }
    });
  }
}
