import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-complete-modal',
  templateUrl: './complete-modal.component.html',
  styleUrls: ['./complete-modal.component.scss']
})
export class CompleteModalComponent implements OnInit {
 
  @ViewChild('completeModal') completeModal: any;

  constructor(private ngbModal : NgbModal) { }

  ngOnInit(): void {
  }

  openModal() {
    this.ngbModal.open(this.completeModal, {       
      animation: false,
      size: 'lg',
      centered: true,
      backdrop: 'static',
      keyboard: false,
    });
  }

  closeModal() {
    this.ngbModal.dismissAll();
  }
}
