import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  contentHeader: object;
  ngOnInit(): void {
    this.contentHeader = {
      headerTitle: 'หน้าหลัก',
      actionButton: false,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'ผู้ใช้',
            isLink: true,
            link: '/atms/user'
          },
        ]
      }
    }
  }

}
