import { Component, OnInit, ViewChild } from '@angular/core';
import { CancelModalComponent } from 'shared/cancel-modal/cancel-modal.component';
import { CheckboxFormModalComponent } from 'shared/checkbox-form-modal/checkbox-form-modal.component';
import { CompleteModalComponent } from 'shared/complete-modal/complete-modal.component';
import { ConfirmModalComponent } from 'shared/confirm-modal/confirm-modal.component';
import { DocumentExportDateModalComponent } from 'shared/document-export-date-modal/document-export-date-modal.component';
import { DocumentSubmitDateModalComponent } from 'shared/document-submit-date-modal/document-submit-date-modal.component';
import { TextAreaFormModalComponent } from 'shared/text-area-form-modal/text-area-form-modal.component';
import { TransferTaskComponent } from 'shared/transfer-task/transfer-task.component';

@Component({
  selector: 'app-modal-test',
  templateUrl: './modal-test.component.html',
  styleUrls: ['./modal-test.component.scss']
})
export class InvoiceTestComponent implements OnInit {
  @ViewChild("transferTask") transferTask: TransferTaskComponent;
  @ViewChild("confirmModal") confirmModal: ConfirmModalComponent;
  @ViewChild("completeModal") completeModal: CompleteModalComponent;
  @ViewChild("cancelModal") cancelModal: CancelModalComponent;
  @ViewChild("documentSubmitDateModal") documentSubmitDateModal: DocumentSubmitDateModalComponent;
  @ViewChild("documentExportDateModal") documentExportDateModal: DocumentExportDateModalComponent;
  @ViewChild("checkboxFormModal") checkboxFormModal: CheckboxFormModalComponent;
  @ViewChild("textAreaFormModal") textAreaFormModal: TextAreaFormModalComponent;

  constructor() { }

  ngOnInit(): void {
  }

  printInvoice() {
    window.print();
  }

  openModal() {
    this.transferTask.openModal();
  }

  openConfirmModal() {
    this.confirmModal.openModal();
  }

  openCompleteModal() {
    this.completeModal.openModal();
  }

  openCancelModal() {
    this.cancelModal.openModal();
  }

  openDocSubModal() {
    this.documentSubmitDateModal.openModal();
  }

  openDocExModal() {
    this.documentExportDateModal.openModal();
  }

  openCheckboxModal() {
    this.checkboxFormModal.openModal();
  }

  openTextAreaModal() {
    this.textAreaFormModal.openModal();
  }
}
