<div class="modal-header text-center bg-primary">
  <h3 class="modal-title w-100">จัดการเครดิต</h3>
</div>
<div class="modal-body">
  <form [formGroup]="adjustForm">
    <div class="custo-btn mt-1">
      <div class="d-flex">
        <div class="col-12">
          <div class="form-group row">
            <label class="col-sm-3 col-form-label"> เพิ่ม/ลด เครดิต </label>
            <div class="col-sm-9 d-flex align-items-center">
              <div class="custom-control custom-radio mr-2">
                <input
                  type="radio"
                  id="adjustType"
                  name="adjustType"
                  class="custom-control-input"
                  [value]="1"
                  formControlName="adjustType"
                  checked
                />
                <label class="custom-control-label" for="adjustType"> เพิ่ม </label>
              </div>
              <div class="custom-control custom-radio mr-2">
                <input
                  type="radio"
                  [value]="0"
                  name="adjustType"
                  class="custom-control-input"
                  id="adjustType2"
                  formControlName="adjustType"
                />
                <label class="custom-control-label" for="adjustType2"> ลด </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    
      <div class="d-flex">
        <div class="col-6">
          <label for="quantity">จำนวนเครดิต</label>
          <input
            [ngClass]="{}"
            formControlName="quantity"
            id="quantityAdjustInput"
            class="form-control"
            type="text"
            (blur)="formatNumber('quantityAdjustInput')"
          />
    
          <div [ngClass]="{}" class="invalid-feedback">
            ไม่สามารถใส่จำนวนที่ติดลบได้
          </div>
        </div>
    
        <div class="col-6">
          <label class="col-form-label"> รูปแบบเครดิต </label>
            <div class="d-flex align-items-center">
              <div class="custom-control custom-radio mr-2">
                <input
                  type="radio"
                  id="creditType"
                  name="creditType"
                  class="custom-control-input"
                  [value]="0"
                  formControlName="creditType"
                  checked
                  [readonly]="userRole != userRoleEnum.SuperAdmin"
                />
                <label class="custom-control-label" for="creditType"> เครดิต ภพ.30 </label>
              </div>
              <div class="custom-control custom-radio mr-2" *ngIf="userRole == userRoleEnum.SuperAdmin">
                <input
                  type="radio"
                  [value]="1"
                  name="creditType"
                  class="custom-control-input"
                  id="creditType2"
                  formControlName="creditType"
                />
                <label class="custom-control-label" for="creditType2"> เครดิตลูกค้า </label>
              </div>
            </div>
    
    
          
        </div>
      </div>
    
      <div class="col-12 mb-1">
        <label class="mt-2">หมายเหตุ</label>
        <textarea formControlName="remark" class="form-control"></textarea>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer justify-content-between">
  <button
    type="button"
    class="btn btn-secondary m-0"
    (click)="closeModal()"
    rippleEffect
  >
    ยกเลิก
  </button>
  <button
    type="button"
    class="btn btn-primary m-0"
    [disabled]="adjustForm.invalid"
    (click)="openConfirmModal()"
    rippleEffect
  >
    ยืนยัน
  </button>
</div>
