<div id="customer-page" class="pb-3">
    <div class="row py-2 px-3">
        <div class="col-12">
            <div class="card mb-1" style="border-radius: 20px;">
                <div class="card-body">
                    <div class="row text-center" [hidden]="createCheck">
                        <div class="col-md-4 col-12">
                            <button [routerLink]="['/atms/customer/create/business']" routerLinkActive="active"
                                class="d-block shape-button-menu">ข้อมูลกิจการ</button>
                        </div>
                        <div class="col-md-4 col-12">
                            <button [routerLink]="['/atms/customer/create/package']" routerLinkActive="active"
                                class="d-block shape-button-menu" disabled>Package / แบบภาษี</button>
                        </div>
                        <div class="col-md-4 col-12">
                            <button [routerLink]="['/atms/customer/create/credit']" routerLinkActive="active"
                                class="d-block shape-button-menu" disabled>จัดการ Credit</button>
                        </div>
                    </div>
                    <div class="row text-center" [hidden]="viewCheck">
                        <div class="col-md-4 col-12 my-1">
                            <button [routerLink]="['/atms/customer/view/'+customerId+'/business']"
                                routerLinkActive="active" class="d-block shape-button-menu">ข้อมูลกิจการ</button>
                        </div>
                        <div class="col-md-4 col-12 my-1">
                            <button [routerLink]="['/atms/customer/view/'+customerId+'/package']"
                                routerLinkActive="active" class="d-block shape-button-menu">Package / แบบภาษี</button>
                        </div>
                        <div class="col-md-4 col-12 my-1">
                            <button [routerLink]="['/atms/customer/view/'+customerId+'/credit']"
                                routerLinkActive="active" class="d-block shape-button-menu">จัดการ Credit</button>
                        </div>
                    </div>
                    <div class="row text-center" [hidden]="editCheck">
                        <div class="col-md-4 col-12 my-1">
                            <button [routerLink]="['/atms/customer/edit/'+customerId+'/business']"
                                routerLinkActive="active" class="d-block shape-button-menu">ข้อมูลกิจการ</button>
                        </div>
                        <div class="col-md-4 col-12 my-1">
                            <button [routerLink]="['/atms/customer/edit/'+customerId+'/package']"
                                routerLinkActive="active" class="d-block shape-button-menu">Package / แบบภาษี</button>
                        </div>
                        <div class="col-md-4 col-12 my-1">
                            <button [routerLink]="['/atms/customer/edit/'+customerId+'/credit']"
                                routerLinkActive="active" class="d-block shape-button-menu">จัดการ Credit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <router-outlet></router-outlet>
</div>

<!-- <div class="row justify-content-center p-3">
    <div class="col-lg-4 col-6">
        <button class="btn btn-cancel w-100">ยกเลิก</button>
    </div>
    <div class="col-lg-4 col-6">
        <button class="btn btn-main w-100">ยืนยัน</button>
    </div>
</div> -->