import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from 'app/auth/models';
import { AuthenticationService } from 'app/auth/service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FilesApiService } from 'service/files-api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-user-index',
  templateUrl: './user-index.component.html',
  styleUrls: ['./user-index.component.scss'],
})
export class UserIndexComponent implements OnInit {
  constructor(
    private _modalService: NgbModal,
    private _fileApiService: FilesApiService,
    private _authenticationService: AuthenticationService
  ) {
    this._authenticationService.currentUser.subscribe((x) => {
      this.currentUser = x;

      if (this.currentUser.mediaFiles) {
        this.profileIMG = this._fileApiService.getImage(
          this.currentUser.mediaFiles['profileImage'][0].id
        );
      }
      
    });
  }

  currentUser: any;
  userDetail: User = new User();

  profileIMG: any;
  profileIMGName: string;

  previewIMG: any;
  previewIMGName: string;
  previewIMGBlob: Blob;

  imageBase64: string;
  activeModal: any;

  @BlockUI() blockUI: NgBlockUI;
  ngOnInit(): void {
    // this.currentUser = this.authenticationService.currentUserValue;

    this.mapDataUser();
  }

  mapDataUser() {
    this.userDetail = this.currentUser;
    this.userDetail.fullName =
      this.currentUser.firstName + ' ' + this.currentUser.lastName;
    // this.userDetail.firstName = this.currentUser.firstName;
    // this.userDetail.lastName = this.currentUser.lastName;
    // this.userDetail.fullName =
    //   this.currentUser.firstName + ' ' + this.currentUser.lastName;
    // this.userDetail.email = this.currentUser.email;
    // this.userDetail.phone = this.currentUser.phone;
    // this.userDetail.address = this.currentUser.address;
    // this.userDetail.roleSetName = this.currentUser.roleSetName;
    // this.userDetail.userCode = this.currentUser.userCode;
  }

  uploadProfilePicture(event, modal) {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();

      reader.onload = (event: any) => {
        this.previewIMG = event.target.result;
        // this.data.imageBase64 = event.target.result.replace(/^data:(.*,)?/, '');
      };
      reader.readAsDataURL(event.target.files[0]);
      this.previewIMGName = event.target.files[0].name;
      this.previewIMGBlob = event.target.files[0];
      this.openChangePictureModal(modal);
    }
  }

  openChangePictureModal(modal) {
    this.activeModal = this._modalService.open(modal, {
      centered: true,
      backdrop: 'static',
    });
  }

  SaveProfilePicture() {
    var self = this;

    this.activeModal.close();
    this.blockUI.start();

    const formData = new FormData();
    formData.append('file', this.previewIMGBlob);

    this._fileApiService
      .UpdateFile('user', this.currentUser.id, 'profileImage', 0, formData)
      .subscribe(
        (res) => {
          self._authenticationService.getUserDetails().subscribe(
            (x) => {
              this.profileIMG = this.previewIMG
              this.blockUI.stop();
              Swal.fire('Success', '', 'success');
            },
            (err) => {
              this.blockUI.stop();
              Swal.fire('Error', '', 'error');
            }
          );
        },
        (err) => {
          this.blockUI.stop();
          Swal.fire('Error', '', 'error');
        }
      );
  }
}
