<div class="auth-wrapper auth-v2">
  <div class="auth-inner row m-0">
    <!-- Left Text-->
    <div class="d-none d-lg-flex col-lg-8 align-items-center p-5 bg-main">
      <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
        <img
          class="img-fluid"
          src="../../../../../assets/images/pages/login/accountworks-login-img.png"
          alt="Login V2"
          style="max-height: 500px;"
        />
      </div>
    </div>
    <!-- /Left Text-->
    <!-- Forgot password-->
    <div class="d-flex col-lg-4 align-items-center auth-bg px-2 p-lg-5">
      <div class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto">
        <div class="text-center" *ngIf="haveVerifyData && isLoading">
          <app-loading></app-loading>
        </div>
        <div *ngIf="!isLoading && haveVerifyData && isVerifyComplete">
          <h2 class="card-title font-weight-bold mb-1">Verify Email 🔒</h2>
          <div
            class="mx-auto my-5"
            style="
              background-color: green;
              width: 40px;
              height: 40px;
              border-radius: 100%;
              display: grid;
              place-items: center;
              scale: 2;
            "
          >
            <i
              data-feather="check"
              class="text-white"

            ></i>
          </div>
          <p class="card-text mb-2">
            Your email: <span>{{ email }}</span>
            is completely verified. Please login to continue
          </p>

          <p class="text-center mt-2">
            <a routerLink="/login"
              ><i data-feather="chevron-left" class="mb-25"></i>Back to login</a
            >
          </p>
        </div>

        <div *ngIf="!haveVerifyData">
          <h2 class="card-title font-weight-bold mb-1">Verify Email 🔒</h2>
          <p *ngIf="!isSendedVerifyEmail" class="card-text mb-2">
            Enter your email to sent Verify email
          </p>
          <form
            *ngIf="!isSendedVerifyEmail"
            [formGroup]="emailForm"
            (ngSubmit)="sentVerifyEmail()"
          >
            <div class="form-group">
              <label class="form-label" for="login-email">Email</label>
              <input
                type="text"
                formControlName="email"
                class="form-control"
                [ngClass]="{ 'is-invalid': isSubmitted && f.email.errors }"
                placeholder="example123@email.com"
                aria-describedby="login-email"
                autofocus=""
                tabindex="1"
              />
              <div
                *ngIf="isSubmitted && f.email.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.email.errors.required">Email is required</div>
              </div>
            </div>
            <button
              [disabled]="isLoading"
              class="btn btn-primary btn-block"
              tabindex="4"
              rippleEffect
            >
              Sent Verify Email
            </button>
          </form>

          <p *ngIf="isSendedVerifyEmail">
            Please Check your email: <span>{{ f.email.value }}</span> to Verify
            your account
          </p>

          <div *ngIf="isSendedVerifyEmail" class="text-center">
            <a (click)="sentVerifyEmail()" class="btn btn-primary btn-block">
              Resend Verify Email
            </a>
          </div>

          <p class="text-center mt-2">
            <a routerLink="/login"
              ><i data-feather="chevron-left" class="mb-25"></i>Back to login</a
            >
          </p>
        </div>
      </div>
    </div>
    <!-- /Forgot password-->
  </div>
</div>
