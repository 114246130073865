<!-- Modal -->
<ng-template #checkboxFormModal let-modal>
    <div class="modal-header text-center bg-primary">
        <h3 class="modal-title w-100">Account Task Management System</h3>
        <button type="button" class="btn-close float-right" (click)="closeModal()">&times;</button>
    </div>
    <div class="modal-body">
        <div class="my-4">
            <h4 class="big-text blue-text text-center">
                กรุณาเลือกรอบงาน
            </h4>
            <br />
            <div *ngFor="let i of itemList" style="text-align: center;">
                <div>
                    <input class="form-check-input" 
                           type="checkbox" value="" id="{{i.id}}" 
                           style="width: 24px; height: 24px;"
                    >
                    <label style="font-size: 24px; padding-left: 30px;">
                        {{i.name}}
                    </label>
                    <label style="font-size: 24px; padding-left: 50px;">
                        {{i.status}}
                   </label>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer justify-content-between">
        <button type="button" class="btn btn-danger" (click)="closeModal()">ยกเลิก</button>
        <button type="button" class="btn btn-primary">ยืนยัน</button>
    </div>
</ng-template>