import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { CoreConfigService } from '@core/services/config.service';
import { AuthenticationService } from 'app/auth/service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ForgetPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;
  isSubmitted = false;
  isLoading: boolean;
  isMailSended: boolean;

  public coreConfig: any;
  private _unsubscribeAll: Subject<any>;

  constructor(
    private _coreConfigService: CoreConfigService,
    private _formBuilder: FormBuilder,
    private _authService: AuthenticationService,
    private _translateService: TranslateService,
  ) {
    this._unsubscribeAll = new Subject();

    this._coreConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        menu: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        animation: 'fadeIn',
        customizer: false,
        enableLocalStorage: false
      }
    };
  }

  get f() {
    return this.forgotPasswordForm.controls;
  }

  ngOnInit(): void {
    this.forgotPasswordForm = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });

    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  onSubmit() {
    this.SetLoadingState()
    this.isSubmitted = true;

    // stop here if form is invalid
    if (this.forgotPasswordForm.invalid) {
      this.SetLoadedState()
      return;
    }
    this._authService
      .sendEmailforgetPassword(this.f.email.value)
      .subscribe(
        (res) => {
          Swal.fire({
            title: 'อีเมลสำหรับรีเซ็ตรหัสผ่านถูกส่งแล้ว',
            icon: 'success',
          });
          this.isMailSended = true;
          this.SetLoadedState()
        },
        (err) => {

          Swal.fire({
            title: 'ผิดพลาด',
            text: 'เกิดข้อผิดพลาดบางอย่าง',
            icon: 'error',
          });
          this.SetLoadedState()
        }
      );
  }

  SetLoadingState(): void {
    this.isLoading = true;
  }
  SetLoadedState(): void {
    this.isLoading = false;
  }


}
