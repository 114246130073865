<div class="row px-3">
    <div class="col-12">
        <div class="row justify-content-between">
            <div class="col text-left" *ngIf="buttonHide">
                <button
                    *ngIf="disableCheck && (userRole != userRoleEnum.Finance && userRole != userRoleEnum.FinanceManager && userRole != userRoleEnum.FinanceSupervisor) "
                    class="btn btn-success button-width-200" (click)="editRouter()">แก้ไข</button>
                <button *ngIf="!disableCheck" class="btn btn-secondary button-width-200" disabled>แก้ไข</button>
            </div>
            <div class="col text-left" *ngIf="!buttonHide">

            </div>
            <!-- <div class="col text-left" [hidden]="!disableCheck">
                <button class="btn btn-success button-width-200" (click)="editRouter()">แก้ไข</button>
            </div> -->

            <div class="col-3 text-right">
                <!-- <p class="text-header-document">{{customerDetails.customerCode}}</p> -->
                <label for="" class="form-label text-sub-document">รหัสลูกค้า</label>
                <input type="text" class="form-control"
                    [disabled]="disableCheck || userRole != userRoleEnum.SuperAdmin && this.routePath == 'edit'"
                    [(ngModel)]="customerDetails.customerCode" [ngClass]="{'is-invalid': errorInput.customerCode}">
            </div>
        </div>


        <div class="row mt-2">
            <div class="col-lg-3 col-md-4 col-12 mb-1"
                *ngIf="userRole == userRoleEnum.SuperAdmin || userRole == userRoleEnum.AccountantManager">
                <label for="" class="form-label text-sub-document">ทีม</label>
                <ng-select [(ngModel)]="selectedTeam" (change)="onselectTeam($event)" placeholder="" [clearable]="false"
                    [disabled]="disableCheck" [ngClass]="{'is-invalid-option': errorInput.teamId}">
                    <ng-option [value]="0">-- โปรดระบุ --</ng-option>
                    <ng-option *ngFor="let team of empTeamList" [value]="team.id"
                        [selected]="team.id == selectTeam">{{team.nameEn}}</ng-option>
                </ng-select>
                <div class="invalid-feedback-show" *ngIf="errorInput.teamId">
                    {{errorInput.teamId}}
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-12 mb-1"
                *ngIf="userRole == userRoleEnum.SuperAdmin ||userRole == userRoleEnum.AccountantSupervisor || userRole == userRoleEnum.AccountantManager">
                <div>
                    <label for="" class="form-label text-sub-document">ผู้ดูแล</label>
                    <div class="d-flex">
                        <ng-select class="w-100" [(ngModel)]="selectEmployee" (change)="onselectEmployee()"
                            placeholder="" [clearable]="false" [disabled]="disableCheck"
                            [ngClass]="{'is-invalid-option': errorInput.selectEmployee}">
                            <ng-option *ngFor="let busType of employeeType" [value]="busType.id"
                                [selected]="busType.id == customerDetails.employeeOwnedId">{{busType.firstName + ' ' +
                                busType.lastName}}</ng-option>
                        </ng-select>
                        <button class="btn btn-sm btn-primary ml-1" *ngIf="buttonHide && !disableCheck"
                            (click)="resetEmployee()"><i data-feather="refresh-cw"></i></button>
                    </div>
                </div>
                <div class="invalid-feedback-show" *ngIf="errorInput.selectEmployee">
                    {{errorInput.selectEmployee}}
                </div>
            </div>
            <div class="col-12">
                <span class="text-danger" *ngIf="showCheckDialog">***{{checkTransferDialog}}***</span>
            </div>
            <div class="col-12">
                <p class="text-sub-document">
                    ข้อมูลจดทะเบียน
                </p>
            </div>
            <div class="col-12">
                <div class="d-md-flex d-block">
                    <p class="text-sub-document mr-2">
                        เลขทะเบียน 13 หลัก
                    </p>
                    <div class="form-check mr-3">
                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"
                            [(ngModel)]="customerDetails.isThailand" [value]="true" [disabled]="disableCheck">
                        <label class="form-check-label label-space" for="flexRadioDefault1">
                            ไทย
                        </label>
                    </div>
                    <div class="form-check mr-3">
                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"
                            [(ngModel)]="customerDetails.isThailand" [value]="false" [disabled]="disableCheck">
                        <label class="form-check-label label-space" for="flexRadioDefault2">
                            ประเทศอื่น ๆ
                        </label>
                    </div>
                </div>
            </div>
            <div class="col-lg-8 col-12 mb-1">
                <input type="text" name="personalNumber" class="form-control"
                    [ngClass]="{'is-invalid': errorInput.taxId}" [(ngModel)]="customerDetails.taxId" [maxlength]="13"
                    onkeyup="this.value=this.value.replace(/[^\d]/g,'')" [disabled]="disableCheck" required>
                <div class="invalid-feedback-show" *ngIf="errorInput.taxId">
                    {{errorInput.taxId}}
                </div>
            </div>

            <div class="col-4">

            </div>

            <div class="col-lg-3 col-6 mb-1">
                <label for="" class="form-label text-sub-document">ประเภท</label>
                <ng-select [(ngModel)]="selectBusinessType" (change)="onSelectBusinessType()" placeholder=""
                    [clearable]="false" [disabled]="disableCheck"
                    [ngClass]="{'is-invalid-option': errorInput.businessTypeId}">
                    <ng-option *ngFor="let busType of businessType" [value]="busType.id">{{busType.name}}</ng-option>
                </ng-select>
                <div class="invalid-feedback-show" *ngIf="errorInput.businessTypeId">
                    {{errorInput.businessTypeId}}
                </div>
            </div>
            <div class="col-lg-5 col-md-8 col-6 mb-1" *ngIf="selectBusinessType != 16">
                <label for="" class="form-label text-sub-document">ชื่อกิจการ</label>
                <input type="text" class="form-control" [disabled]="disableCheck"
                    [(ngModel)]="customerDetails.businessName" [ngClass]="{'is-invalid': errorInput.businessName}"
                    onkeyup="this.value=this.value.replace(/^\s*$|^\s+(?=\S)/g,'')">
                <div class="invalid-feedback-show" *ngIf="errorInput.businessName">
                    {{errorInput.businessName}}
                </div>
            </div>


            <div class="col-4">

            </div>



            <div class="form-check ml-2 mb-1" *ngIf="selectBusinessType != 16">
                <input class="form-check-input" type="radio" name="typeCompany" id="main"
                    [(ngModel)]="customerDetails.isHeadBranch" [value]="true" [disabled]="disableCheck">
                <label class="form-check-label " for="main">
                    สำนักงานใหญ่
                </label>
            </div>
            <div class="form-check ml-3" *ngIf="selectBusinessType != 16 ">
                <input class="form-check-input" type="radio" name="ฺbranch" id="branch"
                    [(ngModel)]="customerDetails.isHeadBranch" [value]="false" [disabled]="disableCheck">
                <label class="form-check-label label-space" for="branch">
                    สาขา
                </label>
            </div>
            <div class="col-12 row">
                <div class="col-lg-4 col-md-8 col-6 mb-1" *ngIf="selectBusinessType == 16">
                    <label for="" class="form-label text-sub-document">ชื่อ</label>
                    <input type="text" class="form-control" [disabled]="disableCheck"
                        [(ngModel)]="customerDetails.firstName" [ngClass]="{'is-invalid': errorInput.firstName}"
                        onkeyup="this.value=this.value.replace(/^\s*$|^\s+(?=\S)/g,'')">
                    <div class="invalid-feedback-show" *ngIf="errorInput.firstName">
                        {{errorInput.firstName}}
                    </div>
                </div>
                <div class="col-lg-4 col-md-8 col-6 mb-1" *ngIf="selectBusinessType == 16">
                    <label for="" class="form-label text-sub-document">นามสกุล</label>
                    <input type="text" class="form-control" [disabled]="disableCheck"
                        [(ngModel)]="customerDetails.lastName" [ngClass]="{'is-invalid': errorInput.lastName}"
                        onkeyup="this.value=this.value.replace(/^\s*$|^\s+(?=\S)/g,'')">
                    <div class="invalid-feedback-show" *ngIf="errorInput.lastName">
                        {{errorInput.lastName}}
                    </div>
                </div>
            </div>
            <div class="col-12 row ">
                <div class="col-lg-2 col-md-8 col-3 mb-1" *ngIf="selectBusinessType == 16">
                    <label for="" class="form-label text-sub-document">เพศ</label>
                    <ng-select [items]="genderOptions" bindLabel="label" bindValue="value"
                        [(ngModel)]="customerDetails.genderId" [disabled]="disableCheck" [clearable]="false">
                    </ng-select>

                </div>
            </div>
            <div class=" col-12 mb-1" *ngIf="customerDetails.isHeadBranch === false && selectBusinessType !=16 ">

                <div class="row">
                    <div class="col-lg-2 col-md-8 col-12 mb-1">
                        <label for="" class="form-label text-sub-document">เลขที่สาขา</label>
                        <input type="number" name="branchNumber" maxlength="5" class="form-control"
                            [ngClass]="{'is-invalid': errorInput.branchNumber}"
                            [(ngModel)]="customerDetails.branchNumber" (blur)="updateBranchNumberValue()"
                            onKeyPress="if(this.value.length==5) return false;"
                            onkeyup="this.value=this.value.replace(/[^\d]/g,'')" [disabled]="disableCheck" required>
                        <div class="invalid-feedback-show" *ngIf="errorInput.branchNumber">
                            {{errorInput.branchNumber}}
                        </div>

                    </div>
                    <div class="col-lg-4 col-md-1 col-6 ">
                        <label for="" class="form-label text-sub-document">ชื่อสาขา</label>
                        <input type="text" class="form-control" [disabled]="disableCheck"
                            [(ngModel)]="customerDetails.branchName" [ngClass]="{'is-invalid': errorInput.branchName}"
                            onkeyup="this.value=this.value.replace(/^\s*$|^\s+(?=\S)/g,'')">
                        <div class="invalid-feedback-show" *ngIf="errorInput.branchName">
                            {{errorInput.branchName}}
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<hr />

<div class="row px-3">
    <div class="col-12">
        <div class="row">
            <div class="col-12">
                <p class="text-sub-document">
                    ที่อยู่จดทะเบียน
                </p>
            </div>
            <div class="col-lg-8 col-12 mb-1">
                <label for="" class="text-sub-document">ชื่อผู้ติดต่อ</label>
                <input type="text" class="form-control" [(ngModel)]="customerDetails.personalFirstName"
                    [disabled]="disableCheck" onkeyup="this.value=this.value.replace(/^\s*$|^\s+(?=\S)/g,'')"
                    [ngClass]="{'is-invalid': errorInput.personalFirstName}" [maxlength]="100">
                <div class="invalid-feedback-show" *ngIf="errorInput.personalFirstName">
                    {{errorInput.personalFirstName}}
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-8 col-12 mb-1">
                <label for="" class="text-sub-document">ที่อยู่</label>
                <textarea type="text" class="form-control" rows="3" [(ngModel)]="customerAddress.addressNumber"
                    [disabled]="disableCheck" [ngClass]="{'is-invalid': errorInput.addressNumber}" [maxlength]="180"
                    onkeyup="this.value=this.value.replace(/^\s*$|^\s+(?=\S)/g,'')"></textarea>
                <div class="invalid-feedback-show" *ngIf="this.errorInput.addressNumber">
                    {{this.errorInput.addressNumber}}
                </div>
            </div>

        </div>
    </div>

    <div class="col-12">
        <div class="row">
            <div class="col-lg-4 col-12 mb-1">
                <label for="" class="text-sub-document">จังหวัด</label>
                <ng-select [(ngModel)]="selectProvince" (change)="onSelectChange()" placeholder="โปรดเลือกจังหวัด"
                    [disabled]="disableCheck" [clearable]="false"
                    [ngClass]="{'is-invalid-option': errorInput.selectProvince}">
                    <ng-option *ngFor="let province of provinces"
                        [value]="province.id">{{province.provinceName}}</ng-option>
                </ng-select>
                <div class="invalid-feedback-show" *ngIf="errorInput.selectProvince">
                    {{errorInput.selectProvince}}
                </div>
            </div>
            <div class="col-lg-4 col-12 mb-1">
                <label for="" class="text-sub-document">เขต / อำเภอ</label>
                <ng-select [(ngModel)]="selectDistrict" (change)="onSelectChangeDistrict()"
                    placeholder="โปรดเลือกเขต/อำเภอ" [disabled]="disFieldDistrict || disableCheck" [clearable]="false"
                    [ngClass]="{'is-invalid-option': errorInput.selectDistrict}">
                    <ng-option *ngFor="let district of districts"
                        [value]="district.id">{{district.districtName}}</ng-option>
                </ng-select>
                <div class="invalid-feedback-show" *ngIf="errorInput.selectDistrict">
                    {{errorInput.selectDistrict}}
                </div>
            </div>
        </div>
    </div>

    <div class="col-12">
        <div class="row">
            <div class="col-lg-4 col-12 mb-1">
                <label for="" class="text-sub-document">แขวง / ตำบล</label>
                <ng-select [(ngModel)]="selectSubDistrict" (change)="onSelectChangeSubDistrict()"
                    placeholder="โปรดเลือกแขวง/ตำบล" [disabled]="disFieldSubDistrict || disableCheck"
                    [clearable]="false" [ngClass]="{'is-invalid-option': errorInput.selectSubDistrict}">
                    <ng-option *ngFor="let subDistrict of subDistricts"
                        [value]="subDistrict.id">{{subDistrict.subDistrictName}}</ng-option>
                </ng-select>
                <div class="invalid-feedback-show" *ngIf="errorInput.selectSubDistrict">
                    {{errorInput.selectSubDistrict}}
                </div>
            </div>
            <div class="col-lg-4 col-12 mb-1">
                <label for="" class="text-sub-document">รหัสไปรษณีย์</label>
                <input type="text" class="form-control" [(ngModel)]="postCode" [disabled]="true">
            </div>
        </div>
    </div>
</div>
<hr />
<div class="row px-3">
    <div class="col-12">
        <div class="row">
            <div class="col-12">
                <p class="text-sub-document">
                    ที่อยู่จัดส่งเอกสาร
                </p>
            </div>
            <div class="col-lg-8 col-12 mb-1">
                <label for="" class="text-sub-document">ชื่อผู้ติดต่อ</label>
                <input type="text" class="form-control" [(ngModel)]="customerDetails.customerFirstName"
                    [disabled]="disableCheck" [ngClass]="{'is-invalid': errorInput.customerFirstName}" [maxlength]="100"
                    onkeyup="this.value=this.value.replace(/^\s*$|^\s+(?=\S)/g,'')">
                <div class="invalid-feedback-show" *ngIf="errorInput.customerFirstName">
                    {{errorInput.customerFirstName}}
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-8 col-12 mb-1">
                <label for="" class="text-sub-document">ที่อยู่</label>
                <textarea type="text" class="form-control" rows="3" [(ngModel)]="customerDocAddress.addressNumber"
                    [disabled]="disableCheck" [ngClass]="{'is-invalid': errorInput.addressNumberDoc}" [maxlength]="180"
                    onkeyup="this.value=this.value.replace(/^\s*$|^\s+(?=\S)/g,'')"></textarea>
                <div class="invalid-feedback-show" *ngIf="errorInput.addressNumberDoc">
                    {{errorInput.addressNumberDoc}}
                </div>
            </div>
        </div>
    </div>

    <div class="col-12">
        <div class="row">
            <div class="col-lg-4 col-12 mb-1">
                <label for="" class="text-sub-document">จังหวัด</label>
                <ng-select [(ngModel)]="selectProvinceDoc" (change)="onSelectDocChange()" placeholder="โปรดเลือกจังหวัด"
                    [disabled]="disableCheck" [clearable]="false"
                    [ngClass]="{'is-invalid-option': errorInput.selectProvinceDoc}">
                    <ng-option *ngFor="let province of provincesDoc"
                        [value]="province.id">{{province.provinceName}}</ng-option>
                </ng-select>
                <div class="invalid-feedback-show" *ngIf="errorInput.selectProvinceDoc">
                    {{errorInput.selectProvinceDoc}}
                </div>
            </div>
            <div class="col-lg-4 col-12 mb-1">
                <label for="" class="text-sub-document">เขต / อำเภอ</label>
                <ng-select [(ngModel)]="selectDistrictDoc" (change)="onSelectDocChangeDistrict()"
                    placeholder="โปรดเลือกเขต/อำเภอ" [disabled]="disFieldDistrictDoc || disableCheck"
                    [clearable]="false" [ngClass]="{'is-invalid-option': errorInput.selectDistrict}">
                    <ng-option *ngFor="let district of districtsDoc"
                        [value]="district.id">{{district.districtName}}</ng-option>
                </ng-select>
                <div class="invalid-feedback-show" *ngIf="errorInput.selectDistrictDoc">
                    {{errorInput.selectDistrictDoc}}
                </div>
            </div>
        </div>
    </div>

    <div class="col-12">
        <div class="row">
            <div class="col-lg-4 col-12 mb-1">
                <label for="" class="text-sub-document">แขวง / ตำบล</label>
                <ng-select [(ngModel)]="selectSubDistrictDoc" (change)="onSelectDocChangeSubDistrict()"
                    placeholder="โปรดเลือกแขวง/ตำบล" [disabled]="disFieldSubDistrictDoc || disableCheck"
                    [clearable]="false" [ngClass]="{'is-invalid-option': errorInput.selectSubDistrictDoc}">
                    <ng-option *ngFor="let subDistrict of subDistrictsDoc"
                        [value]="subDistrict.id">{{subDistrict.subDistrictName}}</ng-option>
                </ng-select>
                <div class="invalid-feedback-show" *ngIf="errorInput.selectSubDistrictDoc">
                    {{errorInput.selectSubDistrictDoc}}
                </div>
            </div>
            <div class="col-lg-4 col-12 mb-1">
                <label for="" class="text-sub-document">รหัสไปรษณีย์</label>
                <input type="text" class="form-control" [(ngModel)]="postCodeDoc" [disabled]="true">
            </div>
        </div>
    </div>
</div>
<hr />

<div class="row px-3">
    <div class="col-12">
        <div class="row">
            <div class="col-12">
                <p class="text-sub-document">
                    ช่องทางติดต่อ
                </p>
            </div>
            <div class="col-lg-4 col-12 mb-1">
                <label for="" class="text-sub-document">อีเมล</label>
                <input type="email" class="form-control" [disabled]="disableCheck"
                    [(ngModel)]="customerDetails.contactEmail" [ngClass]="{'is-invalid': errorInput.contactEmail}">
                <!-- this.customerDetails.contactEmail.trim() -->
                <div class="invalid-feedback-show" *ngIf="errorInput.contactEmail">
                    {{errorInput.contactEmail}}
                </div>
            </div>
            <div class="col-lg-4 col-12 mb-1">
                <label for="" class="text-sub-document">ชื่อเจ้าของอีเมล</label>
                <input type="email" class="form-control" [disabled]="disableCheck"
                    [(ngModel)]="customerDetails.contactOwnerEmail"
                    [ngClass]="{'is-invalid': errorInput.contactOwnerEmail}">
                <!-- this.customerDetails.contactEmail.trim() -->
                <div class="invalid-feedback-show" *ngIf="errorInput.contactOwnerEmail">
                    {{errorInput.contactOwnerEmail}}
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-12 mb-1">
                <label for="" class="text-sub-document">อีเมล2</label>
                <input type="email" class="form-control" [disabled]="disableCheck"
                    [(ngModel)]="customerDetails.contactEmail2" [ngClass]="{'is-invalid': errorInput.contactEmail2}">
                <!-- this.customerDetails.contactEmail.trim() -->
                <div class="invalid-feedback-show" *ngIf="errorInput.contactEmail2">
                    {{errorInput.contactEmail2}}
                </div>
            </div>
            <div class="col-lg-4 col-12 mb-1">
                <label for="" class="text-sub-document">ชื่อเจ้าของอีเมล2</label>
                <input type="email" class="form-control" [disabled]="disableCheck"
                    [(ngModel)]="customerDetails.contactOwnerEmail2"
                    [ngClass]="{'is-invalid': errorInput.contactOwnerEmail2}">
                <!-- this.customerDetails.contactEmail.trim() -->
                <div class="invalid-feedback-show" *ngIf="errorInput.contactOwnerEmail2">
                    {{errorInput.contactOwnerEmail2}}
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-12 mb-1">
                <label for="" class="text-sub-document">อีเมล3</label>
                <input type="email" class="form-control" [disabled]="disableCheck"
                    [(ngModel)]="customerDetails.contactEmail3" [ngClass]="{'is-invalid': errorInput.contactEmail3}">
                <!-- this.customerDetails.contactEmail.trim() -->
                <div class="invalid-feedback-show" *ngIf="errorInput.contactEmail3">
                    {{errorInput.contactEmail3}}
                </div>
            </div>
            <div class="col-lg-4 col-12 mb-1">
                <label for="" class="text-sub-document">ชื่อเจ้าของอีเมล3</label>
                <input type="email" class="form-control" [disabled]="disableCheck"
                    [(ngModel)]="customerDetails.contactOwnerEmail3"
                    [ngClass]="{'is-invalid': errorInput.contactOwnerEmail3}">
                <!-- this.customerDetails.contactEmail.trim() -->
                <div class="invalid-feedback-show" *ngIf="errorInput.contactOwnerEmail3">
                    {{errorInput.contactOwnerEmail3}}
                </div>
            </div>
        </div>


        <hr class="mb-1">
    </div>


    <div class="col-12">
        <div class="row">
            <div class="col-lg-4 col-12 mb-1">

                <label for="" class="text-sub-document">เบอร์โทร1</label>
                <input type="tel" class="form-control" [disabled]="disableCheck" [maxlength]="10"
                    [(ngModel)]="customerDetails.contactPhone1" [ngClass]="{'is-invalid': errorInput.contactPhone1}"
                    onkeyup="this.value=this.value.replace(/^\s*$|^\s+(?=\S)/g,'')">
                <div class="invalid-feedback-show" *ngIf="errorInput.contactPhone1">
                    {{errorInput.contactPhone1}}
                </div>
            </div>

            <div class="col-lg-4 col-12 mb-1">
                <label for="" class="text-sub-document">เบอร์โทร2</label>
                <input type="tel" class="form-control" [maxlength]="10" [disabled]="disableCheck"
                    [(ngModel)]="customerDetails.contactPhone2" [ngClass]="{'is-invalid': errorInput.contactPhone2}"
                    onkeyup="this.value=this.value.replace(/^\s*$|^\s+(?=\S)/g,'')">

            </div>
            <div class="col-lg-4 col-12 mb-1">
                <label for="" class="text-sub-document">เบอร์โทร3</label>
                <input type="tel" class="form-control" [maxlength]="10" [disabled]="disableCheck"
                    [(ngModel)]="customerDetails.contactPhone3" [ngClass]="{'is-invalid': errorInput.contactPhone3}"
                    onkeyup="this.value=this.value.replace(/^\s*$|^\s+(?=\S)/g,'')">

            </div>

        </div>
    </div>

    <div class="col-12">
        <div class="row">
            <div class="col-lg-4 col-12 mb-1">
                <label for="" class="text-sub-document">เว็บไซต์</label>
                <input type="text" class="form-control" [disabled]="disableCheck"
                    [(ngModel)]="customerDetails.contactWebsite"
                    onkeyup="this.value=this.value.replace(/^\s*$|^\s+(?=\S)/g,'')">
            </div>
            <!-- <div class="col-lg-4 col-12 mb-1">
                <label for="" class="text-sub-document">เบอร์แฟกซ์</label>
                <input type="tel" class="form-control" 
                [disabled]="disableCheck" 
                [(ngModel)]="customerDetails.contactFax"
                onkeyup="this.value=this.value.replace(/^\s*$|^\s+(?=\S)/g,'')">
                <!-- onkeyup="this.value=this.value.replace(/[^\d]/,'')" -->
            <!-- </div> -->
            <div class="col-lg-4 col-12 mb-1">
                <label for="" class="text-sub-document">ไลน์</label>
                <input type="tel" class="form-control" [disabled]="disableCheck"
                    [(ngModel)]="customerDetails.contactLine"
                    onkeyup="this.value=this.value.replace(/^\s*$|^\s+(?=\S)/g,'')">
                <!-- onkeyup="this.value=this.value.replace(/[^\d]/,'')" -->
            </div>
        </div>
    </div>

    <div class="col-12">
        <div class="d-flex" style="align-items: center;">
            <p class="text-sub-document mr-2 mb-0">Active</p>
            <label class="switch">
                <input type="checkbox" id="switchActive" [disabled]="disableCheck"
                    [(ngModel)]="customerDetails.isActive">
                <span class="slider round"></span>
            </label>
        </div>
    </div>
</div>

<div class="row justify-content-center mt-2">
    <div class="col-lg-4 col-6">
        <button class="btn btn-cancel w-100" (click)="onCancel()" [disabled]="disableCheck">ยกเลิก</button>
    </div>
    <div class="col-lg-4 col-6">
        <button class="btn btn-main w-100" (click)="onSubmit(confirmModal)" [disabled]="disableCheck">ยืนยัน</button>
    </div>
</div>

<ng-template #confirmModal let-modal>
    <div class="modal-header text-center bg-primary">
        <h3 class="modal-title w-100">Account Task Management System</h3>
    </div>
    <div class="modal-body">
        <div class="my-4">
            <h4 class="big-text blue-text text-center">
                คุณต้องการยืนยันการทำรายการนี้หรือไม่ ?
            </h4>
        </div>
    </div>
    <div class="modal-footer justify-content-between">
        <button type="button" class="btn btn-danger" (click)="closeModal()">ยกเลิก</button>
        <button type="button" class="btn btn-primary" (click)="closeModal(); saveCustomer($event)">ยืนยัน</button>

    </div>
</ng-template>
<!-- <app-confirm-modal (dataEmitted)="saveCustomer($event)" #confirmModal></app-confirm-modal> -->