import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { ItemPackageDetail, ItemSets, ManagePackage } from 'app/auth/models/manage-package';

@Injectable({
  providedIn: 'root'
})
export class ManagePackageService {
  private apiBaseUrl: string = `${environment.apiUrl}/api`;
  private httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {}
  
  get API_URL() {
    return this.apiBaseUrl;
  }

  get API_HEADERS() {
    return this.httpHeaders;
  }

  SetHttpHeaders(token): void {
    this.httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`);
  }

  getItemSet(page: number, pageLength: number, name: string, isShowing:boolean, isActive:boolean) : Observable<any>{
    let url = `${this.apiBaseUrl}/item?SubCategoryId=26&IsItemSet=true&PageLength=${pageLength}&Page=${page}&Name=${name}`;
    if(isShowing != undefined && isActive != undefined){
      url += `&Name=${name}&IsShowing=${isShowing}&IsActive=${isActive}`;
    }
    return this.http.get<any>(url, {headers : this.httpHeaders});
  }

  getAllItem() : Observable<any>{
    const url = `${this.apiBaseUrl}/item?SubCategoryId=26&IsItemSet=true&SortPath=name&Direction=0`;
    return this.http.get<any>(url, { headers : this.httpHeaders});
  }

  getItemdetail(id: string) : Observable<any>{
    const url = `${this.apiBaseUrl}/item/${id}`;
    return this.http.get<any>(url, { headers : this.httpHeaders});
  }
  getItemSetdetail(id: string) : Observable<any>{
    const url = `${this.apiBaseUrl}/itemSet/${id}`;
    return this.http.get<any>(url, { headers : this.httpHeaders});
  }
  getItempackagedetail(id: string) : Observable<any>{
    const url = `${this.apiBaseUrl}/itempackagedetail?ItemId=${id}&SortPath=ordinal`;
    return this.http.get<any>(url, { headers : this.httpHeaders});
  }

  getGroupIDListOfPackage() : Observable<any>{
    return this.http.get(`${this.apiBaseUrl}/group?Name=ListOfPackage`, { headers : this.httpHeaders});
  }
  getGroupIDDescriptionPackage() : Observable<any>{
    return this.http.get(`${this.apiBaseUrl}/group?Name=DescriptionOfPackage`, { headers : this.httpHeaders});
  }
  getGroupIDGroupOfItemSet() : Observable<any>{
    return this.http.get(`${this.apiBaseUrl}/group?Name=GroupOfItemSet`, { headers : this.httpHeaders});
  }
  getCategoryServiceList() : Observable<any>{
    return this.http.get(`${this.apiBaseUrl}/category?Name=ServiceList`, { headers : this.httpHeaders});
  }
  getCategoryVerifyList() : Observable<any>{
    return this.http.get(`${this.apiBaseUrl}/category?Name=VerifyList`, { headers : this.httpHeaders});
  }
  getCategoryDescription() : Observable<any>{
    return this.http.get(`${this.apiBaseUrl}/category?Name=Description`, { headers : this.httpHeaders});
  }
  getCategoryOfItemSet() : Observable<any>{
    return this.http.get(`${this.apiBaseUrl}/category?Name=CategoryOfItemSet`, { headers : this.httpHeaders});
  }
  getSubCategoryServiceList() : Observable<any>{
    return this.http.get(`${this.apiBaseUrl}/subcategory?Name=ServiceList`, { headers : this.httpHeaders});
  }
  getSubCategoryVerifyList() : Observable<any>{
    return this.http.get(`${this.apiBaseUrl}/subcategory?Name=VerifyList`, { headers : this.httpHeaders});
  }
  getSubCategoryDescription() : Observable<any>{
    return this.http.get(`${this.apiBaseUrl}/subcategory?Name=Description`, { headers : this.httpHeaders});
  }
  getSubCategoryOfItemSet() : Observable<any>{
    return this.http.get(`${this.apiBaseUrl}/subcategory?Name=SubCategoryOfItemSet`, { headers : this.httpHeaders});
  }

  saveItem(model : ManagePackage) : Observable<any>{
    const url = `${this.apiBaseUrl}/item`;
    return this.http.post(url, model , { headers : this.httpHeaders} );
  }
  createItemPackageDetail(model : ItemPackageDetail): Observable<any>{
    const url = `${this.apiBaseUrl}/itemPackageDetail`;
    return this.http.post(url, model , { headers : this.httpHeaders} );
  }
  setPackageItem(model : ItemSets, itemId : string): Observable<any>{
    const url = `${this.apiBaseUrl}/itemSet/${itemId}`;
    return this.http.put(url, model , { headers : this.httpHeaders} );
  }
  updateItemPackageDetail(model : ItemPackageDetail) : Observable<any>{
    const url = `${this.apiBaseUrl}/itemPackageDetail/${model.id}`;
    return this.http.put(url, model, { headers : this.httpHeaders});
  }

  updateServiceDetail(model : any) : Observable<any>{
    const url = `${this.apiBaseUrl}/item/${model.id}`;
    return this.http.put(url, model, { headers : this.httpHeaders});
  }
}
