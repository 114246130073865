export const paymentTypeList: any[] = [
  { id: 0, name: 'บริษัทชำระ' },
  { id: 1, name: 'ลูกค้ายื่น' },
  { id: 2, name: '-- ไม่ระบุ --' },
];

export const customerPaymentTypeList: any[] = [
  { id: 0, name: '-- ไม่ระบุ --' },
  { id: 1, name: 'ชำระด้วยเงินสด' },
  { id: 2, name: 'โอนผ่านธนาคาร' },
];
