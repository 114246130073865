<div *ngIf="!Excess">
  <div #onlineScope class="online-scope">
    <div name="online-view-v1" class="online-view-v1">
      <div class="watermasks" *ngIf="invoiceObj.isVoid">
        <p *ngIf="invoiceObj.isApprove" class="void-watermasks">ยกเลิก</p>
        <p *ngIf="!invoiceObj.isApprove" class="void-watermasks-with-inner">
          ยกเลิก
        </p>
      </div>
      <div class="watermasks" *ngIf="!invoiceObj.isApprove">
        <p *ngIf="!invoiceObj.isVoid" class="inner-watermasks">ยังไม่อนุมัติ</p>
        <p *ngIf="invoiceObj.isVoid" class="inner-watermasks-with-void">
          ยังไม่อนุมัติ
        </p>
      </div>
      <table class="invoice-slip">
        <tbody>
          <ng-container *ngTemplateOutlet="headerPaper"></ng-container>

          <tr class="body" *ngIf="invoiceObj">
            <td #parentTable>
              <div class="invoice-main-scope" #mainElement>
                <table
                  class="invoice-main-table"
                  [ngClass]="{ invoice_main_table_fx: !fxTable }"
                  #tableElement
                >
                  <tbody>
                    <tr class="trc-header" #trcHeaderElement>
                      <td>
                        <strong>ลำดับ </strong>
                      </td>
                      <td style="width: 300px">
                        <strong>รายการ</strong>
                      </td>
                      <td>
                        <strong>วิธียื่น</strong>
                      </td>
                      <td>
                        <strong>ภาษี</strong>
                      </td>
                      <td>
                        <strong>บริการ</strong>
                      </td>
                      <td>
                        <strong>ค่าปรับ</strong>
                      </td>
                      <td>
                        <strong>ราคาสุทธิ</strong>
                      </td>
                    </tr>

                    <tr
                      *ngFor="
                        let item of invoiceObj.companySubmitItem;
                        index as ind
                      "
                      #itemListElements
                      class="trc"
                    >
                      <td class="text-center">
                        <span class="text-dark"> {{ ind + 1 }} </span>
                      </td>
                      <td>
                        <span
                          >{{ item.itemName }} <br />{{
                            item.description[0].description
                          }}
                        </span>
                      </td>
                      <td>
                        <span>{{ submitTypeList[item.submitType].name }}</span>
                      </td>
                      <td
                        [ngClass]="{
                          'text-center': item.documentTypeEnum !== 1
                        }"
                      >
                        <div
                          [ngClass]="{
                            setPerens: isNegitive(item.unitPrice)
                          }"
                        >
                          <span *ngIf="item.documentTypeEnum === 1">{{
                            item.unitPrice | negToPos | currency : "" : ""
                          }}</span>
                        </div>
                        <span *ngIf="item.documentTypeEnum !== 1">-</span>
                      </td>
                      <td
                        [ngClass]="{
                          'text-center':
                            item.documentTypeEnum !== 2 &&
                            item.documentTypeEnum !== 3
                        }"
                      >
                        <div
                          [ngClass]="{
                            setPerens: isNegitive(item.unitPrice)
                          }"
                        >
                          <span
                            *ngIf="
                              item.documentTypeEnum === 2 ||
                              item.documentTypeEnum === 3
                            "
                            >{{
                              item.unitPrice | negToPos | currency : "" : ""
                            }}</span
                          >
                        </div>
                        <span
                          *ngIf="
                            item.documentTypeEnum !== 2 &&
                            item.documentTypeEnum !== 3
                          "
                          >-</span
                        >
                      </td>
                      <td [ngClass]="{ 'text-center': item.fineAmount == 0 }">
                        <div
                          [ngClass]="{
                            setPerens: isNegitive(item.fineAmount)
                          }"
                        >
                          <span *ngIf="item.fineAmount != 0">{{
                            item.fineAmount | negToPos | currency : "" : ""
                          }}</span>
                        </div>
                        <span *ngIf="item.fineAmount == 0">-</span>
                      </td>
                      <td
                        [ngClass]="{ setPerens: isNegitive(item.preTaxAmount) }"
                      >
                        <span>{{
                          item.preTaxAmount | negToPos | currency : "" : ""
                        }}</span>
                      </td>
                    </tr>

                    <!-- *ngFor="let num of [].constructor(dummyRowAmountList[0])" -->
                    <tr
                      class="dummy-row"
                      [ngClass]="{ 'd-none': !showDummyRow }"
                      *ngFor="let num of [].constructor(dummyRowAmountList[0])"
                    >
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </tr>

          <ng-container *ngTemplateOutlet="footerPaper"></ng-container>
          <div style="margin-top: 30px; margin-bottom: 20px">
            <strong class="customer-self-invoice"
              >รายละเอียดที่ลูกค้าชำระด้วยตนเอง</strong
            >
          </div>

          <div #mainElementBottom>
            <table class="customer-invoice-table" #tableElementBottom>
              <tr class="trc-header-bottom" #trcHeaderElementBottom>
                <th>
                  <strong>ลำดับ</strong>
                </th>
                <th>
                  <strong>รายการ</strong>
                </th>
                <th>
                  <strong>เดือน</strong>
                </th>
                <th>
                  <strong>ค่าภาษี / บริการ</strong>
                </th>
                <th>
                  <strong>จำนวนค่าปรับ</strong>
                </th>
                <th>
                  <strong>ราคาสุทธิ</strong>
                </th>
              </tr>

              <tr
                *ngFor="let item of invoiceObj.customerSubmitItem; index as ind"
                #itemListElementsBottom
                class="trc-bottom"
              >
                <td>{{ ind + 1 }}</td>
                <td>
                  {{ item.itemName }}<br />{{ item.description[0].description }}
                </td>
                <td>
                  {{
                    invoiceObj
                      ? months[invoiceObj.monthId - 1]
                      : ("-" | thaidate : "MMMM")
                  }}
                </td>
                <td>{{ item.unitPrice | currency : "" : "" }}</td>
                <td>{{ item.fineAmount | currency : "" : "" }}</td>
                <td>{{ item.grandTotal | currency : "" : "" }}</td>
              </tr>
              <tr class="dummy-row" *ngIf="invoiceObj.customerSubmitItem == 0">
                <td>1</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>

              <tr>
                <td style="height: 34px" colspan="5">
                  <strong style="font-size: 24px; vertical-align: text-bottom">
                    จำนวนเงินรวมทั้งสิ้น (บาท)
                  </strong>
                </td>
                <td style="text-align: right">
                  <strong>{{ customerGrandTotal | currency : "" : "" }}</strong>
                </td>
              </tr>
            </table>
          </div>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div *ngIf="Excess">
  <div
    #onlineScope
    class="online-scope"
    *ngFor="let list of itemList; let lst = last; let ind = index"
  >
    <div class="page-break"></div>
    <div name="online-view-v1" class="online-view-v1" #mainElement>
      <div class="watermasks" *ngIf="invoiceObj.isVoid">
        <p *ngIf="invoiceObj.isApprove" class="void-watermasks">ยกเลิก</p>
        <p *ngIf="!invoiceObj.isApprove" class="void-watermasks-with-inner">
          ยกเลิก
        </p>
      </div>
      <div class="watermasks" *ngIf="!invoiceObj.isApprove">
        <p *ngIf="!invoiceObj.isVoid" class="inner-watermasks">ยังไม่อนุมัติ</p>
        <p *ngIf="invoiceObj.isVoid" class="inner-watermasks-with-void">
          ยังไม่อนุมัติ
        </p>
      </div>
      <table class="invoice-slip">
        <tbody>
          <ng-container *ngTemplateOutlet="headerPaper"></ng-container>

          <div *ngIf="ind + 1 <= topItemList.length">
            <tr class="body" #overElement *ngIf="invoiceObj">
              <!-- <div
                class="invoice-main-scope-excess"
                #mainElement
                [ngClass]="{
                  overhigh: !lst
                }"
              > -->
              <div class="invoice-main-scope-excess" #mainElement>
                <div *ngIf="list.length != 0">
                  <table
                    class="invoice-main-table"
                    [ngClass]="{ invoice_main_table_fx: !fxTable }"
                    #tableElement
                  >
                    <tbody>
                      <tr class="trc-header" #trcHeaderElement>
                        <td>
                          <strong>ลำดับ </strong>
                        </td>
                        <td style="width: 300px">
                          <strong>รายการ</strong>
                        </td>
                        <td>
                          <strong>วิธียื่น</strong>
                        </td>
                        <td>
                          <strong>ภาษี</strong>
                        </td>
                        <td>
                          <strong>บริการ</strong>
                        </td>
                        <td>
                          <strong>ค่าปรับ</strong>
                        </td>
                        <td>
                          <strong>ราคาสุทธิ</strong>
                        </td>
                      </tr>

                      <tr
                        *ngFor="let item of list; index as ind"
                        class="trc"
                        #trClild
                      >
                        <td class="text-center">
                          <span class="text-dark"> {{ ind + 1 }} </span>
                        </td>
                        <td>
                          <span
                            >{{ item.itemName }} <br />{{
                              item.description[0].description
                            }}
                          </span>
                        </td>
                        <td>
                          <span>{{
                            submitTypeList[item.submitType].name
                          }}</span>
                        </td>
                        <td
                          [ngClass]="{
                            'text-center': item.documentTypeEnum !== 1
                          }"
                        >
                          <div
                            [ngClass]="{
                              setPerens: isNegitive(item.unitPrice)
                            }"
                          >
                            <span *ngIf="item.documentTypeEnum === 1">{{
                              item.unitPrice | negToPos | currency : "" : ""
                            }}</span>
                          </div>
                          <span *ngIf="item.documentTypeEnum !== 1">-</span>
                        </td>
                        <td
                          [ngClass]="{
                            'text-center':
                              item.documentTypeEnum !== 2 &&
                              item.documentTypeEnum !== 3
                          }"
                        >
                          <div
                            [ngClass]="{
                              setPerens: isNegitive(item.unitPrice)
                            }"
                          >
                            <span
                              *ngIf="
                                item.documentTypeEnum === 2 ||
                                item.documentTypeEnum === 3
                              "
                              >{{
                                item.unitPrice | negToPos | currency : "" : ""
                              }}</span
                            >
                          </div>
                          <span
                            *ngIf="
                              item.documentTypeEnum !== 2 &&
                              item.documentTypeEnum !== 3
                            "
                            class="text-center"
                            >-</span
                          >
                        </td>
                        <td
                          [ngClass]="{
                            'text-center': item.fineAmount == 0
                          }"
                        >
                          <div
                            [ngClass]="{
                              setPerens: isNegitive(item.fineAmount)
                            }"
                          >
                            <span *ngIf="item.fineAmount != 0">{{
                              item.fineAmount | negToPos | currency : "" : ""
                            }}</span>
                          </div>
                          <span *ngIf="item.fineAmount == 0">-</span>
                        </td>
                        <td
                          [ngClass]="{
                            setPerens: isNegitive(item.preTaxAmount)
                          }"
                        >
                          <span>{{
                            item.preTaxAmount | negToPos | currency : "" : ""
                          }}</span>
                        </td>
                      </tr>

                      <!-- *ngFor="let num of [].constructor(dummyRowAmountList[0])" -->
                      <tr
                        class="dummy-row"
                        [ngClass]="{ 'd-none': !showDummyRow }"
                        *ngFor="
                          let num of [].constructor(dummyRowAmountList[ind])
                        "
                      >
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                  <div *ngIf="paperTopWithFooter || bottomTableExcess">
                    <ng-container
                      *ngTemplateOutlet="footerPaper"
                    ></ng-container>
                  </div>
                </div>
              </div>
            </tr>
          </div>

          <div *ngIf="ind + 1 == topItemList.length">
            <div *ngIf="!paperTopWithFooter && !bottomTableExcess">
              <ng-container *ngTemplateOutlet="footerPaper"></ng-container>
            </div>
            <div *ngIf="!bottomTableExcess">
              <div style="margin-top: 30px; margin-bottom: 20px">
                <strong class="customer-self-invoice"
                  >รายละเอียดที่ลูกค้าชำระด้วยตนเอง</strong
                >
              </div>

              <div #mainElementBottom>
                <table class="customer-invoice-table" #tableElementBottom>
                  <tr class="trc-header-bottom" #trcHeaderElementBottom>
                    <th>
                      <strong>ลำดับ</strong>
                    </th>
                    <th>
                      <strong>รายการ</strong>
                    </th>
                    <th>
                      <strong>เดือน</strong>
                    </th>
                    <th>
                      <strong>ค่าภาษี / บริการ</strong>
                    </th>
                    <th>
                      <strong>จำนวนค่าปรับ</strong>
                    </th>
                    <th>
                      <strong>ราคาสุทธิ</strong>
                    </th>
                  </tr>

                  <tr
                    *ngFor="
                      let item of invoiceObj.customerSubmitItem;
                      index as ind
                    "
                    #itemListElementsBottom
                    class="trc-bottom"
                  >
                    <td>{{ ind + 1 }}</td>
                    <td>
                      {{ item.itemName }}<br />{{
                        item.description[0].description
                      }}
                    </td>
                    <td>
                      {{
                        invoiceObj
                          ? months[invoiceObj.monthId - 1]
                          : ("-" | thaidate : "MMMM")
                      }}
                    </td>
                    <td>{{ item.unitPrice | currency : "" : "" }}</td>
                    <td>{{ item.fineAmount | currency : "" : "" }}</td>
                    <td>{{ item.grandTotal | currency : "" : "" }}</td>
                  </tr>
                  <tr
                    class="dummy-row"
                    *ngIf="invoiceObj.customerSubmitItem == 0"
                  >
                    <td>1</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>

                  <tr>
                    <td style="height: 34px" colspan="5">
                      <strong
                        style="font-size: 24px; vertical-align: text-bottom"
                      >
                        จำนวนเงินรวมทั้งสิ้น (บาท)
                      </strong>
                    </td>
                    <td style="text-align: right">
                      <strong>{{
                        customerGrandTotal | currency : "" : ""
                      }}</strong>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>

          <div *ngIf="bottomTableExcess && ind + 1 > topItemList.length">
            <div class="customer-submit-table">
              <div style="margin-top: 30px; margin-bottom: 20px">
                <strong class="customer-self-invoice"
                  >รายละเอียดที่ลูกค้าชำระด้วยตนเอง</strong
                >
              </div>

              <div #mainElementBottom>
                <table class="customer-invoice-table" #tableElementBottom>
                  <tr class="trc-header-bottom" #trcHeaderElementBottom>
                    <th>
                      <strong>ลำดับ</strong>
                    </th>
                    <th>
                      <strong>รายการ</strong>
                    </th>
                    <th>
                      <strong>เดือน</strong>
                    </th>
                    <th>
                      <strong>ค่าภาษี / บริการ</strong>
                    </th>
                    <th>
                      <strong>จำนวนค่าปรับ</strong>
                    </th>
                    <th>
                      <strong>ราคาสุทธิ</strong>
                    </th>
                  </tr>

                  <tr
                    *ngFor="let item of itemList[ind]; index as ind"
                    #itemListElementsBottom
                    class="trc-bottom"
                  >
                    <td>{{ ind + 1 }}</td>
                    <td>
                      {{ item.itemName }}<br />{{
                        item.description[0].description
                      }}
                    </td>
                    <td>
                      {{
                        invoiceObj
                          ? months[invoiceObj.monthId - 1]
                          : ("-" | thaidate : "MMMM")
                      }}
                    </td>
                    <td>{{ item.unitPrice | currency : "" : "" }}</td>
                    <td>{{ item.fineAmount | currency : "" : "" }}</td>
                    <td>{{ item.grandTotal | currency : "" : "" }}</td>
                  </tr>
                  <tr
                    class="dummy-row"
                    *ngIf="invoiceObj.customerSubmitItem == 0"
                  >
                    <td>1</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>

                  <tr>
                    <td style="height: 34px" colspan="5">
                      <strong
                        style="font-size: 24px; vertical-align: text-bottom"
                      >
                        จำนวนเงินรวมทั้งสิ้น (บาท)
                      </strong>
                    </td>
                    <td style="text-align: right">
                      <strong>{{
                        customerGrandTotal | currency : "" : ""
                      }}</strong>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </tbody>
      </table>
      <span class="page-number"
        >หน้า : {{ ind + 1 }}/{{ itemList.length }}</span
      >
    </div>
  </div>
</div>

<ng-template #headerPaper>
  <tr class="header">
    <td>
      <!-- HEADER -->
      <div class="document-header">
        <div class="type">
          <table class="invoice-title">
            <tbody>
              <tr>
                <!-- <td class="name" *ngIf="data"> -->
                <td class="name">
                  <h1 style="margin: 0px">
                    <!-- {{ data.titleTh }}
                      <br />
                      {{ data.titleEn }} -->
                    ใบแจ้งหนี้ / Invoice
                  </h1>
                </td>
                <!-- </td> -->

                <td class="duplicate">
                  <p class="set"></p>
                  <p class="duplicate">
                    <!-- <span *ngIf="showManuScr">{{
                        isOriginalDoc
                          ? "( ต้นฉบับ / Original )"
                          : "( สำเนา / Copy )"
                      }}</span> -->
                  </p>
                </td>
                <td class="logo">
                  <div class="logo-scope" style="text-align: center">
                    <img [src]="logoOnPaper" alt="" srcset="" />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="clear"></div>
        </div>
        <div class="contact-info-header">
          <table class="cih-left">
            <tbody>
              <tr>
                <td><strong>ลูกค้า / Customer</strong></td>
                <td colspan="3">
                  {{ invoiceObj ? invoiceObj.customerName : "-" }}

                  <span *ngIf="invoiceObj.islegalEntity"
                    >(

                    <span *ngIf="invoiceObj.isHeadBranch">สำนักงานใหญ่</span>
                    <span *ngIf="!invoiceObj.isHeadBranch"
                      >สาขา&nbsp;{{
                        invoiceObj.branchNumber.toString().padStart(5, "0")
                      }}
                    </span>

                    )</span
                  >

                  <!-- <span *ngIf="!invoiceObj.isHeadBranch">
                    <span>สาขา</span>
                  </span>
                  <span>
                    {{
                      invoiceObj.isHeadBranch
                        ? " สำนักงานใหญ่ "
                        : invoiceObj.branchNumber
                            .toString()
                            .padStart(5, "0")
                    }}
                  </span> -->
                </td>
              </tr>
              <tr>
                <td style="width: 160px; vertical-align: text-top">
                  <strong>ที่อยู่ / Address</strong>
                </td>
                <td colspan="3">
                  <span>
                    {{ invoiceObj ? GetAddressTemplate(customerAddress) : "-" }}
                    <!-- 349 ห้อง 1207 ชั้น 12 จตุจักร จอมพล กรุงเทพมหานคร 10900 -->
                  </span>
                </td>
              </tr>
              <tr>
                <td><strong>เลขผู้เสียภาษี / Tax ID</strong></td>
                <td>
                  {{ invoiceObj ? invoiceObj.taxInvoiceCustomerTaxId : "-" }}
                  <!-- 0105554088014 -->
                </td>
                <td>
                  <strong>เบอร์โทรศัพท์ / Tel.</strong>
                </td>
                <td>
                  {{
                    invoiceObj
                      ? formatPhoneNumber(invoiceObj.shippingPhone)
                      : "-"
                  }}
                </td>
              </tr>
            </tbody>
          </table>
          <table class="cih-right">
            <tbody>
              <tr>
                <td><strong>เลขที่ / No.</strong></td>
                <td>
                  {{ invoiceObj ? invoiceObj.invoiceCode : "-" }}
                </td>
              </tr>
              <tr>
                <td><strong>วันที่ / Issue</strong></td>
                <td>
                  <ng-container *ngIf="invoiceObj">
                    {{
                      (invoiceObj ? invoiceObj.documentCreateDate : "-")
                        | thaidate : "D MMMM yyyy"
                    }}
                  </ng-container>
                </td>
              </tr>
              <!-- <tr *ngIf="showValidDate"> -->
              <tr>
                <td><strong>ครบกำหนด / Valid</strong></td>
                <td>
                  <ng-container *ngIf="invoiceObj">
                    {{
                      (invoiceObj ? invoiceObj.documentExpireDate : "-")
                        | thaidate : "D MMMM yyyy"
                    }}
                  </ng-container>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="contact-info-header">
          <table class="cih-bottom">
            <tbody>
              <tr></tr>
              <tr>
                <td>
                  <strong>ผู้ออก</strong>
                </td>
                <td>
                  <span>{{ invoiceObj.issuerName }}</span>
                </td>
                <td><strong>เลขผู้เสียภาษี</strong><span>/ Tax ID</span></td>
                <td>
                  <span style="float: right; vertical-align: bottom">{{
                    invoiceObj.issuerTaxId
                  }}</span>
                </td>
                <td colspan="2">
                  <strong>พนักงาน</strong><span>/ Create By :</span>
                  <span class="name-text">{{
                    getNameFormat(invoiceObj.preparedByName)
                  }}</span>
                  <!-- <span class="name-text">PloyChomphu K.</span> -->
                </td>
              </tr>

              <tr>
                <td>ที่อยู่</td>
                <td>
                  <span>
                    {{ invoiceObj.issuerAddressDetail }}
                  </span>
                </td>
                <td>
                  <strong>โทรศัพท์ </strong><span>/ Tel</span>
                  <br />
                  <strong>เดือน</strong><span>/ Month :</span>
                </td>
                <td>
                  <!--โทรศัพท์-->
                  <span style="float: right">092 - 478 - 2917</span>
                  <br />
                  <!--แฟ็ก-->
                  <span style="float: right">
                    {{
                      invoiceObj
                        ? months[invoiceObj.monthId - 1]
                        : ("-" | thaidate : "MMMM")
                    }}
                    {{ invoiceObj ? invoiceObj.year + 543 : "-" }}</span
                  >
                </td>
                <td colspan="2">
                  <strong>ผู้อนุมัติ</strong><span>/ Approve By :</span>
                  <span class="name-text">{{
                    getNameFormat(invoiceObj.approveByName)
                  }}</span>
                  <!-- <span class="name-text">PloyChomphu K.</span> -->
                  <br />
                  <strong>หน่วย :</strong>
                  <span style="float: right">บาท</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </td>
  </tr>
</ng-template>

<ng-template #footerPaper>
  <tr style="height: 10px"></tr>
  <table class="invoice-slip-summary">
    <td>
      <tr *ngIf="invoiceObj.islegalEntity">
        <div>
          <td colspan="2" *ngIf="isCompany">
            <table class="payment-alert">
              <tr>
                <td colspan="2">
                  <strong>**หมายเหตุ** </strong>
                  <span class="span-alert" style="word-break: break-word"
                    >กรุณาทำเอกสารหัก ณ ที่จ่าย</span
                  >
                  <span> ค่าบริการจัดทำบัญชี</span>
                </td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <div>
                    <span>- จำนวนเงินที่จ่ายยอดก่อนภาษีจำนวน</span>
                    <span style="float: right; font-weight: bold">
                      <!-- {{ invoiceObj.preDiscountAmountTotal | currency :"" : "" }} -->
                      {{ invoiceObj.serviceGrandTotal | currency : "" : "" }}
                      <span style="font-weight: normal"> บาท</span>
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <div>
                    <span
                      >- ภาษีที่หักและนำส่งไว้จำนวน
                      <span class="span-alert">หัก 3%</span></span
                    >
                    <span style="float: right; font-weight: bold"
                      >{{ invoiceObj.salesWHT | currency : "" : ""
                      }}<span style="font-weight: normal"> บาท</span></span
                    >
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <div style="word-break: break-word">
                    <span
                      >หากมีข้อสงสัยหรือต้องการสอบถามเพิ่มเติม สามารถส่งอีเมล
                      หรือติดต่อบัญชี</span
                    >
                  </div>
                </td>
              </tr>
            </table>
          </td>
        </div>
      </tr>
      <tr *ngIf="!invoiceObj.islegalEntity" style="height: 105px"></tr>
      <tr>
        <td>
          <table class="bank-account-info">
            <tr>
              <td colspan="2">
                <strong style="word-break: break-word">
                  รายละเอียดการชำระเงิน
                </strong>
              </td>
            </tr>
            <tr>
              <td>
                <strong>ธนาคาร</strong>
              </td>
              <td>
                <strong>ชื่อบัญชี</strong>
              </td>
              <td>
                <strong>เลขที่บัญชี</strong>
              </td>
            </tr>
            <tr>
              <td>
                <span>กสิกรไทย</span>
              </td>
              <td>
                <span>บริษัท แอคเคาท์เวิร์ค จำกัด</span>
              </td>
              <td>
                <span>006-1-72945-3</span>
              </td>
            </tr>
          </table>
        </td>
        <td>
          <!-- <div class="qr-code">
            <img class="qrcode-image" [src]="qrCodeImage" alt="" srcset="" />
          </div> -->
        </td>
      </tr>
    </td>

    <!-- Slip -->
    <td>
      <table class="invoice-slip-summary-scope">
        <tr>
          <td colspan="4">
            <strong>รวมค่าภาษีประกันสังคม (ฝากจ่าย)</strong>
          </td>
          <td colspan="1">
            <span class="grandTotal-price mb-0">
              {{ invoiceObj.documentGrandTotal | currency : "" : "" }}
            </span>
          </td>
        </tr>

        <tr>
          <td class="blank-row" colspan="4"></td>
          <td class="blank-row"></td>
        </tr>

        <tr>
          <td colspan="4">
            <strong>รวมค่าบริการ (ก่อน VAT)</strong>
          </td>

          <td colspan="1">
            <!-- {{ invoiceObj.preDiscountAmountTotal | currency : "" : "" }} -->
            {{ invoiceObj.serviceGrandTotal | currency : "" : "" }}
          </td>
        </tr>

        <tr>
          <td colspan="4">
            <strong
              ><span style="text-decoration: underline; font-size: larger"
                >บวก</span
              >
              ภาษีมูลค่าเพิ่ม</strong
            >
          </td>
          <td colspan="1">
            <!-- {{ invoiceObj.salesTax | currency : "" : "" }} -->
            {{ invoiceObj.salesTax | currency : "" : "" }}
          </td>
        </tr>

        <tr>
          <td colspan="4">
            <strong
              ><span style="text-decoration: underline; font-size: larger"
                >หัก</span
              >
              ภาษี หัก ณ ที่จ่าย</strong
            >
          </td>

          <td colspan="1">({{ invoiceObj.salesWHT | currency : "" : "" }})</td>
        </tr>

        <tr>
          <td colspan="4">
            <strong
              ><strong class="underline-setting">บวก</strong>เงินขาด/(<strong
                class="underline-setting"
                >หัก</strong
              >เงินเกิน)</strong
            >
          </td>
          <td
            colspan="1"
            [ngClass]="{ setPerens: isNegitive(invoiceObj.creditGrandTotal) }"
          >
            <span>{{
              invoiceObj.creditGrandTotal | negToPos | currency : "" : ""
            }}</span>
          </td>
        </tr>

        <tr>
          <td colspan="4">
            <strong>ค่าบริการสุทธิ</strong>
          </td>
          <td colspan="1">
            <span>
              <!-- {{ invoiceObj.grandTotal | currency : "" : "" }} -->
              {{
                invoiceObj.serviceGrandTotal +
                  (invoiceObj.salesTax - invoiceObj.salesWHT) +
                  invoiceObj.creditGrandTotal | currency : "" : ""
              }}
            </span>
          </td>
        </tr>
        <tr>
          <td class="blank-row" colspan="4"></td>
          <td class="blank-row"></td>
        </tr>

        <tr>
          <td colspan="4">
            <div class="summary-read-price">
              <strong style="font-size: 28px">ยอดจ่ายสุทธิ</strong>
            </div>
          </td>
          <td>
            <!-- <strong>{{ THBgrantotal }}</strong> -->
            <strong style="font-size: 28px" class="grandTotal-price mb-0">
              <!-- {{ invoiceObj.grandTotal | currency : "" : "" }} -->
              {{
                invoiceObj.documentGrandTotal +
                  (invoiceObj.serviceGrandTotal +
                    (invoiceObj.salesTax - invoiceObj.salesWHT) +
                    invoiceObj.creditGrandTotal) | currency : "" : ""
              }}
            </strong>
          </td>
        </tr>
      </table>
    </td>
    <tr style="height: 10px"></tr>
  </table>
</ng-template>

<ng-template #customerTable>
  <div style="margin-top: 30px; margin-bottom: 20px">
    <strong class="customer-self-invoice"
      >รายละเอียดที่ลูกค้าชำระด้วยตนเอง</strong
    >
  </div>

  <div #mainElementBottom>
    <table class="customer-invoice-table" #tableElementBottom>
      <tr class="trc-header-bottom" #trcHeaderElementBottom>
        <th>
          <strong>ลำดับ</strong>
        </th>
        <th>
          <strong>รายการ</strong>
        </th>
        <th>
          <strong>เดือน</strong>
        </th>
        <th>
          <strong>ค่าภาษี / บริการ</strong>
        </th>
        <th>
          <strong>จำนวนค่าปรับ</strong>
        </th>
        <th>
          <strong>ราคาสุทธิ</strong>
        </th>
      </tr>

      <tr
        *ngFor="let item of invoiceObj.customerSubmitItem; index as ind"
        #itemListElementsBottom
        class="trc-bottom"
      >
        <td>{{ ind + 1 }}</td>
        <td>{{ item.itemName }}<br />{{ item.description[0].description }}</td>
        <td>
          {{
            invoiceObj
              ? months[invoiceObj.monthId - 1]
              : ("-" | thaidate : "MMMM")
          }}
        </td>
        <td>{{ item.unitPrice | currency : "" : "" }}</td>
        <td>{{ item.fineAmount | currency : "" : "" }}</td>
        <td>{{ item.grandTotal | currency : "" : "" }}</td>
      </tr>
      <tr class="dummy-row" *ngIf="invoiceObj.customerSubmitItem == 0">
        <td>1</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>

      <tr>
        <td style="height: 34px" colspan="5">
          <strong style="font-size: 24px; vertical-align: text-bottom">
            จำนวนเงินรวมทั้งสิ้น (บาท)
          </strong>
        </td>
        <td style="text-align: right">
          <strong>{{ customerGrandTotal | currency : "" : "" }}</strong>
        </td>
      </tr>
    </table>
  </div>
</ng-template>
