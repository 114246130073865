<div class="card p-0">
    <div class="timeline-scope">
      <div class="timeline-me" style="translate: 3.5rem;">
        <div
          class="timeline-item"
          *ngFor="let item of timelineItems; let i = index"
        >
          <div class="timeline-name">
            <div
              [class.active]="i === currentStep"
              [class.active-danger]="i === currentStep && currentStep >= 4"
              [class.disabled]="isDisabledStatus(i)"
              class="timeline-button"
              >
              <span
                class="d-none"
                [ngClass]="{
                  'd-block': i < currentStep
                }"
              >
                <i  data-feather="check"></i>
              </span>
              <span
                [ngClass]="{
                  'd-none': i < currentStep
                }"
              >
                {{ i + 1 }}
              </span>
            </div>
            <div
              [ngClass]="{
                'd-none': i >= timelineItems.length - 3
              }"
              class="line_of_time"
              [class.line-active]="i < currentStep"
              [class.line-disabled]="isDisabledLine(i)"
            ></div>
          </div>
          <div class="timeline-lable">
            <b>{{ item.label }}</b>
            <span>
              {{ item.descript }}
            </span>
          </div>
  
          <!-- <div class="timeline-name">
            <div 
              [class.active]="i === currentStep" 
              [class.disabled]="i < currentStep" 
              class="timeline-button"
            >
              <span class="d-none" [ngClass]="{
                  'd-block': i < currentStep
                }">
                <i data-feather="check"></i>
              </span>
              <span [ngClass]="{
                  'd-none': i < currentStep
                }">
                {{ i + 1 }}
              </span>
            </div>
            <div [ngClass]="{
                'd-none': i >= (timelineItems.length - 1 | number)
              }" class="line_of_time" [class.line-active]="
                currentStep <= (timelineItems.length - 1 | number)
              " [class.line-disabled]="i > currentStep - 1"></div>
          </div>
          <div class="timeline-lable">
            <b>{{ item.label }}</b>
            <p [ngClass]="{
                'd-block': i == currentStep
              }">
              <span>
                {{ item.descript }}
              </span>
            </p>
          </div> -->
        
        </div>
        
        <!-- Cancel Status -->
        <!-- <div class="timeline-item">
          <div 
          class="timeline-button"
          [class.active-danger]="currentStepNumber == 5"
          >
            <span class="d-block">
              <i data-feather="edit-3"></i>
            </span>
            <span class="d-none">
              <i data-feather="edit-2"></i>
            </span>
          </div>
          <div class="timeline-lable">
            <b>{{ lastItems.label }}</b>
            <p class="d-block">
              <span>
                {{ lastItems.descript }}
              </span>
            </p>
          </div>
        </div>
   -->
        <!-- <div class="timeline-item">
          <div [class.active-danger]="currentStep >= (timelineItems.length | number)"
            [class.disabled]="currentStep > (timelineItems.length | number)" class="timeline-button">
            <span class="d-none" [ngClass]="{
                'd-block': currentStep >= (timelineItems.length - 1 | number)
              }">
              <i data-feather="edit-3"></i>
            </span>
            <span [ngClass]="{
                'd-none': currentStep >= (timelineItems.length - 1 | number)
              }">
              <i data-feather="edit-2"></i>
            </span>
          </div>
          <div class="timeline-lable">
            <b>{{ lastItems.label }}</b>
            <p [ngClass]="{
                'd-block': currentStep >= (timelineItems.length | number)
              }">
              <span>
                {{ lastItems.descript }}
              </span>
            </p>
          </div>
        </div> -->
      </div>
    </div>
  </div>
  
