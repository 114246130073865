import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from '../modal/modal.component';
import { User } from 'app/auth/models';
import { Router } from '@angular/router';
import { DocumentService } from 'service/document.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApiService } from 'service/api.service';

@Component({
  selector: 'app-period-select-modal',
  templateUrl: './period-select-modal.component.html',
  styleUrls: ['./period-select-modal.component.scss'],
})
export class PeriodSelectModalComponent implements OnInit {
  componentName: string;
  componentHeader: object;

  isSuperAdmin: boolean;
  user: User;
  selectPeriodNumber: number;

  isLoading: boolean;
  itemList = [];

  @Input() customerId: string;
  @Input() month: number;
  @Input() year: number;

  form: FormGroup;

  constructor(
    private _modalService: NgbModal,
    private router: Router,
    private documentService: DocumentService,
    private fb: FormBuilder,
    private apiService: ApiService
  ) {
    const token = JSON.parse(localStorage.getItem('token'))
    this.documentService.SetHttpHeaders(token)
    this.form = fb.group({
      selectedDoc: [''],
    });
  }

  getCustomerDocumentByPeriod() {
    this.isLoading = true
    this.apiService
      .GetAllData(`documenttiming/${this.customerId}/GetDropdownDocumentTimingPeriod?Year=${this.year}&Month=${this.month}`)
      .subscribe((res) => {
        this.isLoading = false
        this.itemList = res.data.resultData;
        console.log(this.itemList)
      });
  }

  closeModal() {
    this._modalService.dismissAll();
  }

  Submit(): void {
    this.closeModal();
    this.router.navigate([
      `atms/task-document/view-document/${this.form.controls.selectedDoc.value}`,
    ]);
  }

  ngOnInit(): void {
    this.getCustomerDocumentByPeriod();
  }
}
