<div class="row">
  <div class="col-12">
    <div class="card card-rounded px-4">
      <div class="card-body pt-0">
        <div class="row justify-content-between">
          <div class="col-lg-5 col-md-6 col-12">
            <div class="row">
              <div class="col-12 mb-2">
                <img
                  src="../../../../../assets/images/logo/accountwork.png"
                  alt=""
                  class="w-50"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3 justify-content-between">
          <div class="col-5">
            <label
              *ngIf="customerDetail.isHeadBranch == true"
              for="companyName"
              class="text-sub-document mb-1"
              >ชื่อบริษัท</label
            >

            <p
              *ngIf="customerDetail.isHeadBranch == true"
              class="mb-3 text-sub-document"
            >
              {{ customerDetail?.businessName }}
              <span *ngIf="customerDetail.islegalEntity == true"
                >(สำนักงานใหญ่)</span
              >
            </p>

            <label
              *ngIf="customerDetail.isHeadBranch == false"
              for="companyName"
              class="text-sub-document mb-1"
              >ชื่อบริษัท</label
            >
            <p
              *ngIf="customerDetail.isHeadBranch == false"
              class="mb-3 text-sub-document"
            >
              {{ customerDetail?.businessName }} (สาขา
              {{ customerDetail.branchNumber.toString().padStart(5, "0") }})
            </p>

            <label for="" class="text-sub-document mb-1">ที่อยู่บริษัท</label>
            <p class="mb-3 text-sub-document">
              {{ GetAddressTemplate(customerDetail?.customerAddress) }}
            </p>
          </div>
          <div class="col-6">
            <div class="row">
              <div class="col-6 mb-3">
                <label class="text-sub-document">Customer Code</label>
              </div>
              <div class="col-6 mb-3">
                <p class="text-sub-document">
                  {{ customerDetail?.customerCode }}
                </p>
              </div>
              <div class="col-6 mb-3">
                <label for="" class="text-sub-document">TAX ID</label>
              </div>
              <div class="col-6 mb-3">
                <p class="text-sub-document">{{ customerDetail?.taxId }}</p>
              </div>
              <div class="col-6 mb-3">
                <label class="text-sub-document"
                  >Credit <span class="text-underline">บวก</span>เงินขาด/(<span
                    class="text-bold text-underline"
                    >หัก</span
                  >เงินเกิน)</label
                >
              </div>
              <div class="col-6 mb-3">
                <p class="text-color-main" style="font-size: 18px">
                  {{ creditMovement | number : "1.2-2" }}
                </p>
              </div>
              <div class="col-6 mb-3">
                <label class="text-sub-document">Credit (ภพ 30)</label>
              </div>
              <div class="col-6 mb-3">
                <p class="text-color-red" style="font-size: 18px">
                  {{ creditPP30 | number : "1.2-2" }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row justify-content-between align-items-center mb-2">
      <div class="col">
        <button
          type="button"
          class="btn btn-success w-25"
          rippleEffect
          (click)="onAddClicked(dialogAdd)"
          [disabled]="!customerDetail.isCreatable"
        >
          เพิ่ม
        </button>
      </div>
      <div class="col-2 d-flex justify-content-end">
        <a (click)="onDownloadFile('', '')">
          <img
            src="../../../../../assets/images/icons/button-icons/Download_Icon.svg"
            alt="Download"
            class="w-100"
          />
        </a>
      </div>
    </div>

    <div class="card">
      <div class="card-body p-0">
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th class="text-center" scope="col">รอบที่</th>
                <th class="text-center" scope="col">เดือน</th>
                <th class="text-center" scope="col">สถานะเอกสาร</th>
                <th
                  class="text-center"
                  scope="col"
                  *ngFor="let header of tableHeader"
                >
                  {{ header.itemName }}
                </th>

                <th class="text-center" scope="col">ค่าบริการ</th>
                <th class="text-center" scope="col">รวมภาษี</th>
                <th class="text-center" scope="col">ค่าปรับ</th>
                <th class="text-center" scope="col">รวม</th>
                <th class="text-center" scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of tableListData; let i = index">
                <td class="text-center">
                  <a (click)="onStatusClicked(data.documentTimingId)">{{
                    data.periodNumber
                  }}</a>
                </td>
                <td class="text-center">
                  <a (click)="onStatusClicked(data.documentTimingId)">
                    {{ getMonthName(data.monthNumber) }}</a
                  >
                </td>
                <td
                  class="text-center table-border-right"
                  [ngClass]="{
                    'statusColor-black': data.myWorksStatusId == 1,
                        'statusColor-gray':
                          data.myWorksStatusId == 32768 ||
                          data.myWorksStatusId == 65536 ||
                          data.myWorksStatusId == 131072,
                        'statusColor-red':
                          data.myWorksStatusId == 16 ||
                          data.myWorksStatusId == 32 ||
                          data.myWorksStatusId == 8192 ||
                          data.myWorksStatusId == 16384,
                        'statusColor-yellow':
                          data.myWorksStatusId == 2 ||
                          data.myWorksStatusId == 1024,
                        'statusColor-green-2':
                        data.myWorksStatusId == 128,
                        'statusColor-green':
                          data.myWorksStatusId == 8 ||
                          data.myWorksStatusId == 2048 ||
                          data.myWorksStatusId == 262144,
                        'statusColor-blue':
                          data.myWorksStatusId == 4 ||
                          data.myWorksStatusId == 4096
                  }"
                >
                  <a (click)="onStatusClicked(data.documentTimingId)">
                    {{ data.myWorksStatusTh }}</a
                  >
                </td>
                <!-- ภ.พ.36 -->
                <td
                  class="text-right table-border-right"
                  style="min-width: 180px"
                  *ngFor="let item of data.documentValueList"
                >
                  <div ngbTooltip="{{ item.remarks ? item.remarks : '' }}">
                    <div *ngIf="item.paymentType === 0">
                      <div class="text-primary d-flex justify-content-end">
                        <div
                          class="text-success mr-1"
                          *ngIf="item.documentStateEnum == 1"
                        >
                          <i data-feather="check-square" [size]="18"></i>
                        </div>
                        <div
                          class="text-danger mr-1"
                          *ngIf="item.documentStateEnum == 2"
                        >
                          <i data-feather="x" [size]="18"></i>
                        </div>
                        <div class="text-danger mr-1" *ngIf="item.isOverDue">
                          <i data-feather="alert-circle" [size]="18"></i>
                        </div>
                        {{ item.price }}
                      </div>
                    </div>
                    <div *ngIf="item.paymentType === 1">
                      <div class="text-success d-flex justify-content-end">
                        <div
                          class="text-success mr-1"
                          *ngIf="item.documentStateEnum == 1"
                        >
                          <i data-feather="check-square" [size]="18"></i>
                        </div>
                        <div
                          class="text-danger mr-1"
                          *ngIf="item.documentStateEnum == 2"
                        >
                          <i data-feather="x" [size]="18"></i>
                        </div>
                        <div class="text-danger mr-1" *ngIf="item.isOverDue">
                          <i data-feather="alert-circle" [size]="18"></i>
                        </div>
                        {{ item.price }}
                      </div>
                    </div>
                    <div
                      *ngIf="item.paymentType !== 1 && item.paymentType !== 0"
                    >
                      <div class="d-flex justify-content-end">
                        <div
                          class="text-success mr-1"
                          *ngIf="item.documentStateEnum == 1"
                        >
                          <i data-feather="check-square" [size]="18"></i>
                        </div>
                        <div
                          class="text-danger mr-1"
                          *ngIf="item.documentStateEnum == 2"
                        >
                          <i data-feather="x" [size]="18"></i>
                        </div>
                        <div class="text-danger mr-1" *ngIf="item.isOverDue">
                          <i data-feather="alert-circle" [size]="18"></i>
                        </div>
                        {{ item.price }}
                      </div>
                    </div>

                    <hr />

                    <div *ngIf="item.paymentType === 0">
                      <div class="text-pink d-flex justify-content-end">
                        <div
                          class="text-success mr-1"
                          *ngIf="item.documentStateEnum == 1"
                        >
                          <i data-feather="check-square" [size]="18"></i>
                        </div>
                        <div
                          class="text-danger mr-1"
                          *ngIf="item.documentStateEnum == 2"
                        >
                          <i data-feather="x" [size]="18"></i>
                        </div>
                        <div class="text-danger mr-1" *ngIf="item.isOverDue">
                          <i data-feather="alert-circle" [size]="18"></i>
                        </div>
                        {{ item.fineAmount }}
                      </div>
                    </div>
                    <div *ngIf="item.paymentType === 1">
                      <div class="text-danger d-flex justify-content-end">
                        <div
                          class="text-success mr-1"
                          *ngIf="item.documentStateEnum == 1"
                        >
                          <i data-feather="check-square" [size]="18"></i>
                        </div>
                        <div
                          class="text-danger mr-1"
                          *ngIf="item.documentStateEnum == 2"
                        >
                          <i data-feather="x" [size]="18"></i>
                        </div>
                        <div class="text-danger mr-1" *ngIf="item.isOverDue">
                          <i data-feather="alert-circle" [size]="18"></i>
                        </div>
                        {{ item.fineAmount }}
                      </div>
                    </div>
                    <div
                      *ngIf="item.paymentType !== 1 && item.paymentType !== 0"
                    >
                      <div class="d-flex justify-content-end">
                        <div
                          class="text-success mr-1"
                          *ngIf="item.documentStateEnum == 1"
                        >
                          <i data-feather="check-square" [size]="18"></i>
                        </div>
                        <div
                          class="text-danger mr-1"
                          *ngIf="item.documentStateEnum == 2"
                        >
                          <i data-feather="x" [size]="18"></i>
                        </div>
                        <div class="text-danger mr-1" *ngIf="item.isOverDue">
                          <i data-feather="alert-circle" [size]="18"></i>
                        </div>
                        {{ item.fineAmount }}
                      </div>
                    </div>
                  </div>
                </td>

                <td
                  class="text-right table-border-right"
                  style="min-width: 180px"
                >
                  <div class="d-flex justify-content-end">
                    <div
                      class="text-success mr-1"
                      *ngIf="data.isServiceMarkClear"
                    >
                      <i data-feather="check-square" [size]="18"></i>
                    </div>
                    <div class="text-danger mr-1" *ngIf="data.isServiceReject">
                      <i data-feather="x" [size]="18"></i>
                    </div>
                    <div class="text-danger mr-1" *ngIf="data.isServiceOverDue">
                      <i data-feather="alert-circle" [size]="18"></i>
                    </div>
                    {{ data.summationOfPriceService }}
                  </div>
                </td>
                <td
                  class="text-right table-border-right"
                  style="min-width: 180px"
                >
                  <div class="d-flex justify-content-end">
                    <div
                      class="text-success mr-1"
                      *ngIf="data.isPriceMarkClear"
                    >
                      <i data-feather="check-square" [size]="18"></i>
                    </div>
                    <div class="text-danger mr-1" *ngIf="data.isPriceReject">
                      <i data-feather="x" [size]="18"></i>
                    </div>
                    <div class="text-danger mr-1" *ngIf="data.isPriceOverDue">
                      <i data-feather="alert-circle" [size]="18"></i>
                    </div>
                    {{ data.summationOfPrice }}
                  </div>
                </td>
                <td
                  class="text-right table-border-right"
                  style="min-width: 180px"
                >
                  <div class="d-flex justify-content-end">
                    <div class="text-success mr-1" *ngIf="data.isFineMarkClear">
                      <i data-feather="check-square" [size]="18"></i>
                    </div>
                    <div class="text-danger mr-1" *ngIf="data.isFineReject">
                      <i data-feather="x" [size]="18"></i>
                    </div>
                    <div class="text-danger mr-1" *ngIf="data.isFineOverDue">
                      <i data-feather="alert-circle" [size]="18"></i>
                    </div>
                    {{ data.summationOfFine }}
                  </div>
                </td>
                <td class="text-right table-border-right">
                  {{ data.totalPrice }}
                </td>
                <td>
                  <button
                    *ngIf="!data.canCloseJob"
                    class="new-document-btn disabled btn"
                  >
                    ปิดงาน
                  </button>
                  <button
                    *ngIf="data.canCloseJob"
                    class="new-document-btn"
                    (click)="markerJob(data.documentTimingId)"
                  >
                    ปิดงาน
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Dialog -->
<ng-template #dialogAdd let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Account Task Management System</h4>
  </div>
  <div class="modal-body d-flex justify-content-center">
    <div class="row justify-content-center">
      <div class="col-12 text-center my-3">
        <label class="text-color-main">กรุณาเลือกวันที่ต้องการยื่นเอกสาร</label>
      </div>
      <div class="col-8 mb-5">
        <input
          type="text"
          [bsConfig]="dateOptions"
          [(ngModel)]="selectedDate"
          placeholder="DD/MM/YYYY"
          class="form-control"
          bsDatepicker
        />
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-danger"
      (click)="modal.dismiss('Cancel')"
    >
      ยกเลิก
    </button>
    <button
      type="button"
      class="btn btn-primary"
      (click)="modal.close('Confirm'); AddDocumentTiming()"
    >
      ยืนยัน
    </button>
  </div>
</ng-template>
