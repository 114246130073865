<div *ngIf="isPermanentMode">
  <div class="modal-header text-center">
    <h4 class="modal-title w-100">Account Task Management System</h4>
  </div>
  <div class="modal-body d-flex justify-content-center">
    <div class="row justify-content-center">
      <div class="col-12 text-center my-3">
        <h4 class="text-color-main">โอนงานแบบถาวร</h4>
        <p>กรุณาเลือกผู้รับโอนงาน</p>
        <p class="text-danger" *ngIf="showCheckDialog">***{{ checkTransferDialog }}***</p>
      </div>
      <div class="col-12 mb-5">
        <ng-select style="min-width: 350px" [(ngModel)]="selectedEmployee" (ngModelChange)="onSelectChanged()">
          <ng-option *ngFor="let employee of employeeList" [value]="employee">
            {{
            employee.firstName +
            " " +
            employee.lastName +
            " " +
            " (" +
            employee.teamTh +
            ")"
            }}
          </ng-option>
        </ng-select>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="closeModal()">
      ยกเลิก
    </button>
    <button type="button" [disabled]="selectedEmployee == null" class="btn btn-primary" (click)="submit()">
      ยืนยัน
    </button>
  </div>
</div>

<div *ngIf="!isPermanentMode && !this.data.isFullPeriodModel">
  <div class="modal-header text-center">
    <h4 class="modal-title w-100">Account Task Management System</h4>
  </div>
  <div class="modal-body d-flex justify-content-center">
    <div class="row justify-content-center">
      <div class="col-12 text-center my-3">
        <h3>โอนงานแบบ{{ isPermanentMode ? "ถาวร" : "ชั่วคราว" }}</h3>
        <h4>ลูกค้า: {{ data.customerName }}</h4>
        <h4>เดือน: {{ data.monthNameTh }} รอบที่: {{ data.periodNumber }}</h4>
      </div>
      <div class="col-12 text-center my-3">
        <label class="text-color-main">กรุณาเลือกผู้รับโอนงาน</label>
      </div>
      <div class="col-12 mb-5">
        <ng-select style="min-width: 350px" [(ngModel)]="selectedEmployee" (ngModelChange)="onSelectChanged()">
          <ng-option *ngFor="let employee of employeeList" [value]="employee">
            {{
            employee.firstName +
            " " +
            employee.lastName +
            " " +
            " (" +
            employee.teamTh +
            ")"
            }}
          </ng-option>
        </ng-select>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="closeModal()">
      ยกเลิก
    </button>
    <button type="button" [disabled]="selectedEmployee == null" class="btn btn-primary" (click)="submit()">
      ยืนยัน
    </button>
  </div>
</div>

<div *ngIf="!isPermanentMode && this.data.isFullPeriodModel">
  <div class="modal-header text-center">
    <h4 class="modal-title w-100">Account Task Management System</h4>
  </div>
  <div class="modal-body d-flex justify-content-center">
    <div class="row justify-content-center">
      <div class="col-12 text-center my-3">
        <h3>โอนงานแบบ{{ isPermanentMode ? "ถาวร" : "ชั่วคราว" }}</h3>
        <h4>ลูกค้า: {{ data.customerName }}</h4>
      </div>
      <div class="col-12 mb-5">
        <ng-container *ngIf="isLoading">
          <div class="d-flex justify-content-center">
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="!isLoading && !viewByInvoice">
          <div class="col-12">
            <label class="text-color-main d-flex justify-content-center">กรุณาเลือกรอบงาน</label>
          </div>
          <form [formGroup]="form">
            <div *ngFor="let i of periodList; index as ind" class="text-center mb-1">
              <div class="d-flex align-items-center justify-content-center">
                <div class="custom-control custom-radio">
                  <fieldset [disabled]="!i.isTransfer">
                    <input type="radio" id="{{ i.id }}" [value]="i.id" formControlName="selectedDoc"
                      (change)="onChangeSelectedPeriod(i)" class="custom-control-input" />
                    <label class="custom-control-label" for="{{ i.id }}" style="font-size: 18px">
                      {{ i.monthNameTh }} รอบที่ {{ i.period }}
                    </label>
                  </fieldset>
                </div>
              </div>
            </div>
          </form>
        </ng-container>

        <ng-container *ngIf="!isLoading && viewByInvoice">
          <h3 class="text-center">ลูกค้า: {{ data.customerName }}</h3>

          <h3 class="text-center">หมายเลขเอกสาร: {{ data.invoiceCode }}</h3>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="closeModal()">
      ยกเลิก
    </button>
    <button type="button" class="btn btn-primary" [disabled]="form.controls.selectedDoc.value == '' && !viewByInvoice"
      (click)="openModal(dialogTransferStep2)">
      ยืนยัน
    </button>
  </div>
</div>

<ng-template #dialogTransferStep2 let-modal>
  <div class="modal-header text-center">
    <h4 class="modal-title w-100">Account Task Management System</h4>
  </div>
  <div class="modal-body d-flex justify-content-center">
    <div class="row justify-content-center">
      <div class="col-12 text-center my-3">
        <h3>โอนงานแบบ{{ isPermanentMode ? "ถาวร" : "ชั่วคราว" }}</h3>
        <h4>ลูกค้า: {{ data.customerName }}</h4>
        <h4>
          เดือน: {{ data.monthNameTh }} รอบที่: {{ selectedPeriodNumber }}
        </h4>
      </div>
      <div class="col-12 text-center my-1">
        <label class="text-color-main">กรุณาเลือกผู้รับโอนงาน</label>
      </div>
      <div class="col-12 mb-3">
        <ng-select style="min-width: 350px" [(ngModel)]="selectedEmployee" (ngModelChange)="onSelectChanged()">
          <ng-option *ngFor="let employee of employeeList" [value]="employee">
            {{
            employee.firstName +
            " " +
            employee.lastName +
            " (" +
            employee.teamTh +
            ")"
            }}
          </ng-option>
        </ng-select>
      </div>
    </div>
  </div>
  <div class="modal-body d-flex justify-content-center">
    <div class="row justify-content-center w-100">
      <div class="col-12 text-center my-1">
        <label class="text-color-main">Remark</label>
      </div>
      <div class="col-12 mb-3">
        <div class="row justify-content-center">
          <div class="form-group w-100">
            <textarea [(ngModel)]="remark" class="form-control" id="textArea" rows="5" style="resize: none">
            </textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss(cancel)">
      ยกเลิก
    </button>
    <button type="button" class="btn btn-primary" (click)="submit()" (click)="modal.close(complete)">
      ยืนยัน
    </button>
  </div>
</ng-template>