import { CustomerAddress, CustomerAddressModel } from 'app/auth/models/customer';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { CustomerDetails } from '../app/auth/models/customer';
import { switchMap } from 'rxjs/operators';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  private apiBaseUrl: string = `${environment.apiUrl}/api`;
  private httpHeaders: HttpHeaders;
  customerCode: string;

  constructor(private http: HttpClient, private apiService: ApiService) {}
  
  get API_URL() {
    return this.apiBaseUrl;
  }

  get API_HEADERS() {
    return this.httpHeaders;
  }

  SetHttpHeaders(token): void {
    this.httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`);
  }

  getCustomerByEmployeeOwnedId(employeeOwnedId: string) : Observable<any>{
    return this.http.get(`${this.apiBaseUrl}/customer?EmployeeOwnedId=${employeeOwnedId}`, {headers : this.httpHeaders});
  }

  getCustomerDetail(customerID: string) : Observable<any>{
    return this.http.get(`${this.apiBaseUrl}/customer/${customerID}`, { headers : this.httpHeaders });
  }

  getCustomerAddress(customerID: string) : Observable<any>{
    let params = new HttpParams()
    .set('CustomerId', customerID)
    .set('IsActive', 'true')
    .set('IsDelete', 'false');
    return this.http.get(`${this.apiBaseUrl}/customerAddress`, { params: params, headers: this.httpHeaders });
  }

  getBusinessType() : Observable<any>{
    return this.http.get(`${this.apiBaseUrl}/BusinessType`, { headers : this.httpHeaders } );
  }
  getEmployee() : Observable<any>{
    return this.http.get(`${this.apiBaseUrl}/employee`, { headers : this.httpHeaders } );
  }
  
  createCustomerAddress(CustomerAddress: CustomerAddressModel) : Observable<any>{
    return this.http.post(`${this.apiBaseUrl}/customerAddress`, CustomerAddress , { headers : this.httpHeaders } );
  }

  updateCustomerAddress(customerAddressID: any, CustomerAddress: CustomerAddressModel) : Observable<any>{
    const url = `${this.apiBaseUrl}/customerAddress/${customerAddressID}`;
    return this.http.put(url, CustomerAddress , { headers : this.httpHeaders } );
  }

  createCustomer(customer : CustomerDetails) : Observable<any>{
    const url = `${this.apiBaseUrl}/customer`; 
    return this.http.post<any>(url, customer, { headers : this.httpHeaders });
  }

  editCustomer(customerID: string, customer : CustomerDetails) : Observable<any>{
    const url = `${this.apiBaseUrl}/customer/${customerID}`; 
    return this.http.put<any>(url, customer, { headers : this.httpHeaders });
  }

  activeCustomer(customerID: string, isActive: boolean) : Observable<any>{
    const url = `${this.apiBaseUrl}/customer/${customerID}/active/${isActive}`; 
    return this.http.patch<any>(url, null, { headers : this.httpHeaders });
  }

  getCustomerList(page: number, pageLength: number) : Observable<any>{
    const url = `${this.apiBaseUrl}/customer?Page=${page}&PageLength=${pageLength}`; 
    return this.http.get<any>(url, { headers : this.httpHeaders });
  }

  getSearchCustomerList(page: number, pageLength: number, businessName: string | null, customerStatus: number) : Observable<any>{
    // Construct the URL using URLSearchParams
    // const url = new URL(`${environment.apiUrl}/api/customer`);
    let url = `${environment.apiUrl}/api/customer?Page=${page}&PageLength=${pageLength}&SortPath=businessName`;
    if(businessName != null && businessName != ''){
      url += `&BusinessName=${businessName}`;
    }
    if(customerStatus == 2){
      url += `&IsActive=true`;
    } else if(customerStatus == 3){
      url += `&IsActive=false`;
    }

    return this.http.get<any>(url, { headers : this.httpHeaders });
  }

  getCustomerPackage(customerID: string) : Observable<any>{
    const url = `${this.apiBaseUrl}/customer/${customerID}/Package`;
    return this.http.get<any>(url, { headers : this.httpHeaders });
  }

  updateCustomerPackage(customerID: string, model : any) : Observable<any>{
    const url = `${this.apiBaseUrl}/customer/${customerID}/Package`;
    return this.http.put<any>(url, model, { headers : this.httpHeaders });
  }

  getCustomerItemPackage() : Observable<any>{
    return this.http.get<any>(`${this.apiBaseUrl}/item?GroupId=3`, { headers : this.httpHeaders })
  }

  getCustomerListByActive() : Observable<any>{
    const url = `${this.apiBaseUrl}/customer?IsActive=true`;
    return this.http.get<any>(url, { headers : this.httpHeaders });
  }

  getCreditListByCustomerID(customerID: string) : Observable<any>{
    const url = `${this.apiBaseUrl}/credit/Credit/Customer/${customerID}`;
    return this.http.get<any>(url, { headers : this.httpHeaders });
  }

  getPP30ListByCustomerID(customerID: string) : Observable<any>{
    const url = `${this.apiBaseUrl}/creditpp30/CreditPP30/Customer/${customerID}`; 
    return this.http.get<any>(url, { headers : this.httpHeaders });
  }

  chectPermanentJobTransfer(customerId: string): Observable<any> {
    const url = `${this.apiBaseUrl}/WorkTransfer/PermanentJobTransfer/Customer/${customerId}/check`;
    return this.http.get<any>(url, { headers : this.httpHeaders });
  }
}
