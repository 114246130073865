
<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <!-- Basic Alerts start -->
        <section>
            <!-- <div class="size-page">
                <div class="invoice-container">
                    <div class="header">
                        <div class="company-info">
                            <h2>Your Company Name</h2>
                            <p>123 Main Street, Cityville, USA</p>
                            <p>Email: info@yourcompany.com</p>
                            <p>Phone: (123) 456-7890</p>
                        </div>
            
                        <div class="logo">
                            <img src="../../../../assets/images/logo/logo_invoice.png" alt="" class="w-100">
                        </div>
                    </div>
            
                    <div class="invoice-details">
                        <div class="from">
                            <h2>From:</h2>
                        </div>
                        <div class="to">
                            <h2>To:</h2>
                        </div>
                        <div class="invoice-info">
                            <p>Invoice #12345</p>
                            <p>Date: January 1, 2023</p>
                        </div>
                    </div>
            
                    <div class="item-list">
                        <table>
                            <thead>
                                <tr>
                                    <th>Item</th>
                                    <th>Description</th>
                                    <th>Quantity</th>
                                    <th>Price</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Item 1</td>
                                    <td>Description of Item 1</td>
                                    <td>2</td>
                                    <td>$10.00</td>
                                    <td>$20.00</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
            
                    <div class="totals">
                        <div class="subtotal">
                            <p>Subtotal: $20.00</p>
                        </div>
                        <div class="tax">
                            <p>Tax (10%): $2.00</p>
                        </div>
                        <div class="total">
                            <h2>Total: $22.00</h2>
                        </div>
                    </div>
                </div>
            </div> -->

            <div class="print-button">
                <button (click)="printInvoice()" class="mr-1">Print Preview</button>
                <button (click)="openModal()" class="mr-1">Open transfer modal</button>
                <button (click)="openConfirmModal()" class="mr-1">Open confirm modal</button>
                <button (click)="openCompleteModal()" class="mr-1">Open complete modal</button>
                <button (click)="openCancelModal()" class="mr-1">Open cancel modal</button>
                <button (click)="openDocSubModal()" class="mr-1">Open document submit date modal</button>
                <button (click)="openDocExModal()" class="mr-1">Open document export date modal</button>
                <button (click)="openCheckboxModal()" class="mr-1">Open checkbox modal</button>
                <button (click)="openTextAreaModal()" class="mr-1">Open text area modal</button>
            </div>
        </section>
        <!-- Basic Alerts end -->
    </div>
</div>


<app-transfer-task #transferTask></app-transfer-task>
<app-confirm-modal #confirmModal></app-confirm-modal>
<app-complete-modal #completeModal></app-complete-modal>
<app-cancel-modal #cancelModal></app-cancel-modal>
<app-document-submit-date-modal #documentSubmitDateModal></app-document-submit-date-modal>
<app-document-export-date-modal #documentExportDateModal></app-document-export-date-modal>
<app-checkbox-form-modal #checkboxFormModal></app-checkbox-form-modal>
<app-text-area-form-modal #textAreaFormModal></app-text-area-form-modal>