import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isNullOrUndefined } from '@swimlane/ngx-datatable';
import { environment } from 'environments/environment';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private apiBaseUrl: string = `${environment.apiUrl}/api`;
  private apiPath: string;
  private httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {}

  get API_URL() {
    return this.apiBaseUrl;
  }

  get API_HEADERS() {
    return this.httpHeaders;
  }

  // {
  //   headers: this.httpHeaders,
  // }

  SetHttpHeaders(token): void {
    this.httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`);
  }

  GetAllData(
    apiPath: string,
    params?: { [key: string]: any }
  ): Observable<any> {
    if (params) {
      let httpParams = new HttpParams();

      // Loop through the params object and add each key-value pair to the HttpParams
      Object.keys(params).forEach((key) => {
        httpParams = httpParams.set(key, params[key]);
      });

      return this.http.get(`${this.apiBaseUrl}/${apiPath}`, {
        headers: this.httpHeaders,
        params: params,
      });
    }

    return this.http.get(`${this.apiBaseUrl}/${apiPath}`, {
      headers: this.httpHeaders,
    });
  }

  GetDataById(
    apiPath: string,
    id: string,
    params?: { [key: string]: any }
  ): Observable<any> {
    if (params) {
      let httpParams = new HttpParams();

      // Loop through the params object and add each key-value pair to the HttpParams
      Object.keys(params).forEach((key) => {
        httpParams = httpParams.set(key, params[key]);
      });

      return this.http.get(`${this.apiBaseUrl}/${apiPath}/${id}`, {
        headers: this.httpHeaders,
        params: params,
      });
    }

    return this.http.get(`${this.apiBaseUrl}/${apiPath}/${id}`, {
      headers: this.httpHeaders,
    });
  }

  AddData(apiPath: string, body: any): Observable<any> {
    return this.http.post(`${this.apiBaseUrl}/${apiPath}`, body, {
      headers: this.httpHeaders,
    });
  }

  UpdateWithOutId(apiPath: string, body: any): Observable<any> {
    return this.http.put(`${this.apiBaseUrl}/${apiPath}/`, body, {
      headers: this.httpHeaders,
    });
  }

  UpdateDataById(apiPath: string, id: string, body: any): Observable<any> {
    return this.http.put(`${this.apiBaseUrl}/${apiPath}/${id}`, body, {
      headers: this.httpHeaders,
    });
  }

  DeleleDataById(apiPath: string, id: string): Observable<any> {
    return this.http.delete(`${this.apiBaseUrl}/${apiPath}/${id}`, {
      headers: this.httpHeaders,
    });
  }

  Get(path: string): Observable<any> {
    return this.http.get(`${this.apiBaseUrl}/${path}`, {
      headers: this.httpHeaders,
    });
  }

  GetBlob(path: string, params?: { [key: string]: any }): Observable<any> {
    if (params) {
      let httpParams = new HttpParams();

      // Loop through the params object and add each key-value pair to the HttpParams
      Object.keys(params).forEach((key) => {
        httpParams = httpParams.set(key, params[key]);
      });
    }

    return this.http.get(`${this.apiBaseUrl}/${path}`, {
      headers: this.httpHeaders,
      params: params,
      responseType: 'blob',
    });
  }

  Put(path: string, body: any): Observable<any> {
    return this.http.put(`${this.apiBaseUrl}/${path}`, body, {
      headers: this.httpHeaders,
    });
  }

  Post(path: string, body: any): Observable<any> {
    return this.http.post(`${this.apiBaseUrl}/${path}`, body, {
      headers: this.httpHeaders,
    });
  }

  Patch(path: string, body?: any): Observable<any> {
    return this.http.patch(`${this.apiBaseUrl}/${path}`, body, {
      headers: this.httpHeaders,
    });
  }

  Delete(path: string, body: any): Observable<any> {
    return this.http.delete(`${this.apiBaseUrl}/${path}`, {
      headers: this.httpHeaders,
    });
  }

  SetIsActiveData(
    apiPath: string,
    id: string,
    active: boolean
  ): Observable<any> {
    if (isNullOrUndefined(active)) {
      active = false;
    }
    return this.http.patch(
      `${this.apiBaseUrl}/${apiPath}/${id}/active/${active}`,
      null,
      {
        headers: this.httpHeaders,
      }
    );
  }

  SetIsDefault(apiPath: string, id: string): Observable<any> {
    return this.http.patch(`${this.apiBaseUrl}/${apiPath}/${id}/default/true`, {
      headers: this.httpHeaders,
    });
  }

  SetIsShowing(apiPath: string, id: string, active: boolean): Observable<any> {
    if (isNullOrUndefined(active)) {
      active = false;
    }

    return this.http.patch(
      `${this.apiBaseUrl}/${apiPath}/${id}/showing/${active}`,
      { headers: this.httpHeaders }
    );
  }

  SoftDelete(
    apiPath: string,
    id: string,
    isDelete: boolean = true
  ): Observable<any> {
    return this.http.patch(
      `${this.apiBaseUrl}/${apiPath}/${id}/delete/${isDelete}`,
      { headers: this.httpHeaders }
    );
  }

  //Error
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage : ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
}
