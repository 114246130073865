import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ApiService } from 'service/api.service';
import { CustomerService } from 'service/customer.service';
import { DocumentService } from 'service/document.service';
import { GlobalFuncService } from 'service/global-func.service';
import Swal from 'sweetalert2';
import { ModalComponent } from '../../task-document/modal/modal.component';

@Component({
  selector: 'app-task-detail',
  templateUrl: './my-task-detail.component.html',
  styleUrls: ['./my-task-detail.component.scss'],
})
export class MyTaskDetailComponent implements OnInit {
  tableListData = [];

  tableHeader: any[];

  currentDate = new Date();
  firstDayOfMonth = new Date(
    this.currentDate.getFullYear(),
    this.currentDate.getMonth() - 1,
    1
  );
  lastDayOfMonth = new Date(
    this.currentDate.getFullYear(),
    this.currentDate.getMonth(),
    0
  );

  public dateOptions: Partial<BsDatepickerConfig> = {
    adaptivePosition: true,
    isAnimated: true,
    containerClass: 'theme-dark-blue',
    dateInputFormat: 'D MMM YYYY',
    showWeekNumbers: false,
    minDate: this.firstDayOfMonth,
    maxDate: this.lastDayOfMonth,
    displayMonths: 1,
  };

  selectedDate: Date = new Date();

  customerId: string;
  customerDetail: any;

  creditMovement: number = 0;
  creditPP30: number = 0;

  month: number;
  year: number;

  @BlockUI() blockUI: NgBlockUI;

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private customerService: CustomerService,
    private route: ActivatedRoute,
    private documentService: DocumentService,
    private globalFunction: GlobalFuncService,
    private apiService: ApiService,
    private localeService: BsLocaleService,
    private globalFuncService: GlobalFuncService
  ) {
    const token = JSON.parse(localStorage.getItem('token'));
    this.customerService.SetHttpHeaders(token);
    this.documentService.SetHttpHeaders(token);
    this.apiService.SetHttpHeaders(token);
    this.localeService.use('th-be');
    this.selectedDate.setMonth(this.currentDate.getMonth() - 1);

    this.checkRouteParam();
    this.blockUI.start();
  }

  ngOnInit(): void {
    this.getCustomerDetail();
    this.getCustomerDocumentByPeriod();
  }

  onDownloadFile(param: string, fileName: string) {
    this.globalFuncService.getExcelReport(
      'excel/ExportMyworkById',
      `My Work - ${this.customerDetail.businessName}`,
      {
        customerId: this.customerId,
        AtMonthNumber: this.month,
        AtYearNumber: this.year,
      }
    );
  }

  onAddClicked(content: any) {
    this.modalService.open(content, { centered: true }).result.then(
      (result) => {
        console.log('close with', result); //close
      },
      (reason) => {
        console.log('dismiss with', reason); //dismiss
      }
    );
  }

  checkRouteParam() {
    this.route.params.subscribe((params) => {
      this.customerId = params['id'];
      this.month = params['month'];
      this.year = params['year'];

      console.log(params);
      console.log(this.month, this.year);
    });
  }

  getCustomerDocumentByPeriod() {
    this.apiService
      .GetAllData('myworks', {
        customerId: this.customerId,
        AtMonthNumber: this.month,
        AtYearNumber: this.year,
      })
      .subscribe((res) => {
        this.tableListData = [];
        res.data.resultData.forEach((element, index) => {
          element.paymentAmount = [''];
          element.diffValueList = [''];
          element.paymentTypeList = [''];
          element.bankList = [''];
          element.payDate = [''];
          element.payTime = [''];
          this.tableListData.push(element);
          this.tableHeader = element.documentValueList;
        });

        this.blockUI.stop();
      });
  }

  getCustomerDetail() {
    this.customerService
      .getCustomerDetail(this.customerId)
      .subscribe(async (res) => {
        this.customerDetail = res.data.resultData[0];
        console.log(this.customerDetail)
        await this.getCreditMovementByCustomer(this.customerId);
        await this.getCreditPP30ByCustomer(this.customerId);
        this.customerService
          .getCustomerAddress(this.customerId)
          .subscribe((res) => {
            this.customerDetail.customerAddress = res.data.resultData[0];
          });
      });
  }

  getCreditMovementByCustomer(customerId: string): Promise<number> {
    return new Promise((resolve) => {
      this.apiService
        .Get(`credit/Credit/Last/Customer/${customerId}`)
        .subscribe((res) => {
          this.creditMovement = res.data.resultData[0].balance
            ? res.data.resultData[0].balance
            : 0;
          resolve(this.creditMovement);
        });
    });
  }

  getCreditPP30ByCustomer(customerId: string): Promise<number> {
    return new Promise((resolve) => {
      this.apiService
        .Get(`creditpp30/CreditPP30/Last/Customer/${customerId}`)
        .subscribe((res) => {
          this.creditPP30 = res.data.resultData[0].balance
            ? res.data.resultData[0].balance
            : 0;
          resolve(this.creditPP30);
        });
    });
  }

  onStatusClicked(documentId: string) {
    this.router.navigate(['/atms/task-document/view-document/', documentId]);
  }

  getMonthName(monthNumber: number): string {
    return this.globalFunction.getMonthName(monthNumber);
  }

  preparePostData() {
    return {
      customerId: this.customerId,
      year: this.selectedDate.getFullYear(),
      month: this.selectedDate.getMonth() + 1,
      documentCreateDate: '2023-12-03T06:58:56.986Z',
      documentDueDate: '2023-12-03T06:58:56.986Z',
      period: this.calulatePeriod(this.selectedDate),
      remarks: '',
    };
  }

  AddDocumentTiming() {
    console.log(this.selectedDate);
    console.log(this.calulatePeriod(this.selectedDate));
    this.blockUI.start();
    this.apiService.Post('documenttiming', this.preparePostData()).subscribe(
      (res) => {
        this.blockUI.stop();
        this.getCustomerDocumentByPeriod();
        Swal.fire('เพิ่มข้อมูลสำเร็จ', '', 'success');
      },
      (err) => {
        this.blockUI.stop();
        Swal.fire('เพิ่มข้อมูลไม่สำเร็จ', '', 'error');
      }
    );
  }

  calulatePeriod(date: Date) {
   
    return this.globalFunction.calulatePeriod(date);
  }

  markerJob(data) {
    console.log(data)
    const modalRef = this.modalService.open(ModalComponent, { centered: true });
    modalRef.componentInstance.title = 'ยืนยันการปิดงาน'
    modalRef.componentInstance.detail = 'คุณแน่ใจที่จะปิดงานนี้หรือไม่'
    modalRef.componentInstance.callBackFunc.subscribe(res => {
      this.callMarkJob(data)
    })
  }

  callMarkJob(data) {
    this.blockUI.start()
    this.apiService.Post('Document/setmarker/bydocumenttiming', 
      { documentTimingIdList: [data], isMarked: true}).subscribe(
      (res) => {
        this.blockUI.stop();
        Swal.fire('Complete', '', 'success');
        this.modalService.dismissAll()
      },
      (err) => {
        this.blockUI.stop();
        Swal.fire('Error', '', 'error');
      }
    );
  }

  GetAddressTemplate(customerAddr): string {
    return this.globalFunction.GetAddressTemplate(customerAddr)
  }
}
