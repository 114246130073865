import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

import { AuthenticationService } from 'app/auth/service';
import Swal from 'sweetalert2';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private tokenUpdated$ = new BehaviorSubject<boolean>(false);

  @BlockUI() blockUI: NgBlockUI

  /**
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   */
  constructor(
    private _router: Router,
    private _authenticationService: AuthenticationService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
       
        switch (err.status) {
          case 401:
            // if (!this.refreshTokenInProgress) {
            //   this.refreshTokenInProgress = true;
            //   return this._authenticationService.refreshToken().pipe(
            //     switchMap(() => {
            //       let token = this._authenticationService.tokenValue;
            //       request = request.clone({
            //         setHeaders: {
            //           Authorization: `Bearer ${token}`,
            //         },
            //       });
            //       this.refreshTokenInProgress = false
            //       this.tokenUpdated$.next(true);
            //       return next.handle(request);
            //     })
            //   );
            // } else {
            //   return this.tokenUpdated$.pipe(
            //     filter(updated => updated === true),
            //     take(1),
            //     switchMap(() => {
            //       let token = this._authenticationService.tokenValue;
            //       request = request.clone({
            //         setHeaders: {
            //           Authorization: `Bearer ${token}`,
            //         },
            //       });
            //       return next.handle(request)
            //     })
            //   );
            // }
            this.blockUI.stop()
            if (err.error.messageCode == '1504') {
              Swal.fire(
                'เซสชั่นหมดอายุ',
                'กรุณาเข้าสู่ระบบใหม่อีกครั้ง',
                'error'
              ).then(() => {
                this._authenticationService.logout();
                return throwError(err);
              });
            } else if (err.error.messageCode == '1505') {
              Swal.fire('มีการเข้าสู่ระบบซ้ำ', 'กรุณาเข้าสู่ระบบใหม่อีกครั้ง', 'error').then(() => {
                this._authenticationService.logout();
                return throwError(err);
              });
            } else {
              Swal.fire(
                'เกิดข้อผิดพลาด',
                'กรุณาเข้าสู่ระบบใหม่อีกครั้ง',
                'error'
              ).then(() => {
                this._authenticationService.logout();
                return throwError(err);
              });
            }

            
          case 403:
            // this._router.navigate(['/miscellaneous/not-authorized']);
            this.blockUI.stop()
            if (err.error.messageCode == '1504') {
              Swal.fire(
                'เซสชั่นหมดอายุ',
                'กรุณาเข้าสู่ระบบใหม่อีกครั้ง',
                'error'
              ).then(() => {
                this._authenticationService.logout();
                return throwError(err);
              });
            } else if (err.error.messageCode == '1505') {
              Swal.fire('มีการเข้าสู่ระบบซ้ำ', 'กรุณาเข้าสู่ระบบใหม่อีกครั้ง', 'error').then(() => {
                this._authenticationService.logout();
                return throwError(err);
              });
            } else {
              Swal.fire(
                'เกิดข้อผิดพลาด',
                'กรุณาเข้าสู่ระบบใหม่อีกครั้ง',
                'error'
              ).then(() => {
                this._authenticationService.logout();
                return throwError(err);
              });
            }
          default:
            // MessageBox.show({
            //     title: `Code : ${err.error.data?.errorCode}`,
            //     contentColor: 'danger',
            //     text: err.error.data?.message,
            //     icon: 'error',
            //     primaryButton: {
            //         color: 'danger',
            //         text: 'ปิด'
            //     }
            // })
            this.blockUI.stop()
            return throwError(err);
        }
      })
    );
  }
}
