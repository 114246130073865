<div class="row">
  <div class="col-12">
    <div class="card pb-0 mb-0">
      <div class="card-body px-3 py-5">
        <div class="row">
          <div class="col-md-6 col-12 mb-2">
            <div class="mt-2 text-center rounded">
              <img
                [src]="
                  profileIMG
                    ? profileIMG
                    : '../../../../../assets/images/portrait/small/avatar-logo.jpg'
                "
                (error)="profileIMG = '../../../../../assets/images/portrait/small/avatar-logo.jpg'"
                style="
                  object-fit: cover;
                  aspect-ratio: 1/1;
                  max-height: 250px;
                  max-width: 250px;
                  border-radius: 100%;
                "
              />
            </div>

            <div class="d-flex">
              <div class="mx-auto my-1">
                <label class="btn btn-primary mb-0" for="change-picture">
                  <span class="d-none d-sm-block"> เปลี่ยน </span>
                  <input
                    class="form-control"
                    type="file"
                    id="change-picture"
                    hidden
                    accept="image/png, image/jpeg, image/jpg"
                    (change)="
                      uploadProfilePicture($event, changeProfileImageModal)
                    "
                  />
                  <span class="d-block d-sm-none">
                    <i class="mr-0" data-feather="edit"></i>
                  </span>
                </label>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-12 d-block m-auto">
            <div class="row mb-2">
              <label
                for="staticEmail"
                class="col-xxl-2 col-xl-3 col-lg-3 col-sm-3 col-form-label"
                >ชื่อ</label
              >
              <div class="col-lg-8 col-sm-8">
                <input
                  type="text"
                  class="form-control"
                  value="{{ userDetail.firstName }}"
                  disabled
                />
              </div>
            </div>
            <div class="row mb-2">
              <label
                for="staticEmail"
                class="col-xxl-2 col-xl-3 col-lg-3 col-sm-3 col-form-label"
                >นามสกุล</label
              >
              <div class="col-lg-8 col-sm-8">
                <input
                  type="text"
                  class="form-control"
                  value="{{ userDetail.lastName }}"
                  disabled
                />
              </div>
            </div>

            <div class="row mb-2">
              <label
                for="staticEmail"
                class="col-xxl-2 col-xl-3 col-lg-3 col-sm-3 col-form-label"
                >แผนก</label
              >
              <div class="col-lg-8 col-sm-8">
                <input
                  type="text"
                  class="form-control"
                  value="{{ userDetail.departmentName_TH }}"
                  disabled
                />
              </div>
            </div>
            <div class="row mb-2">
              <label
                for="staticEmail"
                class="col-xxl-2 col-xl-3 col-lg-3 col-sm-3 col-form-label"
                >ทีม</label
              >
              <div class="col-lg-8 col-sm-8">
                <input
                  type="text"
                  class="form-control"
                  value="{{ userDetail.teamName_TH }}"
                  disabled
                />
              </div>
            </div>
            <div class="row mb-2">
              <label
                for="staticEmail"
                class="col-xxl-2 col-xl-3 col-lg-3 col-sm-3 col-form-label"
                >ตำแหน่ง</label
              >
              <div class="col-lg-8 col-sm-8">
                <input
                  type="text"
                  class="form-control"
                  value="{{ userDetail.positionName_TH }}"
                  disabled
                />
              </div>
            </div>
            <div class="row mb-2">
              <label
                for="staticEmail"
                class="col-xxl-2 col-xl-3 col-lg-3 col-sm-3 col-form-label"
                >หัวหน้า</label
              >
              <div class="col-lg-8 col-sm-8">
                <input
                  type="text"
                  class="form-control"
                  value="{{ userDetail.superVisor }}"
                  disabled
                />
              </div>
            </div>
            <div class="row mb-2">
              <label
                for="staticEmail"
                class="col-xxl-2 col-xl-3 col-lg-3 col-sm-3 col-form-label"
                >ผู้จัดการ</label
              >
              <div class="col-lg-8 col-sm-8">
                <input
                  type="text"
                  class="form-control"
                  value="{{ userDetail.manager }}"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #changeProfileImageModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalCenterTitle">
      Change Profile Picture
    </h5>
    <button
      type="button"
      class="close"
      (click)="modal.dismiss('Cross click')"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center" tabindex="0" ngbAutofocus>
    <div class="" style="object-fit: cover">
      <img
        [src]="previewIMG"
        
        alt=""
        width="200"
        height="200"
        style="
          object-fit: cover;
          aspect-ratio: 1/1;
          max-height: 250px;
          max-width: 250px;
          border-radius: 100%;
        "
      />
    </div>
    <p class="mt-1">{{ previewIMGName }}</p>
  </div>
  <div class="modal-footer justify-content-between">
    <button
      type="button"
      class="btn btn-dark"
      (click)="modal.close()"
      rippleEffect
    >
      ยกเลิก
    </button>
    <button
      type="button"
      ngSubmit
      (click)="SaveProfilePicture()"
      class="btn btn-primary"
      rippleEffect
    >
      ตกลง
    </button>
  </div>
</ng-template>
