<div class="modal-header text-center bg-primary">
    <h5 class="modal-title w-100" id="exampleModalCenterTitle">
      {{ title }}
    </h5>
    <button type="button" class="close" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">
        <i data-feather="x" size="22"></i>
      </span>
    </button>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    <p style="word-break: break-all; white-space: pre-wrap">{{ detail }}</p>
  </div>
  <div class="modal-footer justify-content-between">
    <button
      type="button"
      class="btn btn-secondary m-0"
      (click)="closeModal()"
      rippleEffect
    >
      ยกเลิก
    </button>
    <a
      type="button"
      class="btn btn-primary m-0"
      (click)="callBack()"
      rippleEffect
    >
      ยืนยัน
    </a>
  </div>
  