import { NgModule } from '@angular/core';
import { PaginationComponent } from './pagination/pagination.component';
import { FormWizardComponent } from './form-wizard/form-wizard.component';
import { CommonModule, JsonPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TransferTaskComponent } from './transfer-task/transfer-task.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { CompleteModalComponent } from './complete-modal/complete-modal.component';
import { CancelModalComponent } from './cancel-modal/cancel-modal.component';
import { NgbAlertModule, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { DocumentSubmitDateModalComponent } from './document-submit-date-modal/document-submit-date-modal.component';
import { CheckboxFormModalComponent } from './checkbox-form-modal/checkbox-form-modal.component';
import { TextAreaFormModalComponent } from './text-area-form-modal/text-area-form-modal.component';
import { DocumentExportDateModalComponent } from './document-export-date-modal/document-export-date-modal.component';
import { InvoiceTemplateComponent } from './invoice-template/invoice-template.component';
import { CorePipesModule } from '@core/pipes/pipes.module';
import { CoreCommonModule } from '@core/common.module';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NgSelectModule,
    NgbDatepickerModule,
    NgbAlertModule,
    JsonPipe,
    CorePipesModule,
    CoreCommonModule
  ],
  declarations: [
    PaginationComponent,
    FormWizardComponent,
    TransferTaskComponent,
    ConfirmModalComponent,
    CompleteModalComponent,
    CancelModalComponent,
    DocumentSubmitDateModalComponent,
    CheckboxFormModalComponent,
    TextAreaFormModalComponent,
    DocumentExportDateModalComponent,
    InvoiceTemplateComponent,
    
  ],
  exports: [
    PaginationComponent,
    FormWizardComponent,
    TransferTaskComponent,
    ConfirmModalComponent,
    CompleteModalComponent,
    CancelModalComponent,
    DocumentSubmitDateModalComponent,
    DocumentExportDateModalComponent,
    CheckboxFormModalComponent,
    TextAreaFormModalComponent,
    InvoiceTemplateComponent,
    
  ]
})
export class SharedModule { }
