<div class="modal-header text-center bg-primary">
  <h3 class="modal-title w-100">Account Task Management System</h3>
</div>
<div class="modal-body">
  <div class="my-4">
    <h4 class="big-text blue-text text-center mb-2">กรุณาเลือกรอบงาน</h4>
    <br />
    <form [formGroup]="form">
      <ng-container *ngIf="isLoading">
        <div class="d-flex justify-content-center">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="!isLoading">
        <div *ngFor="let i of itemList; index as ind" class="text-center mb-1">
          <div class="d-flex align-items-center justify-content-center">
            <div class="custom-control custom-radio">
              <fieldset [disabled]="!i.isUsing">
                <input
                  type="radio"
                  id="{{ i.id }}"
                  [value]="i.id"
                  formControlName="selectedDoc"
                  class="custom-control-input"
                />
                <label
                  class="custom-control-label"
                  for="{{ i.id }}"
                  style="font-size: 18px"
                >
                  {{ i.monthNameTh }}: รอบที่ {{ i.period }}
                </label>
              </fieldset>
            </div>
          </div>
        </div>
      </ng-container>
    </form>
  </div>
</div>
<div class="modal-footer justify-content-between">
  <button type="button" class="btn btn-danger" (click)="closeModal()">
    ยกเลิก
  </button>
  <button type="button" class="btn btn-primary" (click)="Submit()">
    ยืนยัน
  </button>
</div>
