import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { CoreConfigService } from '@core/services/config.service';
import Invoice, { TempRowInvoice, InvoiceItem } from 'app/auth/models/invoice';
import { paymentTypeList } from 'app/main/enum/paymentType';
import { submitTypeList } from 'app/main/enum/submitType';
import { environment } from 'environments/environment';
import { take } from 'rxjs/operators';
import { CustomerService } from 'service/customer.service';
import { GlobalFuncService } from 'service/global-func.service';

@Component({
  selector: 'app-invoice-template',
  templateUrl: './invoice-template.component.html',
  styleUrls: ['./invoice-template.component.scss'],
})
export class InvoiceTemplateComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild('invoiceTemplate') invoiceTemplate: TemplateRef<any>;

  @ViewChild('tableElement')
  tableElement: ElementRef<HTMLElement>;
  @ViewChild('tableElementBottom')
  tableElementBottom: ElementRef<HTMLElement>;

  @ViewChild('trClild')
  trClild: ElementRef<HTMLElement>;

  @ViewChild('mainElement')
  mainElement: ElementRef<HTMLElement>;
  @ViewChild('mainElementBottom')
  mainElementBottom: ElementRef<HTMLElement>;

  @ViewChild('overElement')
  overElement: ElementRef<HTMLElement>;

  @ViewChild('trcHeaderElement')
  trcHeaderElement: ElementRef<HTMLElement>;
  @ViewChild('trcHeaderElementBottom')
  trcHeaderElementBottom: ElementRef<HTMLElement>;

  @ViewChildren('onlineScope') onlineScopeElement: QueryList<
    ElementRef<HTMLElement>
  >;

  @ViewChildren('itemListElements')
  itemListElements: QueryList<ElementRef<HTMLElement>>;
  @ViewChildren('itemListElementsBottom')
  itemListElementsBottom: QueryList<ElementRef<HTMLElement>>;

  contentHeader: object;

  @Input() data: any;
  idOfInvoice: number = 0;
  @Input() invoiceObj: any;

  customerGrandTotal: number = 0;

  bottomTableExcess: boolean = false;

  logoOnPaper: string = 'assets/images/logo/LOGO-Full-White-DBD.jpg';

  qrCodeImage: string = 'assets/images/logo/Code Accountworks.jpg';

  submitTypeList: any[] = submitTypeList;
  paymentTypeList: any[] = paymentTypeList;

  paperTopWithFooter: boolean;

  constructor(
    private cdr: ChangeDetectorRef,
    private globalFuncService: GlobalFuncService,
    private customerService: CustomerService,
    private _coreConfigService: CoreConfigService,
    private _title: Title
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit() {


    this.itemListElements.changes.subscribe((res) => {
      console.log('item list element start');
      if (this.invoiceObj) {
        console.log('data is here');
        console.log(this.invoiceObj['invoiceCode']);
        this._title.setTitle(this.invoiceObj['invoiceCode']);
      }
      this.calculateSummary();
      this.getCustomerDetail();
    });

    this.itemListElementsBottom.changes.subscribe((res) => {
      if (!this.bottomTableIsChange) {
        setTimeout(() => {
          console.log('bottom item list element start');
          this.calculateBottomSummary();
          if (this.bottomItemList.length > 1) {
            this.bottomTableExcess = true;
            this.bottomItemList.forEach((element) => {
              this.itemList.push(element);
            });
          }
        }, 1000);
      }
      this.getCustomerDetail();
    });
  }

  ngOnDestroy(): void {
    this._coreConfigService.config.pipe(take(1)).subscribe((config) => {
      this._title.setTitle(config.app.appTitle);
    });
  }

  months = [
    'มกราคม',
    'กุมภาพันธ์',
    'มีนาคม',
    'เมษายน',
    'พฤษภาคม',
    'มิถนายน',
    'กรกฏาคม',
    'สิงหาคม',
    'กันยายน',
    'ตุลาคม',
    'พฤศจิกายน',
    'ธันวาคม',
  ];

  isNegitive(val: number): boolean {
    if (val < 0) {
      return true;
    } else {
      return false;
    }
  }

  Excess: Boolean = false;
  showDummyRow: boolean = true;
  dummyRowAmountList: number[] = [];
  itemList: any[];
  topItemList: any[];
  bottomItemList: any[];
  bottomTableIsChange: boolean = false;
  topTableHeight: number;

  calculateSummary() {
    this.itemList = [];
    this.topItemList = [];
    const { reback } = this.checkTableHeight();

    console.log('last index', reback);

    const result: any[][] = [];
    let startIndex = 0;

    for (const index of reback) {
      if (index <= this.invoiceObj['companySubmitItem'].length) {
        const chunk = this.invoiceObj['companySubmitItem'].slice(
          startIndex,
          index
        );
        result.push(chunk);
        startIndex = index;
      }
    }
    if (startIndex < this.invoiceObj['companySubmitItem'].length) {
      const remainingChunk =
        this.invoiceObj['companySubmitItem'].slice(startIndex);
      result.push(remainingChunk);
    }

    result.forEach((subItemList) => {
      const tempRowList: TempRowInvoice[] = [];

      subItemList.forEach((element: InvoiceItem) => {
        const tempRow = new TempRowInvoice();
        tempRow.rowType = 'item';
        tempRow.itemId = element.itemId;
        tempRow.itemName = element.itemName;
        tempRow.description = element.description;
        tempRow.submitType = element.submitType;
        tempRow.documentTypeEnum = element.documentTypeEnum;
        tempRow.unitPrice = element.unitPrice;
        tempRow.fineAmount = element.fineAmount;
        tempRow.preTaxAmount = element.preTaxAmount;
        tempRowList.push(tempRow);
      });

      this.itemList.push(tempRowList);
      this.topItemList.push(tempRowList);
    });

    if (
      reback[reback.length - 1] == this.invoiceObj['companySubmitItem'].length
    ) {
      this.itemList.push([]);
      this.topItemList.push([]);
      console.log('item list', this.itemList);
    }

    this.customerGrandTotal = 0;
    this.invoiceObj['customerSubmitItem'].forEach((element) => {
      this.customerGrandTotal += element['grandTotal'];
    });

    console.log('item list', this.itemList);
  }

  calculateBottomSummary() {
    this.bottomTableIsChange = true;
    this.bottomItemList = [];
    const { bottomReback } = this.checkBottomTableHeight();

    console.log(bottomReback);

    const result: any[][] = [];
    let startIndex = 0;

    for (const index of bottomReback) {
      if (index <= this.invoiceObj['customerSubmitItem'].length) {
        const chunk = this.invoiceObj['customerSubmitItem'].slice(
          startIndex,
          index
        );
        result.push(chunk);
        startIndex = index;
      }
    }
    if (startIndex < this.invoiceObj['customerSubmitItem'].length) {
      const remainingChunk =
        this.invoiceObj['customerSubmitItem'].slice(startIndex);
      result.push(remainingChunk);
    }

    result.forEach((subItemList) => {
      const tempRowList: TempRowInvoice[] = [];

      // subItemList.forEach((element: InvoiceItem) => {
      //   const tempRow = new TempRowInvoice();
      //   tempRow.rowType = 'item';
      //   tempRow.itemId = element.itemId;
      //   tempRow.itemName = element.itemName;
      //   tempRow.unitPrice = element.unitPrice;
      //   tempRow.fineAmount = element.fineAmount;
      //   tempRow.grandTotal = element.grandTotal;
      //   tempRowList.push(tempRow);
      // });

      subItemList.forEach((element: InvoiceItem) => {
        const tempRow = new TempRowInvoice();
        tempRow.rowType = 'item';
        tempRow.itemId = element.itemId;
        tempRow.itemName = element.itemName;
        tempRow.description = element.description;
        tempRow.submitType = element.submitType;
        tempRow.documentTypeEnum = element.documentTypeEnum;
        tempRow.unitPrice = element.unitPrice;
        tempRow.fineAmount = element.fineAmount;
        tempRow.preTaxAmount = element.preTaxAmount;
        tempRowList.push(tempRow);
      });

      this.bottomItemList.push(tempRowList);
    });

    if (
      bottomReback[bottomReback.length - 1] ==
      this.invoiceObj['customerSubmitItem'].length
    ) {
      this.bottomItemList.push([]);
    }

    this.customerGrandTotal = 0;
    this.invoiceObj['customerSubmitItem'].forEach((element) => {
      this.customerGrandTotal += element['grandTotal'];
    });

    console.log(this.bottomItemList);
  }

  checkTableHeight() {
    let footerHeight = this.getBottomTableHeight();
    console.log('footer height', footerHeight);
    if (this.invoiceObj['companySubmitItem']) {
      const table = this.tableElement.nativeElement as HTMLElement;
      const mainHeight =
        this.mainElement.nativeElement.getBoundingClientRect().height;
      console.log('main height', mainHeight);
      const overHeight = 850;
      const trcHeader = this.trcHeaderElement.nativeElement;
      const trElements = Array.from(table.querySelectorAll('tr.trc'));
      const trcHeaderHeight = trcHeader.getBoundingClientRect().height;

      let reback = [];
      let cumulativeHeight = 0;
      let excessTrCount = 0;
      let splitIndex = -1;
      let forTwoPage = 0;
      let lastIndexofExcess = [];
      for (let i = 0; i < trElements.length; i++) {
        const tr = trElements[i] as HTMLElement;

        if (tr.classList[0] == 'trc') {
          cumulativeHeight += Math.round(
            tr.getBoundingClientRect().height * (9.5 / 10)
          );
        }
        if (cumulativeHeight > mainHeight) {
          excessTrCount++;
          this.Excess = true;
          lastIndexofExcess.push(i);
          forTwoPage++;
          this.paperTopWithFooter = false;
        }
        if (cumulativeHeight >= overHeight) {
          forTwoPage = 0;
          reback.push(i);
          excessTrCount++;

          if (this.showDummyRow) {
            let dummyRowAmount = 0;
            // let dummyRowAmount =
            //   (overHeight -
            //     (cumulativeHeight - tr.getBoundingClientRect().height)) /
            //   31.5;
            // if (dummyRowAmount < 1) {
            //   dummyRowAmount = 0;
            // }
            this.dummyRowAmountList.push(Math.floor(dummyRowAmount));
          }

          cumulativeHeight = tr.getBoundingClientRect().height;
          if (splitIndex === -1) {
            splitIndex = i;
          }
        }
      }

      // more option if top table not longer to excess paper
      if (cumulativeHeight + footerHeight >= overHeight && forTwoPage == 0) {
        excessTrCount++;
        this.Excess = true;
        lastIndexofExcess.push([]);
        forTwoPage++;

        if (cumulativeHeight < footerHeight) {
          this.paperTopWithFooter = false;
        } else {
          this.paperTopWithFooter = true;
        }
      }

      if (excessTrCount >= 1) {
        if (forTwoPage == 1) {
          // reback.push(lastIndexofExcess[0] + 1);
        } else {
          if (this.showDummyRow) {
            let dummyRowAmount = (overHeight - 520 - cumulativeHeight) / 31.5;
            if (dummyRowAmount < 1) {
              dummyRowAmount = 0;
            }
            this.dummyRowAmountList.push(Math.floor(dummyRowAmount));
          }
        }
      } else {
        if (this.showDummyRow) {
          let dummyRowAmount = (overHeight - 390 - cumulativeHeight) / 31.5;
          if (dummyRowAmount < 1) {
            dummyRowAmount = 0;
          }
          this.dummyRowAmountList.push(Math.floor(dummyRowAmount));
        }
      }
      this.cdr.detectChanges();
      console.log('dummy row', this.dummyRowAmountList);
      return { reback };
    }
  }

  checkBottomTableHeight() {
    if (this.invoiceObj['customerSubmitItem']) {
      const table = this.tableElementBottom.nativeElement as HTMLElement;
      const mainHeight =
        this.mainElementBottom.nativeElement.getBoundingClientRect().height;
      console.log('main footer height', mainHeight);
      const overHeight = 950;
      const trcHeader = this.trcHeaderElementBottom.nativeElement;
      const trElements = Array.from(table.querySelectorAll('tr.trc-bottom'));
      const trcHeaderHeight = trcHeader.getBoundingClientRect().height;

      let bottomReback = [];
      let cumulativeHeight = 0;
      let excessTrCount = 0;
      let splitIndex = -1;
      let forTwoPage = 0;
      let lastIndexofExcess = [];
      for (let i = 0; i < trElements.length; i++) {
        const tr = trElements[i] as HTMLElement;

        if (tr.classList[0] == 'trc-bottom') {
          cumulativeHeight += Math.round(
            tr.getBoundingClientRect().height * (9.5 / 10)
          );
        }
        if (cumulativeHeight > mainHeight) {
          excessTrCount++;
          this.Excess = true;
          lastIndexofExcess.push(i);
          forTwoPage++;
        }
        if (cumulativeHeight >= overHeight) {
          forTwoPage = 0;
          bottomReback.push(i);
          excessTrCount++;

          if (this.showDummyRow) {
            let dummyRowAmount = 0;
            // let dummyRowAmount =
            //   (overHeight -
            //     (cumulativeHeight - tr.getBoundingClientRect().height)) /
            //   31.5;
            // if (dummyRowAmount < 1) {
            //   dummyRowAmount = 0;
            // }
            this.dummyRowAmountList.push(Math.floor(dummyRowAmount));
          }

          cumulativeHeight = tr.getBoundingClientRect().height;
          if (splitIndex === -1) {
            splitIndex = i;
          }
        }
      }

      if (excessTrCount >= 1) {
        if (forTwoPage == 1) {
          bottomReback.push(lastIndexofExcess[0] + 1);
        }
      }
      this.cdr.detectChanges();
      console.log(bottomReback);
      return { bottomReback };
    }
  }

  getBottomTableHeight() {
    const tableBottom = this.tableElementBottom.nativeElement as HTMLElement;
    const trElementsBottom = Array.from(
      tableBottom.querySelectorAll('tr.trc-bottom')
    );
    let bottom_cumulativeHeight = 0;
    console.log('bottom tr list', trElementsBottom);
    for (let i = 0; i < trElementsBottom.length; i++) {
      const trBottom = trElementsBottom[i] as HTMLElement;

      if (trBottom.classList[0] == 'trc-bottom') {
        bottom_cumulativeHeight += Math.round(
          trBottom.getBoundingClientRect().height
        );
      }
    }
    return bottom_cumulativeHeight + 298;
    // 298 is a top of footer table height
  }

  getNameFormat(fullName: string): string {
    if (fullName) {
      const names = fullName.split(' ');
      const firstName = names[0];
      const lastName = names.length > 1 ? names[names.length - 1] : '';

      return `${firstName}${lastName ? ' ' + lastName.charAt(0) + '.' : ''}`;
    }
  }

  formatMinusOrNot(value: any): string {
    let strValue: number = parseFloat(value);
    strValue = strValue ? strValue : 0;
    return this.globalFuncService.FormatMinusOrNot(strValue)
      ? this.globalFuncService.FormatMinusOrNot(strValue)
      : '0.00';
  }

  formatPhoneNumber(phoneNumber: string): string {
    if (!phoneNumber) {
      return '';
    }
    let formattedNumber = phoneNumber.replace(/\D/g, '');
    formattedNumber = formattedNumber.replace(
      /(\d{3})(\d{3})(\d{4})/,
      '$1 - $2 - $3'
    );
    return formattedNumber;
  }

  customerDetail: any;
  customerAddress: any;
  isCompany: boolean;
  getCustomerDetail() {
    const customerId = this.invoiceObj['customerId'];
    this.customerService.getCustomerDetail(customerId).subscribe((res) => {
      this.customerDetail = res.data.resultData[0];
      if (this.customerDetail['businessTypeId'] == 16) {
        this.isCompany = false;
      } else {
        this.isCompany = true;
      }

      console.log('isCompany',this.isCompany)
    });
    this.customerService.getCustomerAddress(customerId).subscribe((res) => {
      this.customerAddress = res.data.resultData[0];
    });
  }

  GetAddressTemplate(customerAddressObj): string {
    let result = '';

    if (customerAddressObj) {
      var provinceId = parseInt(customerAddressObj.provinceId);

      let address = customerAddressObj.addressNumber
        ? customerAddressObj.addressNumber
        : '-';
      let subDistrict = customerAddressObj.subDistrictName
        ? (provinceId == 10 ? 'แขวง' : 'ตำบล') +
          customerAddressObj.subDistrictName
        : '-';
      let district = customerAddressObj.districtName
        ? (provinceId == 10 ? 'เขต' : 'อำเภอ') + customerAddressObj.districtName
        : '-';

      let provinceName = 'จังหวัด' + customerAddressObj.provinceName;

      let postCode = customerAddressObj.postCode;

      result = `${address} ${subDistrict} ${district} ${provinceName} ${postCode}`;
      return customerAddressObj ? result : '-';
    } else {
      return customerAddressObj ? result : '-';
    }
  }
}
