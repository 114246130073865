import { map } from 'rxjs/operators';
import { Validators } from '@angular/forms';
import { CustomerService } from '../../../../../../service/customer.service';
import { UserService } from '../../../../../../service/user.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Province } from 'app/auth/models/province';
import { CustomerCreateComponent } from '../customer-create.component';
import {
  CustomerAddress,
  CustomerAddressModel,
  CustomerDetails,
} from 'app/auth/models/customer';
import { forkJoin } from 'rxjs';
import { ConfirmModalComponent } from 'shared/confirm-modal/confirm-modal.component';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { AuthenticationService } from 'app/auth/service';
import Swal from 'sweetalert2';
import { ApiService } from 'service/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Role } from 'app/auth/models';
import { Gender } from 'app/main/enum/genderId';

@Component({
  selector: 'app-customer-create-business',
  templateUrl: './customer-create-business.component.html',
  styleUrls: ['./customer-create-business.component.scss'],
})
export class CustomerCreateBusinessComponent implements OnInit {
  @ViewChild('confirmModal') confirmModal: ConfirmModalComponent;

  provinces: Province[];
  districts: any[];
  subDistricts: any[];
  selectProvince: any;
  selectDistrict: any;
  selectSubDistrict: any;
  postCode: string;
  disFieldDistrict: boolean = true;
  disFieldSubDistrict: boolean = true;

  // send doc
  provincesDoc: Province[];
  districtsDoc: any[];
  subDistrictsDoc: any[];
  selectProvinceDoc: any;
  selectDistrictDoc: any;
  selectSubDistrictDoc: any;
  postCodeDoc: string;
  disFieldDistrictDoc: boolean = true;
  disFieldSubDistrictDoc: boolean = true;

  // Business Type
  businessType: any[];

  selectBusinessType: any;

  employeeType: any[];
  selectEnployee: any;
  employee: any;
  userRole: string;
  userRoleEnum = Role;

  loading: boolean = false;
  selectedTeam: any = 0;
  selectedDepartment: any = 2;
  teamList: any[];
  @BlockUI() blockUI: NgBlockUI;

  activeParentRoute: string;
  disableCheck: boolean = false; //เปิดให้แก้ไขข้อมูลได้
  buttonHide: boolean = false; //แสดงปุ่มแก้ไขข้อมูล
  customerDetails: CustomerDetails = new CustomerDetails();
  customerAddress: CustomerAddress = new CustomerAddress();
  customerDocAddress: CustomerAddress = new CustomerAddress();
  customerAddressList: CustomerAddressModel[] = [];
  routePath: string;
  currentUser: any;
  errorInput: any = [];
  validate: boolean = false;
  customerId: string = '';
  addressID = [];

  selectEmployee: any;
  employeeList: any[];

  teamId: number;

  originalEmployeeOwnedId: string;
  showCheckDialog: boolean = false;
  checkTransferDialog: string = '';

  constructor(
    public userService: UserService,
    public router: ActivatedRoute,
    public CustomerCreateComponent: CustomerCreateComponent,
    public customerService: CustomerService,
    private _router: Router,
    private authenticationService: AuthenticationService,
    private apiService: ApiService,
    private modalService: NgbModal
  ) {
    const token = JSON.parse(localStorage.getItem('token'));
    this.customerService.SetHttpHeaders(token);
    this.userService.SetHttpHeaders(token);
    this.apiService.SetHttpHeaders(token);

    this.currentUser = this.authenticationService.currentUserValue;
    this.userRole = this.currentUser.roleSetName;

    if (
      this.userRole == this.userRoleEnum.AccountantManager ||
      this.userRole == this.userRoleEnum.FinanceManager ||
      this.userRole == this.userRoleEnum.SuperAdmin
    ) {
      let params: any = {};

      if (this.userRole != this.userRoleEnum.SuperAdmin) {
        params.departmentId = this.currentUser.departmentId;
      }

      this.apiService
        .GetAllData(`team`, { departmentId: 2 })
        .subscribe((res) => {
          this.empTeamList = res.data.resultData;
          console.log('empteam', this.empTeamList);
        });
    } else {
      this.selectedTeam = this.currentUser.teamId;
      this.onselectTeam();
    }
  }

  ngOnInit(): void {
    this.routePath = this.CustomerCreateComponent.getActiveParentRoute();
    if (this.routePath == 'view' || this.routePath == 'edit') {
      this.buttonHide = true; //ปิดการแสดงปุ่มแก้ไขข้อมูล
    }

    if (this.routePath == 'view') {
      this.disableCheck = true; //ปิดการแก้ไขข้อมูล
      this.customerId = this.CustomerCreateComponent.getCustomerId();
      this.getCustomer();
    } else if (this.routePath == 'edit') {
      if (this.isAccountDepartment() || this.isSuperAdmin()) {
        this.disableCheck = false; //เปิดให้แก้ไขข้อมูลได้
      } else {
        this.disableCheck = true;
      }

      this.disFieldDistrict = false;
      this.disFieldSubDistrict = false;
      this.disFieldDistrictDoc = false;
      this.disFieldSubDistrictDoc = false;
      this.customerId = this.CustomerCreateComponent.getCustomerId();
      this.getCustomer();
    }

    this.userService.getProvince().subscribe((data) => {
      this.provinces = data.data.resultData;
      this.provincesDoc = data.data.resultData;
    });
    this.customerService.getBusinessType().subscribe((data) => {
      this.businessType = data.data.resultData;
      this.businessTypeId = data.data.resultData;
      console.log(this.businessTypeId);
    });
  }
  businessTypeId: any;
  empTeamList: any[];
  teamNameEn: string;
  // getTeam() {
  //   this.apiService
  //     .GetAllData(`team/GetTeamOfEmployee/${this.employeeOwnedId}`)
  //     .subscribe((res) => {
  //       this.teamList = res.data.resultData[0];
  //       this.teamId = res.data.resultData[0].id;
  //       this.teamNameEn = res.data.resultData[0].nameEn;

  //       console.log('team list', this.teamNameEn);
  //     });
  // }

  getEmployeeList() {
    this.apiService.GetAllData('employee').subscribe((res) => {
      this.employeeList = res.data.resultData;
      this.employeeList.filter;
    });
  }

  //เมื่อเลือกจังหวัดในส่วนของ ที่อยู่จดทะเบียน
  onSelectChange() {
    // if (this.routePath == 'create') {
    this.disFieldDistrict = true;
    this.disFieldSubDistrict = true;
    this.subDistricts = [];
    this.districts = [];
    this.selectDistrict = null;
    this.selectSubDistrict = null;
    this.postCode = '';

    this.userService.getDistrict(this.selectProvince).subscribe((data) => {
      this.districts = data.data.resultData;
      this.disFieldDistrict = false;
    });
  }
  //เมื่อเลิกเลือกจังหวัดในส่วนของ ที่อยู่จดทะเบียน
  onSelectChangeDistrict() {
    this.disFieldSubDistrict = true; //ปิดการกรอกข้อมูลในส่วนของ ตำบล
    this.subDistricts = []; //เคลียร์ค่าในตัวแปร subDistricts
    this.selectSubDistrict = null; //เคลียร์ค่าในตัวแปร selectSubDistrict
    this.postCode = ''; //เคลียร์ค่าในตัวแปร postCode

    this.userService.getSubDistrict(this.selectDistrict).subscribe((data) => {
      this.subDistricts = data.data.resultData;
      this.disFieldSubDistrict = false;
    });
  }
  onSelectChangeEmployee() {}
  //เมื่อเลือกตำบลในส่วนของ ที่อยู่จดทะเบียน
  onSelectChangeSubDistrict() {
    if (this.selectSubDistrict) {
      this.subDistricts.forEach((e) => {
        if (e.id.trim() === this.selectSubDistrict.trim()) {
          this.postCode = e.subDistrictPostCode;
        }
      });
    }
  }

  onSelectDocChange() {
    // if (this.routePath == 'create') {
    console.log(this.selectProvinceDoc);

    this.disFieldDistrictDoc = true;
    this.disFieldSubDistrictDoc = true;
    this.subDistrictsDoc = [];
    this.districtsDoc = [];
    this.selectDistrictDoc = null;
    this.selectSubDistrictDoc = null;
    this.postCodeDoc = '';

    this.userService.getDistrict(this.selectProvinceDoc).subscribe((data) => {
      this.districtsDoc = data.data.resultData;
      this.disFieldDistrictDoc = false;
    });
  }

  onSelectDocChangeDistrict() {
    this.disFieldSubDistrictDoc = true; //ปิดการกรอกข้อมูลในส่วนของ ตำบล
    this.subDistrictsDoc = []; //เคลียร์ค่าในตัวแปร subDistricts
    this.selectSubDistrictDoc = null; //เคลียร์ค่าในตัวแปร selectSubDistrict
    this.postCodeDoc = '';

    this.userService
      .getSubDistrict(this.selectDistrictDoc)
      .subscribe((data) => {
        this.subDistrictsDoc = data.data.resultData;
        this.disFieldSubDistrictDoc = false;
      });
  }

  onSelectDocChangeSubDistrict() {
    if (this.selectSubDistrictDoc) {
      this.subDistrictsDoc.forEach((e) => {
        if (e.id.trim() === this.selectSubDistrictDoc.trim()) {
          this.postCodeDoc = e.subDistrictPostCode;
        }
      });
    }
  }
  selectTeamType: any;
  teamType: any[];
  onselectTeam($event?: any) {
    if ($event) {
      this.selectedTeam = $event;
      this.selectEmployee = null;
    }

    this.apiService
      .GetAllData('employee/GetEmployeeByDepartmentAndTeam', {
        departmentId: this.selectedDepartment,
        teamId: this.selectedTeam,
      })
      .subscribe((res) => {
        this.employeeType = res.data.resultData;
      });
  }
  genderOptions = [
    { label: 'ชาย', value: Gender.Male },
    { label: 'หญิง', value: Gender.Female },
    { label: 'อื่นๆ', value: Gender.NotSpecified },
  ];

  onSelectBusinessType() {
    if (this.businessTypeId.id == 16) {
      console.log(this.businessTypeId.id);

      this.customerDetails.isHeadBranch = true;
      this.customerDetails.islegalEntity = false;

      console.log('headbranch status', this.customerDetails.isHeadBranch);
    }
  }
  onselectEmployee() {
    if (this.selectEnployee) {
      console.log(this.originalEmployeeOwnedId);
      console.log(this.selectEmployee);
      if (this.originalEmployeeOwnedId != this.selectEmployee) {
        this.showCheckDialog = true;
        this.customerService
          .chectPermanentJobTransfer(this.customerId)
          .subscribe((res) => {
            if (res.data.resultData[0].permanentJobTransferCheckEnum == 0) {
              this.checkTransferDialog = 'การเปลี่ยนผู้ดูแลจะมีผลในทันที';
            } else if (
              res.data.resultData[0].permanentJobTransferCheckEnum == 1
            ) {
              this.checkTransferDialog =
                'การเปลี่ยนผู้ดูแลจะมีผลในรอบงานเดือนถัดไป';
            }
          });
      } else {
        this.checkTransferDialog = 'พนักงานรายนี้กำลังดูแลลูกค้ารายนี้อยู่';
        // this.showCheckDialog = false
      }

      this.employeeType.forEach((d) => {
        if (d.id === this.selectEnployee) {
          this.customerDetails.employeeFirstNameTh = d.firstName;
        }
      });
    }
  }

  employeeOwnedId: string;

  getCustomer() {
    this.loading = true;
    this.blockUI.start();
    this.customerService.getCustomerDetail(this.customerId).subscribe(
      (data) => {
        if (data.data.resultData != null) {
          this.customerId = data.data.resultData[0].id;
          this.customerDetails = data.data.resultData[0];
          this.employeeOwnedId = data.data.resultData[0].employeeOwnedId;
          this.customerDetails.branchNumber = this.customerDetails.branchNumber
            .toString()
            .padStart(5, '0');
          console.log('customer Detail', this.customerDetails.employeeOwnedId);
          this.originalEmployeeOwnedId = this.customerDetails.employeeOwnedId;
          // this.getTeam();
          this.selectEmployee = this.employeeOwnedId;
          if (
            this.userRole == this.userRoleEnum.AccountantSupervisor ||
            this.userRole == this.userRoleEnum.FinanceSupervisor ||
            this.userRole == this.userRoleEnum.AccountantManager ||
            this.userRole == this.userRoleEnum.FinanceManager ||
            this.userRole == this.userRoleEnum.SuperAdmin
          ) {
            // let params: any = {};
            console.log(this.selectedTeam);
            this.apiService
              .GetDataById('customer/EmployeeOwned', this.customerId)
              .subscribe((data) => {
                let res = data.data.resultData[0];
                this.selectedTeam = res.teamId;
                this.selectedDepartment = res.departmentId;
                this.onselectTeam();
              });
          }

          this.selectBusinessType = this.customerDetails.businessTypeId;
          this.selectEnployee = this.customerDetails.employeeOwnedId;
          this.customerService.customerCode = this.customerDetails.customerCode;
        }
        this.loading = false;
      },
      (error) => {
        this.customerDetails = new CustomerDetails();
        console.log(error);
        this.loading = false;
        this.blockUI.stop();
      }
    );

    this.customerService.getCustomerAddress(this.customerId).subscribe(
      (data) => {
        if (data.data.resultData.length > 0) {
          this.customerAddress = data.data.resultData[0];
          this.selectProvince = this.customerAddress.provinceId;
          this.selectDistrict = this.customerAddress.districtId;
          this.selectSubDistrict = this.customerAddress.subDistrictId;

          this.customerDocAddress = data.data.resultData[1];
          this.selectProvinceDoc = this.customerDocAddress.provinceId;
          this.selectDistrictDoc = this.customerDocAddress.districtId;
          this.selectSubDistrictDoc = this.customerDocAddress.subDistrictId;

          this.userService
            .getDistrict(this.selectProvince)
            .subscribe((data) => {
              this.districts = data.data.resultData;
            });
          this.userService
            .getSubDistrict(this.selectDistrict)
            .subscribe((data) => {
              this.subDistricts = data.data.resultData;
              this.onSelectChangeSubDistrict();
            });

          this.userService
            .getDistrict(this.selectProvinceDoc)
            .subscribe((data) => {
              this.districtsDoc = data.data.resultData;
            });
          this.userService
            .getSubDistrict(this.selectDistrictDoc)
            .subscribe((data) => {
              this.subDistrictsDoc = data.data.resultData;
              this.onSelectDocChangeSubDistrict();
              // this.postCodeDoc = data.data.resultData[0].subDistrictPostCode;
            });

          this.blockUI.stop();
        }
      },
      (error) => {
        this.customerAddress = new CustomerAddress();
        this.blockUI.stop();
        console.log(error);
      }
    );
  }

  onSubmit(modal) {
    this.modalService.open(modal, { centered: true });
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  resetEmployee() {
    this.apiService
      .GetDataById('customer/EmployeeOwned', this.customerId)
      .subscribe((data) => {
        let res = data.data.resultData[0];
        this.selectedTeam = res.teamId;
        this.selectedDepartment = res.departmentId;
        this.onselectTeam();
        this.selectEmployee = this.originalEmployeeOwnedId;
        this.showCheckDialog = false
      });
  }

  updateBranchNumberValue() {
    this.customerDetails.branchNumber = this.customerDetails.branchNumber
      .toString()
      .padStart(5, '0');
  }

  saveCustomer(event: boolean) {
    this.blockUI.start();
    this.currentUser = this.authenticationService.currentUserValue;
    this.customerDetails.businessTypeId = this.selectBusinessType;
    this.customerDetails.employeeOwnedId = this.selectEnployee;
    this.customerDetails.createBy = this.currentUser.id;

    if (this.selectBusinessType == 16) {
      this.customerDetails.isHeadBranch = true;
      this.customerDetails.branchName = '';
      this.customerDetails.businessName = '';
      // this.customerDetails.genderId = 2
    } else {
      this.customerDetails.firstName = '';
      this.customerDetails.lastName = '';
      this.customerDetails.genderId = 2;
    }
    let zero = '0';
    (this.customerDetails.branchNumber = !this.customerDetails.isHeadBranch
      ? parseInt(
          this.customerDetails.branchNumber
            ? this.customerDetails.branchNumber
            : zero
        ).toString()
      : zero),
      this.validation();

    if (!this.validate) {
      // console.log('ไม่ติด validate');
      this.loading = false;
      if (this.routePath == 'create') {
        this.mapCustomerAddress();
        this.customerService.createCustomer(this.customerDetails).subscribe(
          (data) => {
            this.updateCustomerCode(data.data.resultData[0]);
            if (
              this.customerDetails.employeeOwnedId != this.selectEmployee &&
              this.userRole != this.userRoleEnum.Accountant &&
              this.userRole != this.userRoleEnum.Finance
            ) {
              this.apiService
                .Put('workTransfer/PermanentJobTransfer', {
                  recipientEmployeeId: this.selectEmployee,
                  customerId: data.data.resultData[0],
                })
                .subscribe(
                  (res) => {
                    this.blockUI.stop();
                    console.log('transfer done');
                  },
                  (err) => {
                    this.blockUI.stop();
                    console.log('transfer fail');
                  }
                );
            }
            for (let i = 0; i < this.addressID.length; i++) {
              this.customerAddressList[i].customerId = data.data.resultData[0];
              this.customerId = data.data.resultData[0];
              forkJoin(
                this.customerService.createCustomerAddress(
                  this.customerAddressList[i]
                )
              ).subscribe((data) => {
                this.blockUI.stop();
                Swal.fire('เพิ่มข้อมูลสำเร็จ', '', 'success');
                this._router.navigate([
                  '/atms/customer/edit/' + this.customerId + '/business',
                ]);
                this.updateBranchNumberValue();
              });
            }
          },
          (err) => {
            this.blockUI.stop();
            if ((err = 888)) {
              Swal.fire('เลขสาขาซ้ำกัน', '', 'error');
              this.errorInput.branchNumber = 'เลขสาขาซ้ำกัน';
            } else {
              this.blockUI.stop();
            }
            this.updateBranchNumberValue();
          }
        );
      } else if (this.routePath == 'edit') {
        this.updateActiveCustomer();
        if (this.isSuperAdmin()) {
          this.updateCustomerCode(this.customerId);
        }
        this.mapCustomerAddress();
        this.customerService
          .editCustomer(this.customerId, this.customerDetails)
          .subscribe(
            (data) => {
              if (
                this.customerDetails.employeeOwnedId != this.selectEmployee &&
                this.userRole != this.userRoleEnum.Accountant &&
                this.userRole != this.userRoleEnum.Finance
              ) {
                this.apiService
                  .Put('workTransfer/PermanentJobTransfer', {
                    recipientEmployeeId: this.selectEmployee,
                    customerId: this.customerId,
                  })
                  .subscribe(
                    (res) => {
                      this.blockUI.stop();
                      console.log('transfer done');
                    },
                    (err) => {
                      this.blockUI.stop();
                      console.log('transfer fail');
                    }
                  );
              }
              for (let i = 0; i < this.addressID.length; i++) {
                forkJoin(
                  this.customerService.updateCustomerAddress(
                    this.addressID[i],
                    this.customerAddressList[i]
                  )
                ).subscribe(
                  (data) => {
                    this.blockUI.stop();
                    Swal.fire('เพิ่มข้อมูลสำเร็จ', '', 'success');
                    this._router.navigate([
                      '/atms/customer/edit/' + this.customerId + '/business',
                    ]);
                    this.updateBranchNumberValue();
                  },
                  (err) => {
                    this.blockUI.stop();
                  }
                );
              }
            },
            (err) => {
              this.blockUI.stop();
              if ((err = 888)) {
                Swal.fire('เลขสาขาซ้ำกัน', '', 'error');
                this.errorInput.branchNumber = 'เลขสาขาซ้ำกัน';
              } else {
                this.blockUI.stop();
              }
              this.updateBranchNumberValue();
            }
          );
      }
    } else {
      console.log('ติด validate');
      this.blockUI.stop();
      this.updateBranchNumberValue();
      this.loading = false;
    }
  }

  validation() {
    this.errorInput = [];
    this.validate = false;

    if (
      !this.customerDetails.customerCode ||
      this.isBlank(this.customerDetails.customerCode)
    ) {
      this.errorInput.customerCode = 'กรุณากรอกรหัสลูกค้า';
      this.validate = true;
    }

    if (
      !this.customerDetails.taxId ||
      this.isBlank(this.customerDetails.taxId) ||
      !this.isValidtaxId(this.customerDetails.taxId)
    ) {
      this.errorInput.taxId = 'กรุณากรอกเลขทะเบียน';
      this.validate = true;
    }

    if (this.isBlank(this.customerDetails.branchNumber)) {
      if (
        this.selectBusinessType !== 16 &&
        this.customerDetails.isHeadBranch == false
      ) {
        this.errorInput.branchNumber = 'กรุณากรอกเลขสาขา';
        this.validate = true;
      } else if (
        (this.businessTypeId.id =
          16 && this.customerDetails.isHeadBranch == true)
      ) {
        this.validate = false;
      }
    }
    if (
      !this.customerDetails.branchName ||
      this.isBlank(this.customerDetails.branchName)
    ) {
      if (
        this.selectBusinessType !== 16 &&
        this.customerDetails.isHeadBranch == false
      ) {
        this.errorInput.branchName = 'กรุณากรอกชื่อสาขา';
        this.validate = true;
      } else if (
        (this.businessTypeId.id =
          16 && this.customerDetails.isHeadBranch == true)
      ) {
        this.validate = false;
      }
    }
    //businessName
    if (
      !this.customerDetails.businessName ||
      this.isBlank(this.customerDetails.businessName)
    ) {
      if (
        this.selectBusinessType !== 16 &&
        this.customerDetails.isHeadBranch == false
      ) {
        this.errorInput.businessName = 'กรุณากรอกชื่อกิจการ';
        this.validate = true;
      } else if (
        (this.businessTypeId.id =
          16 && this.customerDetails.isHeadBranch == true)
      ) {
        this.validate = false;
      }
    }
    //firstName
    if (
      !this.customerDetails.firstName ||
      this.isBlank(this.customerDetails.firstName)
    ) {
      if (
        this.selectBusinessType == 16 &&
        this.customerDetails.isHeadBranch == true
      ) {
        this.errorInput.firstName = 'กรุณากรอกชื่อ';
        this.validate = true;
      } else if (
        (this.businessTypeId.id =
          16 && this.customerDetails.isHeadBranch == true)
      ) {
        this.validate = false;
      }
    }
    //lastName
    if (
      !this.customerDetails.lastName ||
      this.isBlank(this.customerDetails.lastName)
    ) {
      if (
        this.selectBusinessType == 16 &&
        this.customerDetails.isHeadBranch == true
      ) {
        this.errorInput.lastName = 'กรุณากรอกนามสกุล';
        this.validate = true;
      } else if (
        (this.businessTypeId.id =
          16 && this.customerDetails.isHeadBranch == true)
      ) {
        this.validate = false;
      }
    }

    if (!this.selectBusinessType || this.isBlank(this.selectBusinessType)) {
      this.errorInput.businessTypeId = 'กรุณากรอกประเภทธุรกิจ';
      this.validate = true;
    }
    // if (!this.selectedTeam || this.isBlank(this.selectedTeam)) {
    //   this.errorInput.teamId = 'กรุณาเลือกทีม';
    //   this.validate = true;
    // }
    // if (!this.selectEmployee || this.isBlank(this.selectEmployee)) {
    //   this.errorInput.selectEmployee = 'กรุณาเลือกเลือกผู้ดูแล';
    //   this.validate = true;
    // }
    if (
      !this.customerDetails.contactEmail ||
      !this.isValidEmail(this.customerDetails.contactEmail) ||
      this.isBlank(this.customerDetails.contactEmail)
    ) {
      this.errorInput.contactEmail = 'กรุณากรอกอีเมล';
      this.validate = true;
    }
    if (
      !this.customerDetails.contactPhone1 ||
      !this.isValidPhone(this.customerDetails.contactPhone1) ||
      this.isBlank(this.customerDetails.contactPhone1)
    ) {
      this.errorInput.contactPhone1 = 'กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง';
      this.validate = true;
    }
    // if (
    // !this.isValidPhone(this.customerDetails.contactPhone2) &&
    // !this.isBlank(this.customerDetails.contactPhone2)
    // )
    // {
    //   this.errorInput.contactPhone2 = 'กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง';
    //   this.validate = true;
    // }
    // if (
    //   !this.isValidPhone(this.customerDetails.contactPhone3) &&
    //   !this.isBlank(this.customerDetails.contactPhone3)
    // ) {
    //   this.errorInput.contactPhone3 = 'กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง';
    //   this.validate = true;
    // }
    if (
      !this.customerDetails.personalFirstName ||
      this.isBlank(this.customerDetails.personalFirstName)
    ) {
      this.errorInput.personalFirstName = 'กรุณากรอกชื่อผู้ติดต่อ';
      this.validate = true;
    }
    if (
      !this.customerDetails.customerFirstName ||
      this.isBlank(this.customerDetails.customerFirstName)
    ) {
      this.errorInput.customerFirstName = 'กรุณากรอกชื่อผู้ติดต่อ';
      this.validate = true;
    }

    if (
      !this.customerAddress.addressNumber ||
      this.isBlank(this.customerAddress.addressNumber)
    ) {
      this.errorInput.addressNumber = 'กรุณากรอกที่อยู่';
      this.validate = true;
    }

    if (
      !this.customerDocAddress.addressNumber ||
      this.isBlank(this.customerDocAddress.addressNumber)
    ) {
      this.errorInput.addressNumberDoc = 'กรุณากรอกที่อยู่';
      this.validate = true;
    }

    if (!this.selectSubDistrict) {
      this.errorInput.selectSubDistrict = 'กรุณาเลือกแขวง / ตำบล';
      this.validate = true;
    }
    if (!this.selectDistrict) {
      this.errorInput.selectDistrict = 'กรุณาเลือกเขต / อำเภอ';
      this.validate = true;
    }
    if (!this.selectProvince) {
      this.errorInput.selectProvince = 'กรุณาเลือกจังหวัด';
      this.validate = true;
    }

    if (!this.selectSubDistrictDoc) {
      this.errorInput.selectSubDistrictDoc = 'กรุณาเลือกแขวง / ตำบล';
      this.validate = true;
    }
    if (!this.selectDistrictDoc) {
      this.errorInput.selectDistrictDoc = 'กรุณาเลือกเขต / อำเภอ';
      this.validate = true;
    }
    if (!this.selectProvinceDoc) {
      this.errorInput.selectProvinceDoc = 'กรุณาเลือกจังหวัด';
      this.validate = true;
    }

    if (this.validate) {
      // this.confirmModal.closeModal();
      this.modalService.dismissAll();
    }
  }

  // เช็คว่ามี Blank space ไหม
  isBlank(str: string): boolean {
    // return !str || /^\s*$|^\s+(?=\S)|\s+$/g.test(str);
    return /^\s*$/.test(str);
  }

  isValidEmail(email: string): boolean {
    // Regular expression for validating email addresses
    const emailPattern: RegExp =
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    // Use the test() method to check if the email matches the pattern
    return emailPattern.test(email);
  }

  isValidPhone(phone: string): boolean {
    const phonePattern: RegExp = /^\d{10}$/;

    return phonePattern.test(phone);
  }

  isValidtaxId(number: string): boolean {
    const numberPattern: RegExp = /^\d{13}$/g;
    return numberPattern.test(number);
  }

  // isvalidFax(fax: string): boolean {
  //   // Regular expression for validating fax numbers
  //   const faxPattern: RegExp =
  //   /^\d{10}$/;

  //   return faxPattern.test(fax);
  // }

  editRouter() {
    console.log('editRouter');

    this._router.navigate([
      '/atms/customer/edit/' + this.customerId + '/business',
    ]);
  }

  updateActiveCustomer() {
    this.customerService
      .activeCustomer(this.customerId, this.customerDetails.isActive)
      .subscribe((data) => {});
  }

  updateCustomerCode(customerId: string) {
    this.apiService
      .Patch(`customer/${customerId}/customercode`, {
        customerCode: this.customerDetails.customerCode,
      })
      .subscribe((res) => {});
  }

  mapCustomerAddress() {
    const address = new CustomerAddressModel();
    const address2 = new CustomerAddressModel();
    this.customerAddressList = [];
    this.addressID = [];
    this.addressID.push(this.customerAddress.id);
    this.addressID.push(this.customerDocAddress.id);

    address.provinceId = this.selectProvince;
    address.districtId = this.selectDistrict;
    address.subDistrictId = this.selectSubDistrict;
    address.customerId = this.customerId;
    address.addressNumber = this.customerAddress.addressNumber;
    address.detail = this.customerDetails.personalFirstName;
    address.useForShipping = true;
    address.useForBilling = false;
    this.customerAddressList.push(address);

    address2.provinceId = this.selectProvinceDoc;
    address2.districtId = this.selectDistrictDoc;
    address2.subDistrictId = this.selectSubDistrictDoc;
    address2.customerId = this.customerId;
    address2.addressNumber = this.customerDocAddress.addressNumber;
    address2.detail = this.customerDetails.customerFirstName;
    address2.useForShipping = false;
    address2.useForBilling = true;
    this.customerAddressList.push(address2);
  }

  onCancel() {
    this._router.navigate(['/atms/customer']);
  }

  isFinanceDepartment(): boolean {
    return this.authenticationService.IsFinanceDepartment();
  }

  isAccountDepartment(): boolean {
    return this.authenticationService.IsAccountDepartment();
  }

  isSuperAdmin(): boolean {
    return this.authenticationService.IsSuperAdmin();
  }
}
