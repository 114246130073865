import { filter } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerCreateBusinessComponent } from '../customer-create/customer-create-business/customer-create-business.component';
import { log } from 'console';
import { CustomerService } from 'service/customer.service';
import { CustomerDetails } from 'app/auth/models/customer';
import Swal from 'sweetalert2';
import { GlobalFuncService } from 'service/global-func.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ApiService } from 'service/api.service';
import { AuthenticationService } from 'app/auth/service';
import { Role } from 'app/auth/models';
// import { GlobalVars } from 'app/menu/menu';

interface IDemoOption {
  desc: string;
  value: string;
}

@Component({
  selector: 'app-customer-index',
  templateUrl: './customer-index.component.html',
  styleUrls: ['./customer-index.component.scss'],
})
export class CustomerIndexComponent implements OnInit {
  selectedValue: string;
  selectOption: IDemoOption[];
  isSelectAll: boolean = false;
  tableListData: CustomerDetails[];
  currentUser: any;
  userRole: any;
  userRoleEnum = Role;
  isWantFullReport: boolean = false;

  @BlockUI() blockUI: NgBlockUI;

  selectItemToReportList: any[] = [];

  constructor(
    private router: Router,
    private customerService: CustomerService,
    private globalFunction: GlobalFuncService,
    private apiService: ApiService,
    private authenticationService: AuthenticationService
  ) {
    const token = JSON.parse(localStorage.getItem('token'));
    console.log(token);
    this.apiService.SetHttpHeaders(token);
    this.customerService.SetHttpHeaders(token);
    this.getDataList(this.page, this.pageLength);
    // this.blockUI.start();

    // console.log('from static',GlobalVars.overdueCount)
    // this.apiService.setOverdueCount();
    // console.log('from Function setOverdueCount',apiService.setOverdueCount())
  }

  async ngOnInit() {
    this.selectOption = [
      {
        desc: 'option1',
        value: '01',
      },
      {
        desc: 'option2',
        value: '02',
      },
      {
        desc: 'option3',
        value: '03',
      },
    ];

    // await this.customerService.getCustomerList(1, 10).subscribe((res) => {

    //   this.tableListData = res.data.resultData;
    //   console.log('tabledata',this.tableListData);

    //   this.totalPages = Math.ceil(res.data.totalItem / 10);
    //   console.log('End Loading...');
    //   this.blockUI.stop()

    // });
    this.currentUser = this.authenticationService.currentUserValue;
    this.userRole = this.currentUser.roleSetName;
  }

  onSelectChanged() {
    console.log('selectedValue', this.selectedValue);
  }

  onCustomerClicked(id: string) {
    this.router.navigate([`/atms/customer/view/${id}/business`]);
  }

  onSelectAll() {
    this.isSelectAll = !this.isSelectAll;
  }

  onDownloadFile(params: string, fileName: string) {
    let searchData: any = {
      sortPath: 'businessName',
      direction: 0,
    };

    if (this.filterStatus == 2) {
      searchData.isActive = true;
    } else if (this.filterStatus == 3) {
      searchData.isActive = false;
    }

    searchData.SelectList = this.isWantFullReport
      ? []
      : this.selectItemToReportList;

    searchData.Page = this.isWantFullReport ? '' : this.page;
    searchData.PageLength = this.isWantFullReport ? '' : this.pageLength;

    this.globalFunction.getExcelReport(
      'excel/ExportCustomer',
      'Customer',
      searchData
    );
  }

  resetSearchText() {
    this.selectedValue = null;
  }

  // openAlert(){
  //   this.ConfirmColorOpen();
  // }

  // ConfirmColorOpen() {
  //   Swal.fire({
  //     title: 'Are you sure?',
  //     text: "You won't be able to revert this!",
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonText: 'Yes, delete it!',
  //     customClass: {
  //       confirmButton: 'btn btn-primary',
  //       cancelButton: 'btn btn-danger ml-1'
  //     }
  //   }).then(function (result) {
  //     console.log('result',result);
  //     if (result.value) {
  //       Swal.fire({
  //         title: 'Deleted!',
  //         text: 'Your file has been deleted.',
  //         icon: 'success',
  //         customClass: {
  //           confirmButton: 'btn btn-success'
  //         }
  //       });
  //     } else if (result.dismiss === Swal.DismissReason.cancel) {
  //       Swal.fire({
  //         title: 'Cancelled',
  //         text: 'Your imaginary file is safe :)',
  //         icon: 'error',
  //         customClass: {
  //           confirmButton: 'btn btn-success'
  //         }
  //       });
  //     }
  //   });
  // }
  getDataList(page: number, pageLength: number) {
    this.blockUI.start();
    let searchData: any = {
      page: page,
      pageLength: pageLength,
      sortPath: 'businessName',
      direction: 0,
    };

    if (this.filterStatus == 2) {
      searchData.isActive = true;
    } else if (this.filterStatus == 3) {
      searchData.isActive = false;
    }

    this.apiService.GetAllData('customer', searchData).subscribe((res) => {
      this.tableListData = res.data.resultData;
      this.totalItem = res.data.totalItem;
      console.log('total', this.totalItem);
      console.log('list data', this.tableListData);
      this.calculateTotalPages();
      this.blockUI.stop();
    });
  }

  page: number = 1;
  pageLength: number = 10;
  totalItem: number = 0;
  totalPage: number = 0;

  calculateTotalPages() {
    this.totalPage = Math.ceil(this.totalItem / this.pageLength);
  }
  onPageChange(event: any) {
    this.page = event;
    this.getDataList(this.page, this.pageLength);
  }
  onSizePageChange(event: any) {
    this.pageLength = event;
    this.page = 1;
    this.getDataList(this.page, this.pageLength);
  }

  filterText: string = '';
  filterStatus: number = 1;
  selectStatus = [
    {
      desc: 'All',
      value: 1,
    },
    {
      desc: 'Active',
      value: 2,
    },
    {
      desc: 'Inactive',
      value: 3,
    },
  ];
  async onSearch() {
    this.page = 1;
    this.blockUI.start();
    await this.customerService
      .getSearchCustomerList(
        1,
        this.pageLength,
        this.filterText,
        this.filterStatus
      )
      .subscribe((res) => {
        this.tableListData = res.data.resultData;
        this.totalPage = Math.ceil(res.data.totalItem / 10);
        console.log('End Loading...');
        this.blockUI.stop();
        // console.log('tableListData',this.tableListData)
      });
  }
  onReset() {
    this.filterText = '';
    this.filterStatus = 1;
    this.onSearch();
  }

  selectAll(checked: boolean) {
    if (checked) {
      this.isSelectAll = true;
      this.tableListData.map((d) => {
        d.isChecked = true;
      });
      this.selectItemToReportList = this.tableListData.map((d) => d.id);
    } else {
      this.isSelectAll = false;
      this.tableListData.map((d) => {
        d.isChecked = false;
      });
      this.selectItemToReportList = [];
    }
  }

  selectItem(checked: boolean, item: any) {
    if (checked) {
      this.selectItemToReportList.push(item.id);
      if (this.selectItemToReportList.length === this.tableListData.length) {
        this.isSelectAll = true;
      }
    } else {
      this.isSelectAll = false;
      item.isChecked = false;
      this.selectItemToReportList = this.selectItemToReportList.filter(
        (id) => id !== item.id
      );
    }
  }
}
