import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { TimelineItem } from 'shared/form-wizard/form-wizard.component';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  private apiBaseUrl: string = `${environment.apiUrl}/api`;
  private httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {}
  
  get API_URL() {
    return this.apiBaseUrl;
  }

  get API_HEADERS() {
    return this.httpHeaders;
  }
  timelineData: TimelineItem[] = [
    { label: 'Wait Approve', descript: 'รอการยืนยัน', stepCount: 0 },
    { label: 'Wait Payment', descript: 'รอการชำระเงิน', stepCount: 1 },
    { label: 'Payment', descript: 'ชำระเงินสำเร็จ', stepCount: 2 },
    { label: 'Completed', descript: 'เสร็จสิ้น', stepCount: 3 },
    { label: 'Reject', descript: 'ปฎิเสธ', stepCount: 4 },
    { label: 'Void', descript: 'ยกเลิก', stepCount: 5 },
  ];

  // timelineBuyData: TimelineItem[] = [
  //   { label: 'Draft', descript: 'ร่าง', stepCount: 1 },
  //   { label: 'Accepted', descript: 'ยืนยัน', stepCount: 2 },
  //   { label: 'Wait Payment', descript: 'รอชำระเงิน', stepCount: 3 },
  //   { label: 'Payment', descript: 'ชำระเงินสำเร็จ', stepCount: 4 },
  //   { label: 'Get a Receipt', descript: 'รับใบเสร็จแล้ว', stepCount: 5 },
  // ];


  SetHttpHeaders(token): void {
    this.httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`);
  }

  getDocumentDetail(documentID: string) {
    const url = `${this.apiBaseUrl}/Document/${documentID}`; 
    return this.http.get<any>(url, { headers : this.httpHeaders });
  }

  getDocumentByDocumentTiming(documentTimingID: string, customerId: string) {
    let url = '';
    if(customerId != null && documentTimingID == null){
      url = `${this.apiBaseUrl}/Document?CustomerId=${customerId}`; 
    }
    else if(customerId == null && documentTimingID != null){
      url = `${this.apiBaseUrl}/Document?DocumentTimingId=${documentTimingID}`; 
    }
    else if(customerId != null && documentTimingID != null){
      url = `${this.apiBaseUrl}/Document?DocumentTimingId=${documentTimingID}&CustomerId=${customerId}`; 
    }
    return this.http.get<any>(url, { headers : this.httpHeaders });
  }

  getDocumentTiming(documentTimingID: string) {
    const url = `${this.apiBaseUrl}/documentTiming/${documentTimingID}`;
    return this.http.get<any>(url, { headers : this.httpHeaders });
  }

  getDocumentTimingByCustomerId(customerId: string) {
    const url = `${this.apiBaseUrl}/documentTiming?CustomerId=${customerId}`; 
    return this.http.get<any>(url, { headers : this.httpHeaders });
  }

  getDocumentTimingAll() {
    const url = `${this.apiBaseUrl}/documentTiming`; 
    return this.http.get<any>(url, { headers : this.httpHeaders });
  }

  getMyWorkList() : Observable<any>{
    const url = `${this.apiBaseUrl}/myworks`;
    return this.http.get(url, { headers : this.httpHeaders });
  }

  getHistoryList() : Observable<any>{
    const url = `${this.apiBaseUrl}/MyHistory`;
    return this.http.get(url, { headers : this.httpHeaders });
  }

  getAllDocument(): Observable<any> {
    const url = `${this.apiBaseUrl}/item?GroupId=3`;
    return this.http.get<any>(url, { headers : this.httpHeaders });
  }

  getCustomerPackage(customerID: string) : Observable<any>{
    const url = `${this.apiBaseUrl}/customer/${customerID}/Package`;
    return this.http.get<any>(url, { headers : this.httpHeaders });
  }
}
