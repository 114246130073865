<!-- Modal -->
<ng-template #documentExportDateModal let-modal>
    <div class="modal-header text-center bg-primary">
        <h3 class="modal-title w-100">Account Task Management System</h3>
        <button type="button" class="btn-close float-right" (click)="closeModal()">&times;</button>
    </div>
    <div class="modal-body">
        <div class="my-4">
            <h4 class="big-text blue-text text-center">
                กรุณาเลือกวันที่ออกรายงาน
            </h4>
        </div>
        <div class="input-group w-50" style="margin: auto;">
            <input
                class="form-control"
                placeholder="yyyy-mm-dd"
                name="dp"
                [(ngModel)]="model"
                ngbDatepicker
                #d="ngbDatepicker"
            />
            <div class="input-group-append">
                <button class="btn btn-outline-secondary fa" (click)="d.toggle()" type="button">&#xf073;</button>
            </div>
        </div>
        <div style="height: 50px;"></div>
        <div class="input-group w-50" style="margin: auto;">
            <input
                class="form-control"
                placeholder="yyyy-mm-dd"
                name="dp"
                [(ngModel)]="model"
                ngbDatepicker
                #d="ngbDatepicker"
            />
            <div class="input-group-append">
                <button class="btn btn-outline-secondary fa" (click)="d.toggle()" type="button">&#xf073;</button>
            </div>
        </div>
    </div>
    <div class="modal-footer justify-content-evenly">
        
    </div>
</ng-template>