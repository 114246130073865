import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit {
  @Output() dataEmitted: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('confirmModal') confirmModal: any;

  constructor(private ngbModal : NgbModal) { }

  ngOnInit(): void {
  }

  emitData(data: boolean) {
    this.dataEmitted.emit(data);
  }

  openModal() {
    this.ngbModal.open(this.confirmModal, {       
      animation: false,
      size: 'lg',
      centered: true,
      backdrop: 'static',
      keyboard: false,
    });
  }

  closeModal() {
    this.ngbModal.dismissAll();
  }
}
