<div class="px-5">
    <div class="row justify-content-between align-items-center">
        <div class="col">
            <ng-container *ngIf="isAccountDepartment() || isSuperAdmin()">
                <button type="button" class="btn btn-success button-width-200" rippleEffect *ngIf="isShowEditBotton"
                    (click)="editRouter()">แก้ไข</button>

                <button type="button" class="btn btn-secondary button-width-200" rippleEffect *ngIf="!isShowEditBotton"
                    disabled>แก้ไข</button>
            </ng-container>

        </div>
        <!-- <div class="col">
            <button type="button" class="btn btn-success button-width-200" rippleEffect 
            [hidden]="!isShowEditBotton" (click)="editRouter()">
                แก้ไข </button>
        </div> -->
        <div class="col">
            <p class="text-header-document text-right">{{ customerCode }}</p>
        </div>
    </div>
    <form>
        <div class="row mt-1">
            <div class="col-5">
                <div class="form-group">
                    <label class="form-top-lable">Package ค่าทำบัญชี</label>
                    <ng-select [(ngModel)]="selectPackage" [items]="packageList" bindLabel="name" bindValue="id"
                        placeholder="-- กรุณาเลือกแพ็คเกจ --" [readonly]="isShowEditBotton" name="selectPackage"
                        [clearable]="false" [ngClass]="{'is-invalid-option': packageMessage}">
                    </ng-select>
                    <div *ngIf="validate" class="invalid-feedback-show">{{packageMessage}}</div>
                    <!-- <ng-select 
                        placeholder="Package A" [clearable]="false" [(ngModel)]="aaaa">
                        <ng-option *ngFor="let item of packageList" 
                            [value]="item.id">{{item.name}}</ng-option>
                    </ng-select> -->


                    <!-- [(ngModel)]="selectBusinessType"  -->
                    <!-- <input type="text" class="form-control" /> -->
                </div>
            </div>
        </div>
        <div class="row mb-1">
            <div class="col-3">
                <div class="custom-control custom-control-success custom-checkbox">
                    <input type="radio" class="custom-control-input" id="check01" name="isFiscalEOM"
                        (click)="onFiscalEOM()" [checked]="isFiscalEOM" [disabled]="isShowEditBotton" />
                    <label class="custom-control-label form-top-lable" for="check01">รอบปีบัญชี ( ปิดสิ้นปี )</label>
                </div>
            </div>
            <div class="col-3">
                <div class="custom-control custom-control-success custom-checkbox">
                    <input type="radio" class="custom-control-input" id="check02" name="isFiscalEOM"
                        (click)="onOpFiscalEOM()" [checked]="!isFiscalEOM" [disabled]="isShowEditBotton" />
                    <label class="custom-control-label form-top-lable" for="check02">รอบปีบัญชี ( พิเศษ )</label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-2">
                <input type="text" [bsConfig]="monthOptions" [(ngModel)]="monthValue" name="monthValue"
                    class="form-control" (bsValueChange)="onChangeDate($event)" bsDatepicker
                    [disabled]="isFiscalEOM || isShowEditBotton">
            </div>
            <div class="col-2">
                <input type="text" class="form-control" [(ngModel)]="dayValue" name="dayValue" disabled>
            </div>
        </div>
    </form>
</div>
<hr class="mx-1" style="border: 1px solid #DE8F1E;">
<div class="px-5">
    <div class="row my-2">
        <div class="col">
            <p style="padding: 0; margin: 0; font-size: 18px;">แบบภาษี</p>
        </div>
    </div>
    <ng-container *ngFor="let item of listItemPackage let i = index">
        <div class="row my-md-2" *ngIf="i % 4 === 0">
            <div class="col-md-3 col-6" *ngFor="let innerItem of listItemPackage.slice(i, i + 4)">
                <div class="custom-control custom-control-primary custom-checkbox">
                    <input class="custom-control-input" type="checkbox" id="{{innerItem?.id}}"
                        [disabled]="isShowEditBotton" [checked]="innerItem?.checked"
                        (change)="onCheckboxChange(innerItem, $event)" />
                    <label class="custom-control-label" for="{{innerItem?.id}}">{{ innerItem?.name }}</label>
                </div>
            </div>
        </div>
    </ng-container>
    <!-- <div class="row my-2">
        <div class="col">
            <div class="custom-control custom-control-primary custom-checkbox">
                <input class="custom-control-input" type="checkbox" id="{{listItemPackage[7]?.id}}" value="checked" [disabled]="isShowEditBotton"/>
                <label class="custom-control-label" for="{{listItemPackage[7]?.id}}">{{ listItemPackage[7]?.name }}</label>
            </div>
        </div>
        <div class="col">
            <div class="custom-control custom-control-primary custom-checkbox">
                <input class="custom-control-input" type="checkbox" id="{{listItemPackage[8]?.id}}" value="checked" [disabled]="isShowEditBotton"/>
                <label class="custom-control-label" for="{{listItemPackage[8]?.id}}">{{ listItemPackage[8]?.name }}</label>
            </div>
        </div>
        <div class="col">
            <div class="custom-control custom-control-primary custom-checkbox">
                <input class="custom-control-input" type="checkbox" id="{{listItemPackage[9]?.id}}" value="checked" [disabled]="isShowEditBotton"/>
                <label class="custom-control-label" for="{{listItemPackage[9]?.id}}">{{ listItemPackage[9]?.name }}</label>
            </div>
        </div>
        <div class="col">
            <div class="custom-control custom-control-primary custom-checkbox">
                <input class="custom-control-input" type="checkbox" id="{{listItemPackage[10]?.id}}" value="checked" [disabled]="isShowEditBotton"/>
                <label class="custom-control-label" for="{{listItemPackage[10]?.id}}">{{ listItemPackage[10]?.name }}</label>
            </div>
        </div>
        <div class="col">
            <div class="custom-control custom-control-primary custom-checkbox">
                <input class="custom-control-input" type="checkbox" id="{{listItemPackage[11]?.id}}" value="checked" [disabled]="isShowEditBotton"/>
                <label class="custom-control-label" for="{{listItemPackage[11]?.id}}">{{ listItemPackage[11]?.name }}</label>
            </div>
        </div>
    </div>
    <div class="row my-2">
        <div class="col">
            <div class="custom-control custom-control-primary custom-checkbox">
                <input class="custom-control-input" type="checkbox" id="{{listItemPackage[12]?.id}}" value="checked" [disabled]="isShowEditBotton"/>
                <label class="custom-control-label" for="{{listItemPackage[12]?.id}}">{{ listItemPackage[12]?.name }}</label>
            </div>
        </div>
        <div class="col">
            <div class="custom-control custom-control-primary custom-checkbox">
                <input class="custom-control-input" type="checkbox" id="{{listItemPackage[13]?.id}}" value="checked" [disabled]="isShowEditBotton"/>
                <label class="custom-control-label" for="{{listItemPackage[13]?.id}}">{{ listItemPackage[13]?.name }}</label>
            </div>
        </div>
        <div class="col">
            <div class="custom-control custom-control-primary custom-checkbox">
                <input class="custom-control-input" type="checkbox" id="{{listItemPackage[14]?.id}}" value="checked" [disabled]="isShowEditBotton"/>
                <label class="custom-control-label" for="{{listItemPackage[14]?.id}}">{{ listItemPackage[14]?.name }}</label>
            </div>
        </div>
        <div class="col">
            <div class="custom-control custom-control-primary custom-checkbox">
                <input class="custom-control-input" type="checkbox" id="{{listItemPackage[15]?.id}}" value="checked" [disabled]="isShowEditBotton"/>
                <label class="custom-control-label" for="{{listItemPackage[15]?.id}}">{{ listItemPackage[15]?.name }}</label>
            </div>
        </div>
        <div class="col">
            <div class="custom-control custom-control-primary custom-checkbox">
                <input class="custom-control-input" type="checkbox" id="{{listItemPackage[16]?.id}}" value="checked" [disabled]="isShowEditBotton"/>
                <label class="custom-control-label" for="{{listItemPackage[16]?.id}}">{{ listItemPackage[16]?.name }}</label>
            </div>
        </div>
    </div> -->
</div>

<div class="row justify-content-center mt-5" [hidden]="isShowEditBotton">
    <div class="col-lg-4 col-6">
        <button class="btn btn-cancel w-100" (click)="onCancel()">ยกเลิก</button>
    </div>
    <div class="col-lg-4 col-6">
        <button class="btn btn-main w-100" (click)="onSubmit(confirmModal)">ยืนยัน</button>
    </div>
</div>

<ng-template #confirmModal let-modal>
    <div class="modal-header text-center bg-primary">
        <h3 class="modal-title w-100">Account Task Management System</h3>
    </div>
    <div class="modal-body">
        <div class="my-4">
            <h4 class="big-text blue-text text-center">
                คุณต้องการยืนยันการทำรายการนี้หรือไม่ ?
            </h4>
        </div>
    </div>
    <div class="modal-footer justify-content-between">
        <button type="button" class="btn btn-danger" (click)="closeModal()">ยกเลิก</button>
        <button type="button" class="btn btn-primary"
            (click)="closeModal(); saveCustomerPackage($event)">ยืนยัน</button>

    </div>
</ng-template>

<!-- <app-confirm-modal (dataEmitted)="saveCustomerPackage($event)" #confirmModal></app-confirm-modal> -->