import { map } from 'rxjs/operators';
import { Component, OnInit, ViewChild } from '@angular/core';
import { CustomerCreateComponent } from '../customer-create.component';
import { CustomerService } from 'service/customer.service';
import { CustomerDetails, CustomerPackage } from 'app/auth/models/customer';
import { ManagePackageService } from 'service/manage-package.service';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { thBeLocale } from 'ngx-bootstrap/locale';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { Router } from '@angular/router';
import { ApiService } from 'service/api.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { AuthenticationService } from 'app/auth/service';
defineLocale('th-be', thBeLocale);
@Component({
  selector: 'app-customer-create-package',
  templateUrl: './customer-create-package.component.html',
  styleUrls: ['./customer-create-package.component.scss'],
})
export class CustomerCreatePackageComponent implements OnInit {
  @ViewChild('confirmModal') confirmModal: any;
  routePath: string;
  isShowEditBotton: boolean;
  customerCode: string;
  customerId: string;
  packageList: any[] = [];
  listItemPackage: Array<CustomerPackage> = new Array<CustomerPackage>();
  customerDetails: CustomerDetails = new CustomerDetails();
  isEndOfMonth: boolean;
  selectPackage: any;

  @BlockUI() blockUI: NgBlockUI;

  modelUpdate: any = {
    packageId: '',
    fiscalYearDate: new Date(),
    isFiscalEOM: true,
    customerHaveItem: [],
  };

  constructor(
    private customerCreateComponent: CustomerCreateComponent,
    private managePackageService: ManagePackageService,
    private customerService: CustomerService,
    private localeService: BsLocaleService,
    private _router: Router,
    private apiService: ApiService,
    private modalService: NgbModal,
    private authenticationService: AuthenticationService
  ) {
    const token = JSON.parse(localStorage.getItem('token'));
    this.customerService.SetHttpHeaders(token);
    this.managePackageService.SetHttpHeaders(token);
    this.localeService.use('th-be');
  }
  public monthOptions: Partial<BsDatepickerConfig> = {
    adaptivePosition: true,
    isAnimated: true,
    containerClass: 'theme-dark-blue',
    dateInputFormat: 'MMMM',
    showWeekNumbers: false,
    // minMode: 'month',
    startView: 'month',
  };
  monthValue: any = 11;
  dayValue: any = 31;

  isFiscalEOM: boolean = true;
  validate: boolean = false;
  packageMessage: string = '';
  ngOnInit(): void {
    this.getListPackage();
    this.monthValue = new Date(
      new Date().getFullYear(),
      this.monthValue,
      this.dayValue
    );
    this.customerCode = this.customerService.customerCode;

    this.routePath = this.customerCreateComponent.getActiveParentRoute();
    if (this.routePath == 'view') {
      if (this.isAccountDepartment() || this.isSuperAdmin()) {
        this.isShowEditBotton = true;
      } else {
        this.isShowEditBotton = false;
      }
      // this.isShowEditBotton = true;
      this.customerId = this.customerCreateComponent.getCustomerId();
      this.getCustomerPackage();
    } else if (this.routePath == 'edit') {
      if (this.isAccountDepartment() || this.isSuperAdmin()) {
        this.isShowEditBotton = false;
      } else {
        this.isShowEditBotton = true;
      }
      
      this.customerId = this.customerCreateComponent.getCustomerId();
      this.getCustomerPackage();
    } else if (this.routePath == 'create') {
      this.isShowEditBotton = true;
    }
  }

  onCancel() {
    this._router.navigate(['/atms/customer']);
  }

  getCustomerPackage() {
    this.blockUI.start();

    this.customerService.getCustomerPackage(this.customerId).subscribe((data) => {
      this.selectPackage = data.data.resultData[0].packageId;
      this.isFiscalEOM = data.data.resultData[0].isFiscalEOM ? true : false;
      this.monthValue = data.data.resultData[0].fiscalYearDate ? new Date(data.data.resultData[0].fiscalYearDate) : new Date(
        new Date().getFullYear(),
        11,
        31
      );
      this.item = data.data.resultData[0].customerHaveItem.map((item) => {
        return {
          itemId: item.itemId,
        };
      });

      // this.apiService.GetAllData('category',{name: 'MonthlyTax+YearlyTax+PP30Tax'}).subscribe(res => {
      //   this.listItemPackage = res.data.resultData;
      //   this.listItemPackage.forEach((element) => {
      //     this.item.forEach((item) => {
      //       if (element.id == item.itemId) {
      //         element.checked = true;
      //       }
      //     });
      //   });
      // })

      this.customerService.getCustomerItemPackage().subscribe((data) => {
        this.listItemPackage = data.data.resultData;
        this.listItemPackage.forEach((element) => {
          this.item.forEach((item) => {
            if (element.id == item.itemId) {
              element.checked = true;
            }
          });
        });
        this.listItemPackage.sort(
          (a, b) =>
            new Date(a.createDate).getTime() - new Date(b.createDate).getTime()
        );
        setTimeout(() => {
          this.blockUI.stop();
        }, 500);
      });
    });
  }

  getListPackage() {
    this.managePackageService.getAllItem().subscribe((data) => {
      this.packageList = data.data.resultData;
    });
  }

  onChangeDate(newDate: Date): void {
    if (newDate && !isNaN(newDate.getDay())) {
      this.monthValue = new Date(newDate);
      this.dayValue = newDate.getDate();
    }
  }

  onFiscalEOM() {
    this.isFiscalEOM = true;
    this.monthValue = new Date(new Date().getFullYear(), 11, 31);
  }
  onOpFiscalEOM() {
    this.isFiscalEOM = false;
    this.monthValue = new Date(new Date().getFullYear(), 11, 31);
  }

  editRouter() {
    this._router.navigate(['/atms/customer/edit/' + this.customerId + '/package']);
  }

  onSubmit(modal) {
    this.validatePackage();
    if (!this.validate) {
      this.modalService.open(modal, { centered: true });
    }
  }

  validatePackage() {
    this.validate = false;
    if (this.selectPackage == null || this.selectPackage == '') {
      this.validate = true;
      this.packageMessage = 'กรุณาเลือกแพ็คเกจ';
    }
  }
  saveCustomerPackage(event: boolean) {
    if (event) {
      // this.confirmModal.closeModal();
      this.blockUI.start();
      this.mapDataCustomer();
      this.customerService
        .updateCustomerPackage(this.customerId, this.modelUpdate)
        .subscribe((data) => {
          setTimeout(() => {
            this.blockUI.stop();
            Swal.fire('เพิ่มข้อมูลสำเร็จ', '', 'success');
          }, 500);
          this._router.navigate([
            '/atms/customer/view/' + this.customerId + '/package',
          ]);
        },
        (err) => {
          this.blockUI.stop();
            Swal.fire('เพิ่มข้อมูลไม่สำเร็จ', '', 'error');
        }
      );
    }
  }

  mapDataCustomer() {
    this.modelUpdate.packageId = this.selectPackage;
    this.modelUpdate.fiscalYearDate = this.monthValue;
    this.modelUpdate.isFiscalEOM = this.isFiscalEOM;
    this.modelUpdate.customerHaveItem = this.item;
  }

  onCheckboxChange(item: any, event: any) {
    if (event.target.checked) {
      // Checkbox is checked, push data
      this.pushData(item);
    } else {
      // Checkbox is unchecked, pop data
      this.popData(item);
    }
  }

  item: any[] = [];
  pushData(item: any) {
    const data = {
      itemId: item.id,
    };
    this.item.push(data);
  }

  popData(item: any) {
    this.item = this.item.filter((data) => {
      return data.itemId !== item.id;
    });
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  isFinanceDepartment(): boolean {
    return this.authenticationService.IsFinanceDepartment();
  }

  isAccountDepartment(): boolean {
    return this.authenticationService.IsAccountDepartment();
  }
  isSuperAdmin(): boolean {
    return this.authenticationService.IsSuperAdmin();
  }
}
